import React, { useState } from 'react'

import AthletePhysicalTests from './physical-tests/AthletePhysicalTests'
import PhysicalTestsControllerForm from '../../resources/physical_tests/PhysicalTestsControllerForm'

export default (props) => {
  const [showPage, setShowPage] = useState('athlete_physical_tests_list')
  const [physicalTest, setPhysicalTest] = useState(null)
  const [inputs, setInputs] = useState({
    athlete_id: props.record.id,
    season_id: '2020-2021',
    filledwhen: 'inicio',
  })

  return (
    <div style={{ marginBottom: 15 }}>
      {showPage === 'athlete_physical_tests_list' && (
        <AthletePhysicalTests
          {...props}
          setShowPage={setShowPage}
          setPhysicalTest={setPhysicalTest}
          showPage={showPage}
          inputs={inputs}
          setInputs={setInputs}
        />
      )}

      {showPage === 'athlete_physical_tests_action' && (
        <PhysicalTestsControllerForm
          {...props}
          setShowPage={setShowPage}
          physicalTest={physicalTest}
          showPage={showPage}
        />
      )}
    </div>
  )
}
