import React, { useState } from 'react'
import { LinearProgress } from 'react-admin'
import { macro_categories } from '../../../constants/physical_tests_list'
import PhysicalTestsList from './PhysicalTestsList'
import BackButton from '../../BackButton'
import { Box } from '@material-ui/core'
import ButtonCustomColor from '../../ButtonCustomColor'

export default (props) => {
  const { data, loading, contentState, setContentState } = props

  const [physicalTestsList, setPhysicalTestsList] = useState([])

  if (loading) {
    return <LinearProgress />
  }

  if (!data) {
    return 'Ainda não existe historial do jogador nesta época!'
  }

  return (
    <>
      {contentState === 'macro' && (
        <MacroCategories
          setContentState={setContentState}
          setPhysicalTestsList={setPhysicalTestsList}
          data={data}
        />
      )}

      {contentState === 'micro' && (
        <>
          <BackButton
            onBack={() => {
              setContentState('macro')
            }}
          />
          <Box mt="1em" />
          <PhysicalTestsList physicalList={physicalTestsList} {...props} />
        </>
      )}
    </>
  )
}

const MacroCategories = (props) => {
  const { data, setContentState, setPhysicalTestsList } = props
  const buttonsList = []

  function setMacroColorButton(tests) {
    let complete_physical_tests_number = 0

    tests.forEach((test) => {
      if (data[test]) {
        complete_physical_tests_number++
      }
    })

    if (complete_physical_tests_number === 0) {
      return 'default'
    }

    return tests.length === complete_physical_tests_number
      ? 'primary'
      : 'secondary'
  }

  macro_categories.forEach((macro) => {
    buttonsList.push(
      <div key={`macro-categorie-${macro.id}`}>
        <ButtonCustomColor
          color={setMacroColorButton(macro.tests)}
          onClick={() => {
            setPhysicalTestsList(macro.tests)
            setContentState('micro')
          }}
          variant="contained"
          fullWidth
          label={macro.id}
          size="large"></ButtonCustomColor>
        &nbsp;
      </div>,
    )
  })

  return <>{buttonsList}</>
}
