export const agreement_options = [
  { id: 1, name: 'Discordo Totalmente' },
  { id: 2, name: 'Discordo Bastante' },
  { id: 3, name: 'Discordo' },
  { id: 4, name: 'Não Concordo Nem Discordo' },
  { id: 5, name: 'Concordo' },
  { id: 6, name: 'Concordo Bastante' },
  { id: 7, name: 'Concordo Completamente' },
]

export const current_school_degree_options = [
  '5º Ano',
  '6º Ano',
  '7º Ano',
  '8º Ano',
  '9º Ano',
  '10º Ano',
  '11º Ano',
  '12º Ano',
  'Licenciatura',
  'Mestrado',
  'Doutoramento',
]

export const not_in_school_options = [
  { id: 1, name: 'Sem efeito' },
  { id: 2, name: 'Sem estudos ou 1º Ciclo incompleto' },
  { id: 3, name: '1º Ciclo' },
  { id: 4, name: '2º Ciclo' },
  { id: 5, name: '3º Ciclo' },
  { id: 6, name: 'Secundário' },
  { id: 7, name: 'Licenciatura' },
  { id: 8, name: 'Mestrado' },
  { id: 9, name: 'Doutoramento' },
]

export const positive_negative_drop_choices_options = [
  { id: 1, name: 'Nada ou muito ligeiramente' },
  { id: 2, name: 'Um pouco' },
  { id: 3, name: 'Moderadamente' },
  { id: 4, name: 'Bastante' },
  { id: 5, name: 'Extremamente' },
]

export const life_style_drop_choices_options = [
  { id: 1, name: 'Nunca' },
  { id: 2, name: 'Quase Nunca' },
  { id: 3, name: 'Às vezes' },
  { id: 4, name: 'Quase Sempre' },
  { id: 5, name: 'Sempre' },
]

export const alcoohol_options = [
  { id: 1, name: 'Mais de 20 doses' },
  { id: 2, name: '13-20 doses' },
  { id: 3, name: '11-20 doses' },
  { id: 4, name: '8-10 doses' },
  { id: 5, name: '0-7 doses' },
]

export const cafeine_options = [
  { id: 1, name: 'Mais de 10 por dia' },
  { id: 2, name: '7-10 por dia ' },
  { id: 3, name: '3-6 por dia' },
  { id: 4, name: '1-2 por dia' },
  { id: 5, name: 'Menos de 1 por dia' },
]

export const cigarrete_options = [
  { id: 1, name: 'Mais de 10 por dia' },
  { id: 2, name: '1-10 por dia' },
  { id: 3, name: 'Raramente fumo' },
  { id: 4, name: 'Nenhum durante o último ano' },
  { id: 5, name: 'Nenhum durante os últimos 5 anos' },
  { id: 6, name: 'Não Fumo' },
]

export const weight_options = [
  { id: 1, name: '> 8 Kg' },
  { id: 2, name: '6-8 Kg' },
  { id: 3, name: '4-6 Kg' },
  { id: 4, name: '2-4 Kg' },
  { id: 5, name: '< 2 Kg' },
]

export const time_options = [
  { id: 1, name: 'Menos de 1 vez por semana' },
  { id: 2, name: '1-2 vezes por semana' },
  { id: 3, name: '3 vezes por semana' },
  { id: 4, name: '4 vezes por semana' },
  { id: 5, name: '5 ou mais vezes por semana' },
]

export const do_it_options = [
  { id: 0, name: 'Nunca faço isto' },
  { id: 1, name: 'Faço isto por vezes' },
  { id: 2, name: 'Em média é isto que faço' },
  { id: 3, name: 'Faço quase sempre isto' },
]

export const likes_options = [
  { id: 1, name: 'Não gosto nada' },
  { id: 2, name: 'Não gosto lá muito' },
  { id: 3, name: 'É-me indiferente' },
  { id: 4, name: 'Gosto bastante' },
  { id: 5, name: 'Gosto mesmo muito' },
]

export const average_options = [
  { id: 1, name: 'Inferior à média' },
  { id: 2, name: 'Médio' },
  { id: 3, name: 'Bom' },
  { id: 4, name: 'Muito Bom' },
]

export const types_options = ['Inferior à média', 'Médio', 'Bom', 'Muito Bom']

export const sport_types_options = [
  { id: 1, name: 'Futebol' },
  { id: 2, name: 'Andebol' },
  { id: 3, name: 'Basquetebol' },
  { id: 4, name: 'Voleibol' },
  { id: 5, name: 'Corfebol' },
  { id: 6, name: 'Hóquei em campo' },
  { id: 7, name: 'Raguebi' },
  { id: 8, name: 'Softebol/ Beisebol' },
  { id: 9, name: 'Natação' },
  { id: 10, name: 'Ginástica de solo' },
  { id: 11, name: 'Ginástica de aparelhos' },
  { id: 12, name: 'Ginástica acrobática' },
  { id: 13, name: 'Ginástica rítmica' },
  { id: 14, name: 'Atletismo' },
  { id: 15, name: 'Badminton' },
  { id: 16, name: 'Ténis de campo' },
  { id: 17, name: 'Ténis de mesa' },
  { id: 18, name: 'Patinagem' },
  { id: 19, name: 'Corridas em patins' },
  { id: 20, name: 'Dança moderna' },
  { id: 21, name: 'Danças sociais' },
  { id: 22, name: 'Danças tradicionais portuguesas' },
  { id: 23, name: 'Orientação' },
  { id: 24, name: 'Luta' },
  { id: 25, name: 'Judo' },
  { id: 26, name: 'Canoagem' },
  { id: 27, name: 'Ciclocrosse/ Cicloturismo' },
  { id: 28, name: 'Golfe' },
  { id: 29, name: 'Montanhismo/ Escalada' },
  { id: 30, name: 'Tiro com arco' },
  { id: 31, name: 'Vela' },
]

export const field_positions = [
  { id: 'GR', name: 'Guarda-redes' },
  { id: 'LD', name: 'Lateral Direito' },
  { id: 'LE', name: 'Lateral Esquerdo' },
  { id: 'L', name: 'Lateral' },
  { id: 'DC', name: 'Defesa Central' },
  { id: 'MD', name: 'Médio Defensivo' },
  { id: 'MO', name: 'Médio Ofensivo' },
  { id: 'MC', name: 'Médio Centro' },
  { id: 'ED', name: 'Extremo Direito' },
  { id: 'EE', name: 'Extremo Esquerdo' },
  { id: 'E', name: 'Extremo' },
  { id: 'PL', name: 'Ponta de lança' },
  { id: 'DCE', name: 'Defesa Central Esquerdo' },
]

export const footed_options = [
  { id: 1, name: 'Direito' },
  { id: 2, name: 'Esquerdo' },
  { id: 3, name: 'Ambos' },
]

export const filled_when = [
  { id: 'inicio', name: 'Inicio' },
  { id: 'final', name: 'Final' },
]

export const disagreement_options = [
  { id: 1, name: 'Discordo Totalmente' },
  { id: 2, name: 'Discordo' },
  { id: 3, name: 'Não Concordo' },
  { id: 4, name: 'Concordo' },
  { id: 5, name: 'Concordo Totalmente' },
]

export const yes_no_options = [
  { id: 'Sim', name: 'Sim' },
  { id: 'Não', name: 'Não' },
]

export const default_positions_options = [
  { id: 1, name: 'Guarda-Redes' },
  { id: 2, name: 'Defesa' },
  { id: 3, name: 'Médio' },
  { id: 4, name: 'Avançado' },
  { id: 5, name: 'Sem Posição' },
]
