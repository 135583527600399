import { team_formations } from '../constants/teams'
import { active_season } from '../helpers/SeasonsHelper'

export const getTeamFormationPermissionArray = (team_formation) => {
  return team_formations.filter((value) => {
    return (
      getTeamformationNumberFromString(value.id) <=
      getTeamformationNumberFromString(team_formation)
    )
  })
}

export const getSeasonListPermissionArray = () => {
  return [{ id: active_season(), name: active_season() }]
}

const getTeamformationNumberFromString = (string) =>
  parseInt(string.replace('Sub', ''))
