import React from 'react'
import { Show, TabbedShowLayout, Tab } from 'react-admin'
import AthleteSummary from '../../components/athlete-tabs/AthleteSummary'
import AthleteRecordsController from '../../components/athlete-tabs/AthleteRecordsController'
/* import AthleteGames from '../../components/athlete-tabs/AthleteGames' */
import AthleteQuestionnairesController from '../../components/athlete-tabs/AthleteQuestionnairesController'
import AthleteInjuriesController from '../../components/athlete-tabs/AthleteInjuriesController'
import AthletePhysicalTestsController from '../../components/athlete-tabs/AthletePhysicalTestsController'

const AthleteTitle = ({ record }) => {
  return <span>Jogador - {record ? `"${record.name}"` : ''}</span>
}

export default function AthleteShow(props) {
  return (
    <Show {...props} title={<AthleteTitle />}>
      <TabbedShowLayout>
        <Tab label="Sumário">
          <AthleteSummary {...props} />
        </Tab>
        <Tab label="Historial">
          <AthleteRecordsController {...props} />
        </Tab>
        {/*  <Tab label="Jogos">
          <AthleteGames {...props} />
        </Tab> */}
        <Tab label="Perfil Psicológico">
          <AthleteQuestionnairesController {...props} />
        </Tab>
        <Tab label="Aptidão Física">
          <AthletePhysicalTestsController {...props} />
        </Tab>
        <Tab label="Historial Lesões">
          <AthleteInjuriesController {...props} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
