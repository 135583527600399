import React, { useEffect, useState } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { makeStyles } from '@material-ui/styles'
import { line } from 'd3-shape'
import { averageValue } from '../../helpers/MathHelpers'
import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

let useStyles = makeStyles({
  chartContainer: {
    height: (props) => props.height || 450,
    backgroundColor: 'white',
    position: 'relative',
  },
  actions: {
    justifyContent: 'flex-end',
  },
})

const Bar = ({
  data,
  settings,
  getColor,
  layout,
  marginLeft,
  groupMode,
  tooltip,
  containerHeight,
  hasAverage,
  onClick,
}) => {
  const classes = useStyles({ height: containerHeight })
  const [average, setAverage] = useState()
  let history = useHistory()
  useEffect(() => {
    if (!data || !data.length > 0) {
      return
    }

    const key = settings.keys[0]
    const value_array = data.map((element) => element[key])
    const filter_values = value_array.filter(
      (value) => value !== undefined && value !== null,
    )
    setAverage(averageValue(filter_values))
  }, [data, settings])

  if (!data) {
    return 'Sem dados para o gráfico!'
  }

  if (!settings) {
    return 'Sem dados de configuração para este gráfico'
  }

  const handleOnClick = (value) => {
    if (onClick) {
      onClick(value, history)
    }
  }

  return (
    <div className={classes.chartContainer}>
      <Typography variant="subtitle1" gutterBottom>
        Média: <strong>{average}</strong>
      </Typography>
      <ResponsiveBar
        data={data}
        margin={{ top: 50, right: 50, bottom: 120, left: marginLeft }}
        padding={0.2}
        groupMode={groupMode}
        layout={layout}
        indexBy={settings.indexBy}
        keys={settings.keys}
        colorBy="indexValue"
        colors={getColor || { scheme: 'nivo' }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -40,
          legend: settings.axisBottomLegend,
          legendPosition: 'middle',
          legendOffset: 100,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: settings.axisLeftLegend,
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="white" /* {{ from: 'color', modifiers: [['brighter', 3]] }} */
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        tooltip={tooltip}
        onClick={handleOnClick}
        layers={[
          'grid',
          'axes',
          'bars',
          'area',
          hasAverage ? Line : 'line',
          'markers',
          'legends',
        ]}
      />
    </div>
  )
}

const Line = ({ bars, xScale, yScale }) => {
  const [average, setAverage] = useState()
  useEffect(() => {
    if (!bars || bars.length <= 0) {
      return
    }

    const key = bars[0].data.id
    const value_array = bars.map((element) => element.data.data[key])
    const filter_values = value_array.filter(
      (value) => value !== undefined && value !== null,
    )
    setAverage(averageValue(filter_values))
  }, [bars])

  if (!yScale(average) || !average) {
    return null
  }

  const lineGenerator = line()
    .x((bar) => {
      return xScale(bar.data.indexValue) + bar.width / 2
    })
    .y(yScale(average))

  return (
    <>
      <path
        d={lineGenerator(bars)}
        fill="none"
        stroke="red"
        style={{ pointerEvents: 'none' }}
      />
    </>
  )
}

Bar.defaultProps = {
  layout: 'vertical',
  marginLeft: 60,
  groupMode: 'stacked',
  tooltip: null,
  hasAverage: true,
}

export default Bar
