import React, { useState } from 'react'

import AthleteQuestionnaires from './questionnaires/AthleteQuestionnaires'
import QuestionnaireControllerForm from '../../resources/questionnaires/QuestionnaireControllerForm'
import InitialEvaluation from '../../resources/questionnaires/InitialEvaluation'

export default (props) => {
  let initState = 'athlete_questionnaires_list'
  const [showPage, setShowPage] = useState(initState)
  const [questionnaire, setQuestionnaire] = useState(null)
  const [inputs, setInputs] = useState({
    athlete_id: props.record.id,
    season_id: '2020-2021',
    filledwhen: 'inicio',
  })

  return (
    <div style={{ marginBottom: 15 }}>
      {showPage === 'athlete_questionnaires_list' && (
        <AthleteQuestionnaires
          {...props}
          setShowPage={setShowPage}
          setQuestionnaire={setQuestionnaire}
          showPage={showPage}
          inputs={inputs}
          setInputs={setInputs}
        />
      )}

      {showPage === 'athlete_questionaire_action' && (
        <QuestionnaireControllerForm
          {...props}
          setShowPage={setShowPage}
          questionnaire={questionnaire}
          showPage={showPage}
        />
      )}

      {showPage === 'athlete_init_eval' && (
        <InitialEvaluation
          {...props}
          setShowPage={setShowPage}
          questionnaire={questionnaire}
          showPage={showPage}
        />
      )}
    </div>
  )
}
