const MAX_USER_CACHE_MS = 1000 * 60 * 10 // 10 minutes

const userCache = {
  user: null,
  lastUpdated: null,
}

const getUserCache = () => {
  if (!userCache.user) {
    return null
  }
  if (Date.now() - userCache.lastUpdated > MAX_USER_CACHE_MS) {
    return null
  }

  return userCache.user
}

const setUserCache = (userPromise) => {
  userCache.user = userPromise
  userCache.lastUpdated = Date.now()
  return userPromise
}

const clearUserCache = () => {
  userCache.user = null
  userCache.lastUpdated = null
}

export default { get: getUserCache, set: setUserCache, clear: clearUserCache }
