import { useState, useEffect } from 'react'
import dataProvider from '../auth/auth-provider'

export function useCurrentUser() {
  const [user, setUser] = useState(null)

  useEffect(() => {
    ;(async () => {
      const response = await dataProvider.getCurrentUser()
      setUser(response)
    })()
  })

  return user
}
