import SeasonsList from '../data/seasons-storage'

export const seasons_list = () => {
  return SeasonsList.get()
}

export const active_season = () => {
  const active_season = SeasonsList.get().find((season) => season.is_active)
  return active_season ? active_season.id : null
}
