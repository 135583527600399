import React, { useState, useEffect } from 'react'
import {
  LinearProgress,
  SimpleForm,
  SelectInput,
  CreateView,
} from 'react-admin'
import { Grid, Box, Typography } from '@material-ui/core'
import api from '../data/axios-config'
import { useNotify } from 'react-admin'
import { filled_when } from '../constants/physical_tests_list'
import { getTeamFormationPermissionArray } from '../helpers/FilterPermissions'
import {
  physical_tests_average_graphs,
  defaultPositionsColors,
} from '../constants/graphs'
import TabComponent from './TabComponent'
import ChipCustomColor from './ChipCustomColor'

export default ({ firstTeamFormation, urlCallback }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [input, setInput] = useState({
    team_formation: firstTeamFormation,
    physical_test: 'bars',
    filledwhen: 'inicio',
  })
  const notify = useNotify()

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const result = await api.get(urlCallback(input))
        setData(result.data)
      } catch (err) {
        notify(err.message, 'error')
      } finally {
        setLoading(false)
      }
    })()
  }, [notify, input])

  function getPhysicalTest() {
    return physical_tests_average_graphs.find(
      (test) => test.id === input.physical_test,
    )
  }

  if (!urlCallback) {
    return 'Necessário definir um url!'
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item lg={3} sm={12} xl={2} xs={12}>
          <InputsForm
            input={input}
            setInput={setInput}
            firstTeamFormation={firstTeamFormation}
          />
          <Box mt="0.5em" />
          <ColorExplanation />
          <Box mt="0.5em" />
        </Grid>
        <Grid item lg={9} sm={12} xl={10} xs={12}>
          {loading ? (
            <LinearProgress />
          ) : (
            <GraphContent data={data} config={getPhysicalTest()} />
          )}
        </Grid>
      </Grid>
    </>
  )
}

const GraphContent = (props) => {
  const { config } = props
  if (!config || !config.graph) {
    return 'Ainda não existe um gráfico para este teste físico!'
  }

  return config.multiple ? (
    <MultipleGraphs {...props} />
  ) : (
    <SingleGraph {...props} />
  )
}

const MultipleGraphs = ({ data, config }) => {
  const { settings } = config
  let graphs = settings.keys.map((key) => {
    if (!settings.multipleLegends[key]) {
      return `settings.multipleLegends[key] para chave: ${key}, não existe na configuração do gráfico!`
    }

    const { axisLeftLegend, tabLabel } = settings.multipleLegends[key]
    const single_graph_settings = {
      keys: [key],
      axisBottomLegend: settings.axisBottomLegend,
      axisLeftLegend: axisLeftLegend,
      indexBy: settings.indexBy,
    }
    return (
      <SingleGraph
        data={data}
        config={{ graph: config.graph, settings: single_graph_settings }}
        tabLabel={tabLabel}
        key={`single-graph-${tabLabel}`}
      />
    )
  })

  return <TabComponent>{graphs}</TabComponent>
}

const SingleGraph = ({ data, config }) => {
  return React.cloneElement(config.graph, {
    data: data,
    settings: config.settings,
  })
}

const InputsForm = (props) => {
  const { input, setInput, firstTeamFormation } = props

  return (
    <CreateView title=" ">
      <SimpleForm toolbar={null} initialValues={input}>
        <SelectInput
          source="team_formation"
          label="Escalões"
          choices={getTeamFormationPermissionArray(firstTeamFormation)}
          defaultValue={firstTeamFormation}
          onChange={(event) => {
            setInput({
              team_formation: event.target.value,
              physical_test: input.physical_test,
              filledwhen: input.filledwhen,
            })
          }}
        />
        <SelectInput
          source="physical_test"
          label="Testes Físicos"
          choices={physical_tests_average_graphs}
          onChange={(event) => {
            setInput({
              team_formation: input.team_formation,
              physical_test: event.target.value,
              filledwhen: input.filledwhen,
            })
          }}
        />
        <SelectInput
          source="filledwhen"
          label="Momento"
          choices={filled_when}
          onChange={(event) => {
            setInput({
              team_formation: input.team_formation,
              physical_test: input.physical_test,
              filledwhen: event.target.value,
            })
          }}
        />
      </SimpleForm>
    </CreateView>
  )
}

const ColorExplanation = () => {
  const chips_explanation = defaultPositionsColors.map((position) => {
    return (
      <div key={`chip-explanation-${position.color}`}>
        <Box mt="0.2em" />
        <ChipCustomColor color={position.color} label={position.name} />
      </div>
    )
  })

  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Significado das cores:
      </Typography>
      {chips_explanation}
    </>
  )
}
