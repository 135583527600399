export default [
  { id: 1, name: 'Calheta' },
  { id: 2, name: 'Câmara de Lobos' },
  { id: 3, name: 'Funchal' },
  { id: 4, name: 'Machico' },
  { id: 5, name: 'Ponta do Sol' },
  { id: 6, name: 'Porto Moniz' },
  { id: 7, name: 'Porto Santo' },
  { id: 8, name: 'Ribeira Brava' },
  { id: 9, name: 'Santa Cruz' },
  { id: 10, name: 'Santana' },
  { id: 11, name: 'São Vicente' },
]
