import http from '../data/axios-config'

const storeSeasonsList = async () => {
  const list = await requestSeasonsList()
  localStorage.setItem('season_list', JSON.stringify(list.data))
}

const getSeasonsList = () => {
  if (!localStorage.getItem('season_list')) {
    storeSeasonsList()
  }

  return JSON.parse(localStorage.getItem('season_list'))
}

const requestSeasonsList = async () => {
  const seasons = await http.get('seasons_get_list')
  return seasons
}

export default {
  store: storeSeasonsList,
  get: getSeasonsList,
}
