export const school_data_questions = {
  school_thoughts: 'O que pensas da escola?',
  student_performance: 'Globalmente, na escola consideras-te um aluno...',
  math:
    'Quais foram as notas que obtiveste no final do ano passado na disciplina de Matemática?',
  portuguese:
    'Quais foram as notas que obtiveste no final do ano passado na disciplina de Português?',
  physical:
    'Quais foram as notas que obtiveste no final do ano passado na disciplina de Educação Física?',
}

export const socio_economic_data_questions = {
  male_job:
    'Profissão do pai ou da pessoa, do sexo masculino, responsável por ti?',
  female_job:
    'Profissão do mãe ou da pessoa, do sexo femenino, responsável por ti?',
  male_degree: 'Habilitações do responsavel masculino',
  female_degree: 'Habilitações do responsavel femenino',
  cohab_number:
    'Quantas pessoas habitam na tua casa? (Obs. Tu não estás incluído)',
}

export const sport_passions_questions = {
  experiences: 'Esta modalidade permite-me vivenciar diferentes experiências?',
  apreciation:
    'As coisas novas que eu descubro com esta modalidade permitem-me apreciá-la ainda mais?',
  memorable: 'Esta modalidade permite-me vivenciar experiências memoráveis?',
  commitment: 'Estou completamente envolvido(a) nesta modalidade?',
  total_commitment: 'Não consigo viver sem ela?',
  need:
    'A necessidade é tão forte que não consigo deixar de praticar esta modalidade?',
  cant_imagine:
    'Tenho dificuldade em imaginar a minha vida sem esta modalidade?',
  dependency: 'Estou emocionalmente dependente desta modalidade?',
}

export const vitalities_questions = {
  feel_alive: 'Sinto-me vivo(a) e vitalizado(a)?',
  vigourous:
    'Por vezes sinto-me com tanto vigor que parece que vou explodir de entusiasmo?',
  energie: 'Tenho energia e carisma?',
  optimal: 'Olho com otimismo para cada novo dia?',
  alert: 'Quase sempre sinto-me alerta e desperto(a)?',
  strength: 'Sinto-me com força e pujança?',
}

export const positive_negative_questions = {
  interess: 'Interessado(a)?',
  torment: 'Perturbado(a)/Atormentado?',
  guilt: 'Culpado(a)?',
  scared: 'Assustado(a)?',
  excited: 'Entusiasmado(a)?',
  inspired: 'Inspirado(a)?',
  nervous: 'Nervoso(a)?',
  determined: 'Determinado(a)?',
  active: 'Ativo(a)?',
  fear: 'Medo/amedrontado?',
}

export const sleep_habits_questions = {
  wake_up:
    'A que horas acordas, normalmente, nos dias em que há aulas? (Obs.: Caso não tenhas aulas, indica as horas que acordas nos dias da semana.)',
  sleep_time:
    'A que horas vais dormir, normalmente, nos dias em que há aulas? (Obs.: Caso não tenhas aulas, indica as horas que vais dormir nos dias da semana.)',
  weekend:
    'Insere a que horas acordas, normalmente, nos dias de fim-de-semana?',
  sleeping: 'A que horas vais dormir, normalmente, nos dias de fim-de-semana?',
}

export const psych_need_questions = {
  freedom:
    'Tenho uma sensação de escolha e liberdade nos exercícios que eu faço no treino?',
  not_obliged:
    'A maioria dos exercícios que realizo no treino, sinto que são por “obrigação”?',
  included:
    'Sinto que as pessoas com quem eu treino também se preocupam comigo?',
  excluded: 'Sinto-me excluído do grupo ao qual eu quero pertencer no clube?',
  confidant:
    'Sinto-me confiante de que posso fazer os exercícios de treino corretamente?',
  doubt:
    'Tenho muitas dúvidas se consigo fazer os exercícios de treino corretamente?',
  train_reflect:
    'Sinto que a forma como treino reflete o que eu realmente quero?',
  forced_train:
    'Sinto-me forçado a fazer exercícios que eu não escolheria fazer?',
  club_connect:
    'Sinto-me ligado às pessoas do clube que se preocupam comigo e pelas quais me preocupo?',
  cold_person:
    'Sinto que no clube, as pessoas que são importantes para mim são frias e distantes comigo?',
  capability: 'Sinto-me capaz nos exercícios de treino que realizo?',
  performance: 'Nalguns exercícios, sinto-me desapontado com o meu desempenho?',
  pretended_exercises:
    'Sinto que os exercícios que realizo expressam o que eu realmente quero?',
  pressed: 'Sinto-me pressionado para fazer certos exercícios no treino?',
  club_closed:
    'Sinto-me próximo das pessoas no clube que são importantes para mim?',
  dont_like_me:
    'Tenho a impressão que as pessoas com as quais me relaciono no clube não gostam de mim?',
  objective: 'Sinto-me competente para alcançar os meus objetivos?',
  insecure: 'Sinto-me inseguro acerca das minhas capacidades durante o treino?',
  important_trains:
    'Sinto que tenho feito os exercícios que realmente me interessam?',
  burden:
    'As atividades que eu faço no treino parecem-me que são obrigações/um fardo?',
  warmth:
    'Eu experiencio um sentimento caloroso com as pessoas com as quais me relaciono no clube?',
  superficial:
    'Sinto que as relações que estabeleço no clube são apenas superficiais?',
  dificulty:
    'Sinto que consigo realizar com sucesso exercícios avançados/difíceis?',
  failure:
    'Sinto-me como um fracassado por causa dos erros que cometo durante o treino?',
}

export const motivations_questions = {
  why_continue: 'Mas questiono-me por que razão?',
  pressured: 'Porque as pessoas me pressionam?',
  guilty_quit: 'Porque me sentiria culpado se desistisse?',
  important_benefits: 'Porque os benefícios são importantes para mim?',
  find_me: 'Porque é uma oportunidade para ser quem eu sou?',
  enjoy: 'Porque me dá prazer?',
  reason_why: 'Mas questiono-me por que razão me sujeito a isto?',
  satisfy_other: 'Para satisfazer todos aqueles que querem que eu pratique?',
  embarrassed: 'Porque me sentiria envergonhado se desistisse?',
  value_benefits: 'Porque valorizo os seus benefícios?',
  part_of_me: 'Porque faz parte mim?',
  because_enjoy: 'Porque gosto?',
  foggy_reasons: 'Mas as razões para tal já não são claras para mim?',
  pressed_by_others:
    'Porque me sinto pressionado por outras pessoas para praticar?',
  have_continue: 'Porque me sinto obrigado a continuar?',
  autodisciple: 'Porque me ensina a ser autodisciplinado?',
  expression_of_me:
    'Porque o que faço no desporto é uma expressão do que eu sou?',
  fun: 'Porque é divertido?',
  worth_it: 'Mas questiono-me se valerá a pena?',
  satisfy_others:
    'Porque se não o fizer, as outras pessoas não ficarão satisfeitas comigo?',
  failures: 'Por me sentiria um falhado se desistisse?',
  learn_new:
    'Porque é uma boa forma de aprender coisas que podem ser úteis na minha vida?',
  standarts: 'Porque me permite viver de acordo com os meus valores?',
  nice: 'Porque acho agradável?',
}

export const life_satisfactions_questions = {
  ideal:
    'Na maioria dos aspetos, a minha vida aproxima-se do meu ideal de vida?',
  excelent: 'As condições da minha vida são excelentes?',
  satisfied: 'Estou satisfeito/a com a minha vida?',
  reach:
    'Até agora, tenho conseguido alcançar as coisas que considero importantes na vida?',
  not_change: 'Se pudesse viver a minha vida outra vez não mudaria nada?',
}

export const life_styles_questions = {
  talk:
    'Tenho com quem falar e comunico com essas pessoas de forma aberta, honesta e clara sobre os assuntos que são importantes para mim?',
  afection: 'Dou e recebo afetos?',
  emotional: 'Tenho o suporte emocional que preciso?',
  be_active:
    'Sou fisicamente ativo no meu dia-a-dia (por exemplo: ando a pé, desloco-me de bicicleta, subo e desço escadas, etc.)?',
  exercise: 'Faço exercício físico/desporto pelo menos 30 minutos seguidos?',
  relaxe: 'Consigo relaxar e divertir-me durante o meu tempo de lazer?',
  balance_food:
    'Faço uma alimentação equilibrada (Alimentação equilibrada = escolho com maior frequência produtos integrais ou enriquecidos, vegetais verde-escuros e alaranjados, produtos com baixo conteúdo de gordura, carnes magras (aves e peixes) e/ou ervilhas, feijão e lentilhas)?',
  sugar:
    'Frequentemente como alimentos com excesso de açúcar, sal, gordura animal, ou fast food?',
  overweight:
    'Estou com peso a mais em relação ao peso ideal/saudável, considerado para mim?',
  smoke: 'Fumo cigarros?',
  drugs: 'Consumo substâncias psicoativas ilegais: ',
  medication:
    'Uso excessivamente medicamentos que me receitam ou que posso adquirir livremente nas farmácias?',
  cafeine:
    'Ingiro bebidas que contêm cafeína (por exemplo: café, coca-cola, chá preto, etc.)?',
  alcohol:
    'O meu consumo médio semanal de álcool é de (obs.: 1 dose de álcool = 1 lata de cerveja (330ml) ou 1 copo de vinho (142ml) ou 1 shot (42ml))?',
  over_four: 'Consumo mais de 4 doses na mesma ocasião?',
  drink_drive: 'Conduzo após beber?',
  sleep: 'Durmo bem e sinto-me descansado?',
  stress: 'Sou capaz de gerir o stresse do meu dia-a-dia?',
  enjoyng: 'Relaxo e desfruto do meu tempo de lazer?',
  speed: 'Sinto que ando acelerado(a)/ atarefado(a)?',
  bored: 'Sinto-me aborrecido(a) e/ou agressivo(a)?',
  anger: 'Sinto-me com raiva e hostil?',
  optimist: 'Penso de forma positiva e otimista?',
  ansious: 'Sinto-me ansioso(a) e preocupado(a)?',
  depressed: 'Sinto-me triste e deprimido(a)?',
  work: 'Sinto-me satisfeito(a) com o meu trabalho e/ou função?',
  relation: 'Tenho uma boa relação com os outros?',
  lifestyle: 'Escolha a opção que mais se adequa',
}

export const problem_solvings_questions = {
  abstraction: 'Refugio-me noutras atividades para me abstrair da situação?',
  efforts:
    'Concentro os meus esforços para fazer alguma coisa que me permita enfrentar a situação?',
  not_true: 'Tenho dito para mim próprio(a): “isto não é verdade”?',
  pills:
    'Refugio-me no álcool ou noutras drogas (comprimidos, etc), para me sentir melhor?',
  emocional_support: 'Procuro apoio emocional de alguém (família, amigos)?',
  give_up: 'Simplesmente desisto de tentar lidar com isto?',
  improve: 'Tomo medidas para tentar melhorar a minha situação?',
  refuse_believe: 'Recuso-me a acreditar que isto esteja a acontecer comigo?',
  express_feel: 'Fico aborrecido(a) e expresso os meus sentimentos?',
  ask_advice:
    'Peço conselhos e ajuda a outras pessoas para enfrentar melhor a situação?',
  use_drugs:
    'Uso álcool ou outras drogas (comprimidos) para me ajudar a ultrapassar os problemas?',
  think_positive:
    'Tento analisar a situação de maneira diferente, de forma a torná-la mais positiva?',
  criticize: 'Faço críticas a mim próprio(a)?',
  strategy:
    'Tento encontrar uma estratégia que me ajude no que tenho que fazer?',
  understanding: 'Procuro o conforto e compreensão de alguém?',
  give_leed: 'Desisto de me esforçar para lidar com a situação?',
  something: 'Procuro algo positivo em tudo o que está a acontecer?',
  as_joke: 'Enfrento a situação levando-a para a brincadeira?',
  shopping:
    'Faço outras coisas para pensar menos na situação, tal como ir ao cinema, ver TV, ler, sonhar, ou ir às compras?',
  accept_it: 'Tento aceitar as coisas tal como estão a acontecer?',
  feel_bored: 'Sinto e expresso os meus sentimentos de aborrecimento?',
  religion: 'Tento encontrar conforto na minha religião ou crença espiritual?',
  previous_experience:
    'Peço conselhos e ajuda a pessoas que passaram pelo mesmo?',
  same_situation: 'Tento aprender a viver com a situação?',
  learn_to_live: 'Penso muito sobre a melhor forma de lidar com a situação?',
  blame_happens: 'Culpo-me pelo que está a acontecer?',
  pray: 'Rezo ou medito?',
  sense_humor: 'Enfrento a situação com sentido de humor?',
}

export const sports_questions = {
  types:
    'Das seguintes modalidades desportivas, seleciona aquelas que já praticaste ou ainda praticas, seja no âmbito do desporto escolar ou em contexto federado.',
}

export const init_eval_questions = {
  intolerance: 'Possui intolerancias ? Se Sim indique quais.',
  intolerance_text: 'Indique quais, as suas  intolerancias.',
  waking: 'Tem dificuldade em acordar?',
  mood: 'Tem mau humor?',
  intestinal: 'Tem trânsito intestinal normal?',
  alergies: 'Possui alergias? Se Sim indique quais.',
  alergies_text: 'Indique quais são as suas alergias.',
  pain: 'Tem Dores Articulares? Se Sim indique a zona.',
  pain_text: 'Indique a zona.',
  medication: 'Toma Medicação? Se Sim indique quais.',
  medication_text: 'Indique a sua Medicação.',
  smoking: 'É	fumador?',
  candy: 'Costuma ter desejo por doces?',
  water: 'Quantos litros de água bebe por dia',
  train: 'Treinou durante as férias?',
}
