import React, { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import api from '../../data/axios-config'
import { Grid } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IndicatorBoard from './IndicatorBoard'
import StorageIcon from '@material-ui/icons/Storage'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import EventNoteIcon from '@material-ui/icons/EventNote'
import Button from '@material-ui/core/Button'
import SeasonsList from '../../data/seasons-storage'
import CircularProgress from '@material-ui/core/CircularProgress'
import AthletesDayTabletUse from './AthletesDayTabletUse'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  title: {
    fontWeight: 400,
  },
  card: {
    //backgroundColor: '#565656',
    marginTop: '1rem',
  },
  indCard: {
    height: '100%',
  },
  difference: {
    marginTop: theme.spacing(0),
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    height: 32,
    width: 32,
  },
  avatar: {
    height: 56,
    width: 56,
  },
}))

const AdminDashboard = (props) => {
  const classes = useStyles()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const notify = useNotify()

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const result = await api.get(`dashboard`)
        setData(result.data)
      } catch (err) {
        notify(err.message, 'error')
      } finally {
        setLoading(false)
      }
    })()
  }, [notify])

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <ReloadSeasonsListButton />
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <IndicatorBoard
                {...classes}
                title="Dados Gerais"
                loading={loading}
                data={data ? data.geral_stats : null}
                icon={
                  <StorageIcon
                    style={{ color: '#fff' }}
                    className={classes.icon}
                  />
                }
                backgroundAvatarColor="#757575"
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <IndicatorBoard
                {...classes}
                title="Dados do Dia"
                loading={loading}
                data={data ? data.today_stats : null}
                icon={
                  <CalendarTodayIcon
                    style={{ color: '#fff' }}
                    className={classes.icon}
                  />
                }
                backgroundAvatarColor="#757575"
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <IndicatorBoard
                {...classes}
                title="Dados da Época"
                loading={loading}
                data={data ? data.season_stats : null}
                icon={
                  <EventNoteIcon
                    style={{ color: '#fff' }}
                    className={classes.icon}
                  />
                }
                backgroundAvatarColor="#757575"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <AthletesDayTabletUse {...props} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

const ReloadSeasonsListButton = () => {
  const classes = useStyles()
  const notify = useNotify()
  const [loading, setLoading] = useState(false)

  const handleReload = async () => {
    try {
      setLoading(true)
      await SeasonsList.store()
      notify('Lista das épocas actulizada com sucesso!', 'success')
    } catch (err) {
      notify(err.message, 'error')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={handleReload}>
      {loading && (
        <CircularProgress className={classes.icon} size={18} thickness={2} />
      )}
      Actualizar a lista das épocas
    </Button>
  )
}

export default AdminDashboard
