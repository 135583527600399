export const injuries_list = [
  { id: 'Concussão', name: 'Concussão' },
  { id: 'Fratura', name: 'Fratura' },
  { id: 'Ligamentar', name: 'Ligamentar' },
  { id: 'Subluxação', name: 'Subluxação' },
  { id: 'Entorse', name: 'Entorse' },
  { id: 'Muscular', name: 'Muscular' },
  { id: 'Tendinosa', name: 'Tendinosa' },
  { id: 'Pubalgia', name: 'Pubalgia' },
  { id: 'Hérnia', name: 'Hérnia' },
  { id: 'Lesão Dentária', name: 'Lesão Dentária' },
  { id: 'Outro', name: 'Outro' },
]

export const fractura_options = [
  { id: 'Osso da Cabeça', name: 'Osso da Cabeça' },
  { id: 'Osso da Cara', name: 'Osso da Cara' },
  { id: 'Nariz', name: 'Nariz' },
  { id: 'Mandíbula', name: 'Mandíbula' },
  { id: 'Maxilar', name: 'Maxilar' },
  { id: 'Pescoço (Coluna Cervical)', name: 'Pescoço (Coluna Cervical)' },
  { id: 'Coluna Torácica', name: 'Coluna Torácica' },
  { id: 'Coluna Lombar', name: 'Coluna Lombar' },
  { id: 'Bacia', name: 'Bacia' },
  { id: 'Clavícula', name: 'Clavícula' },
  { id: 'Escápula', name: 'Escápula' },
  { id: 'Esterno', name: 'Esterno' },
  { id: 'Costelas', name: 'Costelas' },
  { id: 'Úmero', name: 'Úmero' },
  { id: 'Rádio', name: 'Rádio' },
  { id: 'Cúbito', name: 'Cúbito' },
  { id: 'Carpo', name: 'Carpo' },
  { id: 'Metacarpo', name: 'Metacarpo' },
  { id: 'Falange (Mão)', name: 'Falange (Mão)' },
  { id: 'Fémur', name: 'Fémur' },
  { id: 'Rótula', name: 'Rótula' },
  { id: 'Tíbia', name: 'Tíbia' },
  { id: 'Perónio', name: 'Perónio' },
  { id: 'Tarso', name: 'Tarso' },
  { id: 'Metatarso', name: 'Metatarso' },
  { id: 'Falange (Pé)', name: 'Falange (Pé)' },
]

export const ligamentar_options = [
  { id: 'Ligamento Colateral Medial', name: 'Ligamento Colateral Medial' },
  { id: 'Ligamento Colateral Lateral', name: 'Ligamento Colateral Lateral' },
  {
    id: 'Ligamento Cruzado Anterior (LCA)',
    name: 'Ligamento Cruzado Anterior (LCA)',
  },
  { id: 'Ligamento Cruzado Posterior', name: 'Ligamento Cruzado Posterior' },
  { id: 'Menisco Lateral', name: 'Menisco Lateral' },
  { id: 'Menisco Medial', name: 'Menisco Medial' },
  { id: 'Outro', name: 'Outro' },
]

export const subluxacao_options = [
  { id: 'Ombro', name: 'Ombro' },
  { id: 'Joelho', name: 'Joelho' },
  { id: 'Outro', name: 'Outro' },
]

export const entorse_options = [
  { id: 'Joelho', name: 'Joelho' },
  { id: 'Tibiotársica (Tornozelo)', name: 'Tibiotársica (Tornozelo)' },
  { id: 'Outro', name: 'Outro' },
]

export const muscular_options = [
  { id: 'Esternocleidomastóideo', name: 'Esternocleidomastóideo' },
  { id: 'Trapézio', name: 'Trapézio' },
  { id: 'Deltoide', name: 'Deltoide' },
  { id: 'Peitoral Maior', name: 'Peitoral Maior' },
  { id: 'Reto Abdominal', name: 'Reto Abdominal' },
  { id: 'Oblíquo Externo', name: 'Oblíquo Externo' },
  { id: 'Dorsal', name: 'Dorsal' },
  { id: 'Bicípite', name: 'Bicípite' },
  { id: 'Tricípite', name: 'Tricípite' },
  { id: 'Glúteo Máximo', name: 'Glúteo Máximo' },
  { id: 'Quadricípite', name: 'Quadricípite' },
  { id: 'Adutor', name: 'Adutor' },
  { id: 'Isquiotibial', name: 'Isquiotibial' },
  { id: 'Tibial Anterior', name: 'Tibial Anterior' },
  { id: 'Gémeo', name: 'Gémeo' },
  { id: 'Solear', name: 'Solear' },
  { id: 'Outro', name: 'Outro' },
]

export const tendinosa_options = [
  { id: 'Tendão Rotuliano', name: 'Tendão Rotuliano' },
  { id: 'Tendão de Aquiles', name: 'Tendão de Aquiles' },
  { id: 'Outro', name: 'Outro' },
]

export const laterality_options = [
  { id: 'Direito', name: 'Direito' },
  { id: 'Esquerdo', name: 'Esquerdo' },
  { id: 'Bilateral', name: 'Bilateral' },
  { id: 'Central', name: 'Central' },
]

const injuries_list_options = [
  { id: 'Fratura', options: fractura_options },
  { id: 'Ligamentar', options: ligamentar_options },
  { id: 'Subluxação', options: subluxacao_options },
  { id: 'Entorse', options: entorse_options },
  { id: 'Muscular', options: muscular_options },
  { id: 'Tendinosa', options: tendinosa_options },
]

export const existInjurieOptions = (type) => {
  const injurie = injuries_list_options.find((element) => element.id === type)
  return injurie ? true : false
}

export const getOptionsByInjurieType = (type) => {
  const injurie = injuries_list_options.find((element) => element.id === type)
  return injurie.options
}
