import React, { useState, useEffect } from 'react'
import { useNotify, SelectInput, CreateView, SimpleForm } from 'react-admin'
import { Box, Typography } from '@material-ui/core'
import { seasons_list, active_season } from '../../../helpers/SeasonsHelper'
import api from '../../../data/axios-config'
import { filled_when } from '../../../constants/physical_tests_list'
import PhysicalTestContent from './PhysicalTestContent'
import ChipCustomColor from '../../ChipCustomColor'

export default function AthletePhysicalTests(props) {
  const { setShowPage, setPhysicalTest, inputs, setInputs } = props
  const notify = useNotify()
  const [loadingList, setLoadingList] = useState(true)
  const [data, setData] = useState(null)
  const [contentState, setContentState] = useState('macro')

  useEffect(() => {
    ;(async () => {
      try {
        setLoadingList(true)
        const response = await api.get(
          `/athletes/${inputs.athlete_id}/physical_tests/${inputs.season_id}/${inputs.filledwhen}`,
        )
        setData(response.data)
      } catch (err) {
        notify(err.message, 'error')
      } finally {
        setLoadingList(false)
      }
    })()
  }, [inputs, notify])

  return (
    <>
      <Box display="flex">
        <Box flex={2} mr="1em">
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <InputsForm inputs={inputs} setInputs={setInputs} />
              <Box mt="2em" />
              <CardColorExplanation />
            </Box>
            <Box flex={8} ml={{ xs: 0, sm: '0.5em' }}>
              <PhysicalTestContent
                data={data && data.physical_tests}
                loading={loadingList}
                setShowPage={setShowPage}
                setPhysicalTest={setPhysicalTest}
                inputs={inputs}
                contentState={contentState}
                setContentState={setContentState}
              />
            </Box>
            <Box flex={8} ml={{ xs: 0, sm: '0.5em' }} />
          </Box>
        </Box>
      </Box>
    </>
  )
}

const InputsForm = (props) => {
  const { inputs, setInputs } = props

  return (
    <CreateView title=" - Lista de testes físicos">
      <SimpleForm toolbar={null} initialValues={inputs}>
        <SelectInput
          source="season_id"
          label="Época"
          choices={seasons_list()}
          defaultValue={active_season()}
          onChange={(event) => {
            setInputs({
              athlete_id: inputs.athlete_id,
              season_id: event.target.value,
              filledwhen: inputs.filledwhen,
            })
          }}
        />
        <SelectInput
          source="filledwhen"
          label="Momento"
          choices={filled_when}
          onChange={(event) => {
            setInputs({
              athlete_id: inputs.athlete_id,
              season_id: inputs.season_id,
              filledwhen: event.target.value,
            })
          }}
        />
      </SimpleForm>
    </CreateView>
  )
}

const CardColorExplanation = () => {
  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Significado das cores:
      </Typography>
      <Box mt="0.5em" />
      <Box mt="0.5em" />
      <ChipCustomColor color="#E0E0E0" label={'Não preenchido'} />
      <Box mt="0.5em" />
      <ChipCustomColor color="#FFC400" label={'Incompleto'} />
      <Box mt="0.5em" />
      <ChipCustomColor color="#4CAF50" label={'Preenchido'} />
    </>
  )
}
