import React, { useEffect } from 'react'
import { NumberInput, useInput } from 'react-admin'
import { useFormState } from 'react-final-form'

const MathDependentInput = (props) => {
  const { source, label, fullWidth, validate, calculate, variables } = props
  const {
    input: { onChange },
  } = useInput(props)
  const { values } = useFormState()

  const getValues = () => {
    if (typeof variables === 'object') {
      const array_values = variables.map((name) => values[name])
      return array_values.filter((value) => value)
    }

    return values[variables]
  }

  useEffect(() => {
    onChange(calculate(getValues()))
  })

  return (
    <NumberInput
      source={source}
      label={label}
      onChange={onChange}
      fullWidth={fullWidth}
      validate={validate}
    />
  )
}

export default MathDependentInput
