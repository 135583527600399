import React, { useState, useEffect } from 'react'
import BackButton from '../../components/BackButton'
import {
  useNotify,
  LinearProgress,
  CreateView,
  Toolbar,
  SaveButton,
  TextInput,
  FormWithRedirect,
  SelectInput,
  required,
  FormDataConsumer,
} from 'react-admin'
import api from '../../data/axios-config'
import { yes_no_options } from '../../constants/questionnaires_responses'
import { init_eval_questions } from '../../constants/questionnaires_questions'
import { merge } from 'lodash'
import { Box } from '@material-ui/core'
import DeleteButtonWithConfirm from '../../components/DeleteButtonWithConfirm'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const DEFAULT_VALUES = ({ action, athlete_id, season_id }) => {
  return {
    intolerance: null,
    intolerance_text: null,
    waking: null,
    mood: null,
    intestinal: null,
    alergies: null,
    alergies_text: null,
    pain: null,
    pain_text: null,
    medication: null,
    medication_text: null,
    smoking: null,
    candy: null,
    water: null,
    train: null,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
  }
}

export default (props) => {
  const { setShowPage, questionnaire } = props
  const notify = useNotify()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(questionnaire.action === 'edit')

  useEffect(() => {
    if (questionnaire.action === 'create') {
      return
    }

    ;(async () => {
      try {
        setLoading(true)
        const response = await api.get(
          `/athletes/${questionnaire.athlete_id}/init_eval/${questionnaire.season_id}`,
        )
        setData(response.data)
      } catch (err) {
        notify(err.message, 'error')
      } finally {
        setLoading(false)
      }
    })()
  }, [questionnaire, notify])

  if (loading) {
    return <LinearProgress />
  }

  return (
    <>
      <BackButton
        onBack={() => {
          setShowPage('athlete_questionnaires_list')
        }}
      />
      <CreateView
        record={{}}
        title={
          questionnaire.action === 'edit'
            ? ' - Editar avaliação inicial'
            : ' - Criar avaliação inicial'
        }>
        <InitEvalForm {...props} data={data} questionnaire={questionnaire} />
      </CreateView>
    </>
  )
}

function fixDataTexts(data, column) {
  const string_split = data[column].split(', ')
  if (string_split[0] === 'Sim') {
    data[column] = string_split[0]
    data[`${column}_text`] = string_split[1]
  }
}

const InitEvalForm = (props) => {
  const { setShowPage, questionnaire, data } = props
  let fix_data = data
  if (Object.entries(data).length !== 0) {
    fixDataTexts(data, 'intolerance')
    fixDataTexts(data, 'alergies')
    fixDataTexts(data, 'pain')
    fixDataTexts(data, 'medication')
  }

  let initialValues = merge({}, DEFAULT_VALUES(questionnaire), fix_data)

  return (
    <FormWithRedirect
      {...props}
      initialValues={initialValues}
      redirect={() => {
        setShowPage('athlete_questionnaires_list')
      }}
      render={(formProps) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={1} mr="1em">
                <FirstQuestionColumn />
              </Box>
              <Box flex={1} ml="1em">
                <SecondQuestionColumn />
              </Box>
            </Box>
          </Box>
          {React.cloneElement(
            <InitEvalToolbar
              setShowPage={setShowPage}
              recordId={data ? data.id : null}
            />,
            {
              props,
              handleSubmitWithRedirect: formProps.handleSubmitWithRedirect,
            },
          )}
        </form>
      )}
    />
  )
}

const FirstQuestionColumn = (props) => {
  return (
    <>
      <SelectInput
        source="intolerance"
        label={init_eval_questions.intolerance}
        choices={yes_no_options}
        validate={[required()]}
        fullWidth
      />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.intolerance === 'Sim' && (
            <TextInput
              source="intolerance_text"
              label={init_eval_questions.intolerance_text}
              {...rest}
              validate={[required()]}
              fullWidth
            />
          )
        }
      </FormDataConsumer>
      <SelectInput
        source="waking"
        label={init_eval_questions.waking}
        choices={yes_no_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="mood"
        label={init_eval_questions.mood}
        choices={yes_no_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="intestinal"
        label={init_eval_questions.intestinal}
        choices={yes_no_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="alergies"
        label={init_eval_questions.alergies}
        choices={yes_no_options}
        validate={[required()]}
        fullWidth
      />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.alergies === 'Sim' && (
            <TextInput
              source="alergies_text"
              label={init_eval_questions.alergies_text}
              {...rest}
              validate={[required()]}
              fullWidth
            />
          )
        }
      </FormDataConsumer>
      <SelectInput
        source="pain"
        label={init_eval_questions.pain}
        choices={yes_no_options}
        validate={[required()]}
        fullWidth
      />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.pain === 'Sim' && (
            <TextInput
              source="pain_text"
              label={init_eval_questions.pain_text}
              {...rest}
              validate={[required()]}
              fullWidth
            />
          )
        }
      </FormDataConsumer>
    </>
  )
}

const SecondQuestionColumn = (props) => {
  return (
    <>
      <SelectInput
        source="medication"
        label={init_eval_questions.medication}
        choices={yes_no_options}
        validate={[required()]}
        fullWidth
      />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.medication === 'Sim' && (
            <TextInput
              source="medication_text"
              label={init_eval_questions.medication_text}
              {...rest}
              validate={[required()]}
              fullWidth
            />
          )
        }
      </FormDataConsumer>
      <SelectInput
        source="smoking"
        label={init_eval_questions.smoking}
        choices={yes_no_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="candy"
        label={init_eval_questions.candy}
        choices={yes_no_options}
        validate={[required()]}
        fullWidth
      />
      <TextInput
        source="water"
        label={init_eval_questions.water}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="train"
        label={init_eval_questions.train}
        choices={yes_no_options}
        validate={[required()]}
        fullWidth
      />
    </>
  )
}

const InitEvalToolbar = (props) => {
  const { setShowPage, recordId } = props
  const notify = useNotify()
  async function handleSave(values, redirect) {
    try {
      let body = fixValues(values)
      if (values.action === 'create') {
        await api.post(`/initial_evaluations`, body)
      } else {
        await api.put(`/initial_evaluations/${values.id}`, body)
      }
      setShowPage('athlete_questionnaires_list')
      notify('Avaliação inicial guardada com sucesso!', 'success')
    } catch (error) {
      notify(error.message, 'error')
    }
  }

  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton onSave={handleSave} />
      {recordId && (
        <DeleteButtonWithConfirm
          title={`Excluir Avaliação Inicial #${recordId}`}
          url={`initial_evaluations/${recordId}`}
          redirect={() => setShowPage('athlete_questionnaires_list')}
        />
      )}
    </Toolbar>
  )
}

function createStringText(data, column) {
  if (data[column] === 'Sim') {
    data[column] = `${data[column]}, ${data[`${column}_text`]}`
    delete data[`${column}_text`]
  }
}

function fixValues(values) {
  let fix_values = values
  createStringText(fix_values, 'intolerance')
  createStringText(fix_values, 'alergies')
  createStringText(fix_values, 'pain')
  createStringText(fix_values, 'medication')
  return fix_values
}
