import React from 'react'
import { merge } from 'lodash'
import { SimpleForm, SelectInput, required } from 'react-admin'
import { agreement_options } from '../../constants/questionnaires_responses'
import { vitalities_questions } from '../../constants/questionnaires_questions'

const DEFAULT_VALUES = ({
  type,
  action,
  athlete_id,
  season_id,
  filledwhen,
}) => {
  return {
    feel_alive: null,
    vigourous: null,
    energie: null,
    optimal: null,
    alert: null,
    strength: null,
    type: type,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
    filled_when: filledwhen,
  }
}

export default (props) => {
  const { setShowPage, questionnaire, data, toolbar } = props
  let initialValues = merge({}, DEFAULT_VALUES(questionnaire), data)

  return (
    <>
      <SimpleForm
        initialValues={initialValues}
        toolbar={toolbar}
        redirect={() => {
          setShowPage('athlete_questionnaires_list')
        }}>
        <SelectInput
          source="feel_alive"
          label={vitalities_questions.feel_alive}
          choices={agreement_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="vigourous"
          label={vitalities_questions.vigourous}
          choices={agreement_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="energie"
          label={vitalities_questions.energie}
          choices={agreement_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="optimal"
          label={vitalities_questions.optimal}
          choices={agreement_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="alert"
          label={vitalities_questions.alert}
          choices={agreement_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="strength"
          label={vitalities_questions.strength}
          choices={agreement_options}
          validate={[required()]}
          fullWidth
        />
      </SimpleForm>
    </>
  )
}
