import React from 'react'
import { merge } from 'lodash'
import { SimpleForm, SelectInput, required } from 'react-admin'
import { agreement_options } from '../../constants/questionnaires_responses'
import { sport_passions_questions } from '../../constants/questionnaires_questions'

const DEFAULT_VALUES = ({
  type,
  action,
  athlete_id,
  season_id,
  filledwhen,
}) => {
  return {
    experiences: null,
    apreciation: null,
    memorable: null,
    commitment: null,
    total_commitment: null,
    need: null,
    cant_imagine: null,
    dependency: null,
    type: type,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
    filled_when: filledwhen,
  }
}

export default (props) => {
  const { setShowPage, questionnaire, data, toolbar } = props
  let initialValues = merge({}, DEFAULT_VALUES(questionnaire), data)

  return (
    <>
      <SimpleForm
        initialValues={initialValues}
        toolbar={toolbar}
        redirect={() => {
          setShowPage('athlete_questionnaires_list')
        }}>
        <SelectInput
          source="experiences"
          label={sport_passions_questions.experiences}
          choices={agreement_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="apreciation"
          label={sport_passions_questions.apreciation}
          choices={agreement_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="memorable"
          label={sport_passions_questions.memorable}
          choices={agreement_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="commitment"
          label={sport_passions_questions.commitment}
          choices={agreement_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="total_commitment"
          label={sport_passions_questions.total_commitment}
          choices={agreement_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="need"
          label={sport_passions_questions.need}
          choices={agreement_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="cant_imagine"
          label={sport_passions_questions.cant_imagine}
          choices={agreement_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="dependency"
          label={sport_passions_questions.dependency}
          choices={agreement_options}
          validate={[required()]}
          fullWidth
        />
      </SimpleForm>
    </>
  )
}
