import React from 'react'
import { merge } from 'lodash'
import { SimpleForm, required } from 'react-admin'
import { TimeInput } from '../../components/datetime'
import { sleep_habits_questions } from '../../constants/questionnaires_questions'

const DEFAULT_VALUES = ({
  type,
  action,
  athlete_id,
  season_id,
  filledwhen,
}) => {
  return {
    wake_up: null,
    sleep_time: null,
    weekend: null,
    sleeping: null,
    type: type,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
    filled_when: filledwhen,
  }
}

function fixFormatDate(date) {
  const time_array = date.split(':')
  const today_date = new Date()
  return new Date(
    today_date.getFullYear(),
    today_date.getMonth(),
    today_date.getDay(),
    time_array[0],
    time_array[1],
    time_array[0],
    0,
  )
}

export default (props) => {
  const { setShowPage, questionnaire, data, toolbar } = props
  let fixData = Object.assign({}, data)
  if (Object.entries(data).length !== 0) {
    const inputs_array = ['wake_up', 'sleep_time', 'weekend', 'sleeping']

    inputs_array.forEach((element) => {
      fixData[element] = fixFormatDate(data[element])
    })
  }
  let initialValues = merge({}, DEFAULT_VALUES(questionnaire), data)

  return (
    <>
      <SimpleForm
        initialValues={initialValues}
        toolbar={toolbar}
        redirect={() => {
          setShowPage('athlete_questionnaires_list')
        }}>
        <TimeInput
          source="wake_up"
          label={sleep_habits_questions.wake_up}
          options={{
            autoOk: true,
            variant: 'inline',
          }}
          validate={[required()]}
          fullWidth
        />
        <TimeInput
          source="sleep_time"
          label={sleep_habits_questions.sleep_time}
          options={{
            autoOk: true,
            variant: 'inline',
          }}
          validate={[required()]}
          fullWidth
        />
        <TimeInput
          source="weekend"
          label={sleep_habits_questions.weekend}
          options={{
            autoOk: true,
            variant: 'inline',
          }}
          validate={[required()]}
          fullWidth
        />
        <TimeInput
          source="sleeping"
          label={sleep_habits_questions.sleeping}
          options={{
            autoOk: true,
            variant: 'inline',
          }}
          validate={[required()]}
          fullWidth
        />
      </SimpleForm>
    </>
  )
}
