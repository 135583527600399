import React from 'react'
import { merge } from 'lodash'
import { FormWithRedirect, SelectInput, required } from 'react-admin'
import { disagreement_options } from '../../constants/questionnaires_responses'
import { psych_need_questions } from '../../constants/questionnaires_questions'
import { Box } from '@material-ui/core'
import LongTextInput from '../../components/LongTextInput'

const DEFAULT_VALUES = ({
  type,
  action,
  athlete_id,
  season_id,
  filledwhen,
}) => {
  return {
    freedom: null,
    not_obliged: null,
    included: null,
    excluded: null,
    confidant: null,
    doubt: null,
    train_reflect: null,
    forced_train: null,
    club_connect: null,
    cold_person: null,
    capability: null,
    performance: null,
    pretended_exercises: null,
    pressed: null,
    club_closed: null,
    dont_like_me: null,
    objective: null,
    insecure: null,
    important_trains: null,
    burden: null,
    warmth: null,
    superficial: null,
    dificulty: null,
    failure: null,
    type: type,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
    filled_when: filledwhen,
  }
}

export default (props) => {
  const { setShowPage, questionnaire, data, toolbar } = props
  let initialValues = merge({}, DEFAULT_VALUES(questionnaire), data)

  return (
    <FormWithRedirect
      {...props}
      initialValues={initialValues}
      redirect={() => {
        setShowPage('athlete_questionnaires_list')
      }}
      render={(formProps) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={1} mr="1em">
                <FirstQuestionColumn />
              </Box>
              <Box flex={1} ml="1em">
                <SecondQuestionColumn />
              </Box>
            </Box>
          </Box>
          {React.cloneElement(toolbar, {
            props,
            handleSubmitWithRedirect: formProps.handleSubmitWithRedirect,
          })}
        </form>
      )}
    />
  )
}

const FirstQuestionColumn = (props) => {
  return (
    <>
      <SelectInput
        source="freedom"
        label={psych_need_questions.freedom}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="not_obliged"
        label={psych_need_questions.not_obliged}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="included"
        label={psych_need_questions.included}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="excluded"
        label={psych_need_questions.excluded}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="confidant"
        label={psych_need_questions.confidant}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="doubt"
        label={psych_need_questions.doubt}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="train_reflect"
        label={psych_need_questions.train_reflect}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="forced_train"
        label={psych_need_questions.forced_train}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <LongTextInput
        source="club_connect"
        input={<SelectInput />}
        label={psych_need_questions.club_connect}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <LongTextInput
        source="cold_person"
        input={<SelectInput />}
        label={psych_need_questions.cold_person}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="capability"
        label={psych_need_questions.capability}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="performance"
        label={psych_need_questions.performance}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
    </>
  )
}

const SecondQuestionColumn = (props) => {
  return (
    <>
      <SelectInput
        source="pretended_exercises"
        label={psych_need_questions.pretended_exercises}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="pressed"
        label={psych_need_questions.pressed}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="club_closed"
        label={psych_need_questions.club_closed}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <LongTextInput
        source="dont_like_me"
        input={<SelectInput />}
        label={psych_need_questions.dont_like_me}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="objective"
        label={psych_need_questions.objective}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="insecure"
        label={psych_need_questions.insecure}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="important_trains"
        label={psych_need_questions.important_trains}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="burden"
        label={psych_need_questions.burden}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <LongTextInput
        input={<SelectInput />}
        source="warmth"
        label={psych_need_questions.warmth}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="superficial"
        label={psych_need_questions.superficial}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="dificulty"
        label={psych_need_questions.dificulty}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="failure"
        label={psych_need_questions.failure}
        choices={disagreement_options}
        validate={[required()]}
        fullWidth
      />
    </>
  )
}
