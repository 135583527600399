import React, { useState } from 'react'
import TimelineIcon from '@material-ui/icons/Timeline'
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import CardHistoryPhysicalTest from './CardHistoryPhysicalTest'
import Button from '@material-ui/core/Button'

export default (props) => {
  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        color="primary"
        style={{ float: 'right' }}
        onClick={handleClickOpen}>
        <TimelineIcon />
      </Button>
      <SimpleDialog open={open} onClose={handleClose} {...props} />
    </>
  )
}

const SimpleDialog = (props) => {
  const { onClose, open, type, athlete_id } = props

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="md">
      <>
        <DialogTitle id="simple-dialog-title">Historial</DialogTitle>
        <DialogContent>
          <CardHistoryPhysicalTest type={type} athlete_id={athlete_id} />
        </DialogContent>
      </>
    </Dialog>
  )
}
