import React from 'react'
import {
  TextInput,
  SimpleForm,
  SelectInput,
  required,
  FormDataConsumer,
  NumberInput,
  Toolbar,
  SaveButton,
  DeleteButton,
} from 'react-admin'
import {
  injuries_list,
  existInjurieOptions,
  getOptionsByInjurieType,
  laterality_options,
} from '../../constants/injuries'
import { seasons_list, active_season } from '../../helpers/SeasonsHelper'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const CustomToolbar = (props) => {
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      <DeleteButton
        undoable={false}
        redirect={() => props.setShowPage('athlete_injuries_list')}
      />
    </Toolbar>
  )
}

export default (props) => {
  const { setShowPage, action } = props

  const handleRedirect = () => {
    setShowPage('athlete_injuries_list')
  }

  return (
    <SimpleForm
      toolbar={<CustomToolbar action={action} setShowPage={setShowPage} />}
      initialValues={props.record}
      {...props}
      redirect={handleRedirect}>
      {action !== 'edit' && (
        <SelectInput
          source="season_id"
          label="Época"
          choices={seasons_list()}
          defaultValue={active_season()}
          validate={required()}
        />
      )}
      <SelectInput
        source="type"
        label="Tipo de lesão"
        choices={injuries_list}
        validate={[required()]}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          existInjurieOptions(formData.type) && (
            <SelectInput
              source="location"
              label="Localização"
              choices={getOptionsByInjurieType(formData.type)}
              validate={[required()]}
              {...rest}
            />
          )
        }
      </FormDataConsumer>
      <SelectInput
        source="laterality"
        label="Lateralidade"
        choices={laterality_options}
      />
      <NumberInput source="number_injuries" label="Quantidade de lesões" />
      <TextInput source="surgery_type" label="Tipo de cirurgia" />
      <NumberInput source="surgery_number" label="Nº de cirurgias" />
      <TextInput multiline source="injury_description" label="Descrição" />
    </SimpleForm>
  )
}
