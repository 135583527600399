import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Create,
  TextInput,
  SimpleForm,
  Edit,
  EditButton,
  SelectInput,
  required,
  PasswordInput,
  FormDataConsumer,
  email,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import roles from '../../constants/roles'
import { team_formations } from '../../constants/teams'

const useEditStyles = makeStyles({
  root: { alignItems: 'flex-start' },
})

export const UserList = (props) => (
  <List {...props} bulkActionButtons={false} title="Lista de utilizadores">
    <Datagrid>
      <TextField source="id" label="Id" />
      <TextField source="name" label="Nome" />
      <TextField source="username" label="Username" />
      <TextField source="email" label="Email" />
      <DateField source="created_at" label="Criado em" />
      <EditButton label="Editar" />
    </Datagrid>
  </List>
)

export const UserCreate = (props) => {
  const classes = useEditStyles()

  return (
    <Create
      classes={classes}
      successMessage="Operação efectuada com sucesso"
      title="Criar novo utilizador"
      {...props}>
      <SimpleForm>
        <TextInput source="name" label="Nome" validate={[required()]} />
        <TextInput source="username" label="Username" validate={[required()]} />
        <TextInput
          source="email"
          label="Email"
          type="email"
          validate={[required(), email()]}
        />
        <SelectInput
          source="role"
          label="Papél"
          choices={roles}
          validate={[required()]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.role === 'treinador' && (
              <SelectInput
                label="Treinar o escalão"
                source="coach_team_formation"
                choices={team_formations}
                {...rest}
                validate={[required()]}
              />
            )
          }
        </FormDataConsumer>
        <PasswordInput
          source="password"
          label="Password"
          validate={[required(), ConfirmPasswordValidation]}
        />
        <PasswordInput
          source="password_confirm"
          label="Confirmar Password"
          validate={[required(), ConfirmPasswordValidation]}
        />
      </SimpleForm>
    </Create>
  )
}

const UserTitle = ({ record }) => {
  return <span>User {record ? `"${record.name}"` : ''}</span>
}

export const UserEdit = ({ permissions, ...props }) => (
  <Edit undoable={false} title={<UserTitle />} {...props}>
    <SimpleForm>
      <TextInput source="name" label="Nome" />
      <TextInput source="username" label="Username" />
      <TextInput
        source="email"
        type="email"
        label="Email"
        validate={[required(), email()]}
      />
      <SelectInput
        source="role"
        label="Papél"
        choices={roles}
        validate={[required()]}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.role === 'treinador' && (
            <SelectInput
              label="Treinar o escalão"
              source="coach_team_formation"
              choices={team_formations}
              {...rest}
            />
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
)

const ConfirmPasswordValidation = (value, allValues) => {
  if (allValues.password !== allValues.password_confirm) {
    return 'As passwords não são iguais!'
  }

  return false
}
