import React from 'react'
import { merge } from 'lodash'
import {
  SimpleForm,
  SelectInput,
  TextInput,
  NumberInput,
  required,
} from 'react-admin'
import { not_in_school_options } from '../../constants/questionnaires_responses'
import { socio_economic_data_questions } from '../../constants/questionnaires_questions'

const DEFAULT_VALUES = ({
  type,
  action,
  athlete_id,
  season_id,
  filledwhen,
}) => {
  return {
    male_job: null,
    female_job: null,
    male_degree: null,
    female_degree: null,
    cohab_number: null,
    type: type,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
    filled_when: filledwhen,
  }
}

export default (props) => {
  const { setShowPage, questionnaire, data, toolbar } = props
  let initialValues = merge({}, DEFAULT_VALUES(questionnaire), data)

  return (
    <>
      <SimpleForm
        initialValues={initialValues}
        toolbar={toolbar}
        redirect={() => {
          setShowPage('athlete_questionnaires_list')
        }}>
        <TextInput
          source="male_job"
          label={socio_economic_data_questions.male_job}
          validate={[required()]}
          fullWidth
        />
        <TextInput
          source="female_job"
          label={socio_economic_data_questions.female_job}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="male_degree"
          label={socio_economic_data_questions.male_degree}
          choices={not_in_school_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="female_degree"
          label={socio_economic_data_questions.female_degree}
          choices={not_in_school_options}
          validate={[required()]}
          fullWidth
        />
        <NumberInput
          source="cohab_number"
          label={socio_economic_data_questions.cohab_number}
          validate={[required()]}
          fullWidth
        />
      </SimpleForm>
    </>
  )
}
