import React, { useState } from 'react'
import { useSafeSetState } from 'ra-core'
import { useLogin, useNotify, Notification, useTranslate } from 'react-admin'
import TextField from '@material-ui/core/TextField'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: 400,
      margin: `${theme.spacing(0)} auto`,
    },
    loginBtn: {
      marginTop: theme.spacing(2),
      flexGrow: 1,
    },
    header: {
      textAlign: 'center',
      background: '#212121',
      color: '#fff',
    },
    card: {
      width: 'inherit',
      margin: 'auto',
      marginTop: theme.spacing(10),
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    containerLogo: {
      height: 100,
      width: 400,
      backgroundColor: 'white',
      textAlign: 'center',
    },
    logo: {
      height: 80,
      //width: 360,
      margin: 10,
    },
  }),
)

const Login = ({ setPage, page }) => {
  const [errors, setErrors] = useState({
    username: undefined,
    password: undefined,
  })
  const classes = useStyles()
  const translate = useTranslate()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const login = useLogin()
  const notify = useNotify()
  const [loading, setLoading] = useSafeSetState(false)
  const submit = (e) => {
    e.preventDefault()
    let resultVal = validate()
    if (!resultVal) {
      setLoading(true)
      login({ username, password })
        .then(() => {
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          notify('Username ou Password inválidas!', 'warning')
        })
    }
  }

  let validate = function () {
    let failVal = false
    if (!username) {
      failVal = true
      setErrors((prevState) => ({
        ...prevState,
        username: translate('ra.validation.required'),
      }))
    }
    if (!password) {
      failVal = true
      setErrors((prevState) => ({
        ...prevState,
        password: translate('ra.validation.required'),
      }))
    }
    return failVal
  }

  return (
    <div>
      <form
        onSubmit={submit}
        className={classes.container}
        noValidate
        autoComplete="off">
        <Card className={classes.card}>
          <CardMedia className={classes.containerLogo}>
            <img
              className={classes.logo}
              src={'../maritimo-logo.png'}
              alt="Maritimo Logo"
            />
          </CardMedia>

          <CardContent>
            <div>
              <TextField
                required
                fullWidth
                type="text"
                label="Utilizador"
                value={username}
                helperText={errors.username}
                error={errors.username}
                onChange={(e) => {
                  setUsername(e.target.value)
                  setErrors((prevState) => ({
                    ...prevState,
                    username: undefined,
                  }))
                }}
              />
              <TextField
                required
                fullWidth
                type="password"
                label="Password"
                value={password}
                helperText={errors.password}
                error={errors.password}
                onChange={(e) => {
                  setPassword(e.target.value)
                  setErrors((prevState) => ({
                    ...prevState,
                    password: undefined,
                  }))
                }}
              />
            </div>
          </CardContent>
          <CardActions>
            <div style={{ width: '100%' }}>
              <Box display="block">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={loading}
                  className={classes.loginBtn}>
                  {loading && (
                    <CircularProgress
                      className={classes.icon}
                      size={18}
                      thickness={2}
                    />
                  )}
                  {translate('ra.auth.sign_in')}
                </Button>
              </Box>
            </div>
          </CardActions>
        </Card>
      </form>
      <Notification />
    </div>
  )
}

export default Login
