import React from 'react'
import { merge } from 'lodash'
import { FormWithRedirect, NumberInput, required } from 'react-admin'
import { Box } from '@material-ui/core'

const DEFAULT_VALUES = ({
  type,
  action,
  athlete_id,
  season_id,
  filledwhen,
}) => {
  return {
    left: null,
    right: null,
    both: null,
    type: type,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
    filled_when: filledwhen,
  }
}

export default (props) => {
  const { setShowPage, physicalTest, data, toolbar } = props
  let initialValues = merge({}, DEFAULT_VALUES(physicalTest), data)

  return (
    <FormWithRedirect
      {...props}
      initialValues={initialValues}
      redirect={() => {
        setShowPage('athlete_questionnaires_list')
      }}
      render={(formProps) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={1} mr="1em">
                <FirstColumn />
              </Box>
              <Box flex={1} ml="1em">
                <SecondColumn />
              </Box>
              <Box flex={1} ml="1em">
                <ThirdColumn />
              </Box>
            </Box>
          </Box>
          {React.cloneElement(toolbar, {
            props,
            handleSubmitWithRedirect: formProps.handleSubmitWithRedirect,
          })}
        </form>
      )}
    />
  )
}

const FirstColumn = (props) => {
  return (
    <>
      <NumberInput
        source="left"
        label="Valor do lado esquerdo (em cm)"
        validate={[required()]}
        fullWidth
      />
    </>
  )
}

const SecondColumn = (props) => {
  return (
    <>
      <NumberInput
        source="right"
        label="Valor do lado direito (em cm)"
        validate={[required()]}
        fullWidth
      />
    </>
  )
}

const ThirdColumn = (props) => {
  return (
    <>
      <NumberInput
        source="both"
        label="Valor bilateral (em cm)"
        validate={[required()]}
        fullWidth
      />
    </>
  )
}
