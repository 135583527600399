import React from 'react'
import { merge } from 'lodash'
import { SimpleForm, required, NumberInput } from 'react-admin'

const DEFAULT_VALUES = ({
  type,
  action,
  athlete_id,
  season_id,
  filledwhen,
}) => {
  return {
    plank_sides_esq: null,
    plank_sides_dir: null,
    type: type,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
    filled_when: filledwhen,
  }
}

export default (props) => {
  const { setShowPage, physicalTest, data, toolbar } = props
  let initialValues = merge({}, DEFAULT_VALUES(physicalTest), data)

  return (
    <>
      <SimpleForm
        initialValues={initialValues}
        toolbar={toolbar}
        redirect={() => {
          setShowPage('athlete_physical_tests_list')
        }}>
        <NumberInput
          source="plank_sides_esq"
          label="Insira o valor dp exercício da prancha esquerda"
          validate={[required()]}
          fullWidth
        />
        <NumberInput
          source="plank_sides_dir"
          label="Insira o valor dp exercício da prancha direita"
          validate={[required()]}
          fullWidth
        />
      </SimpleForm>
    </>
  )
}
