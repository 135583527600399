import React from 'react'
import { Show, TabbedShowLayout, Tab } from 'react-admin'
import AthleteRecordSummary from '../../components/athlete-record-tabs/AthleteRecordSummary'
import AthleteRecordPhysicalTests from '../../components/athlete-record-tabs/AthleteRecordPhysicalTests'

const AthleteTitle = ({ record }) => {
  return (
    <span>
      Historial - {record ? `"${record.name} : ${record.season_id}"` : ''}
    </span>
  )
}

export default function AthleteShow(props) {
  return (
    <Show {...props} title={<AthleteTitle />}>
      <TabbedShowLayout>
        <Tab label="Sumário">
          <AthleteRecordSummary {...props} />
        </Tab>
        <Tab label="Aptidão Física">
          <AthleteRecordPhysicalTests {...props} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
