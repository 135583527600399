import simpleRestProvider from 'ra-data-simple-rest'
import { fetchUtils } from 'react-admin'
import ApiUrl from './api-url.js'

const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const token = localStorage.getItem('token')
  options.headers.set('Authorization', `Bearer ${token}`)

  return fetchUtils.fetchJson(url, options).catch((err) => {
    const { error, message } = err.body

    const res = { status: err.status, message: `api.errors.${error}` }

    if (message) {
      res.message = message
    }

    throw res
  })
}
let dataProvider = simpleRestProvider(ApiUrl, httpClient)

const myDataProvider = {
  ...dataProvider,
  getOne: (resource, params) => dataProvider.getOne(resource, params),
  getList: async (resource, params) => dataProvider.getList(resource, params),
  update: async (resource, params) => dataProvider.update(resource, params),
  getMany: async (resource, params) => dataProvider.getMany(resource, params),
}

export default myDataProvider
export { httpClient }