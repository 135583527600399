import React, { useState } from 'react'
import {
  useQuery,
  Datagrid,
  TextField,
  Pagination,
  ListContextProvider,
} from 'react-admin'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'
import keyBy from 'lodash/keyBy'
import LinearProgress from '@material-ui/core/LinearProgress'

export default function AthleteInjuriesList(props) {
  const { setShowPage } = props
  const athlete_records_ids = props.record.athlete_records_ids

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        size="small"
        endIcon={<AddBoxOutlinedIcon />}
        onClick={() => setShowPage('athlete_injuries_create')}>
        Adicionar nova lesão
      </Button>
      <Grid container justify="center">
        {athlete_records_ids.length !== 0 ? (
          <DatagridAthleteInjuries
            {...props}
            athlete_records_ids={athlete_records_ids}
          />
        ) : (
          'Nenhum registo encontrado!'
        )}
      </Grid>
    </>
  )
}

const DatagridAthleteInjuries = (props) => {
  const { athlete_records_ids } = props
  const [page, setPage] = useState(1)
  const perPage = 10
  const { data, total, loading, error } = useQuery({
    type: 'getList',
    resource: 'injuries',
    payload: {
      pagination: { page, perPage },
      sort: { field: 'id', order: 'DESC' },
      filter: {
        athlete_record_id: athlete_records_ids,
      },
    },
  })

  if (loading) {
    return <LinearProgress />
  }

  if (error) {
    return <p>ERROR: {error}</p>
  }

  return (
    <ListContextProvider
      value={{
        data: keyBy(data, 'id'),
        ids: data.map(({ id }) => id),
        total,
        page,
        perPage,
        setPage,
        currentSort: { field: 'id', order: 'DESC' },
        basePath: '/injuries',
        resource: 'injuries',
        selectedIds: [],
      }}>
      <Datagrid>
        <TextField source="id" label="Id" sortable={false} />
        <TextField source="type" label="Tipo de lesão" sortable={false} />
        <TextField source="location" label="Zona" sortable={false} />
        <TextField source="laterality" label="Lateralidade" sortable={false} />
        <TextField
          source="surgery_type"
          label="Tipo de Cirurgia"
          sortable={false}
        />
        <EditInjurieButton {...props} sortable={false} />
      </Datagrid>
      <Pagination rowsPerPageOptions={[]} />
    </ListContextProvider>
  )
}

const EditInjurieButton = (props) => {
  const { setShowPage, SetEditRecord, record } = props
  return (
    record && (
      <Button
        color="primary"
        size="small"
        endIcon={<AddBoxOutlinedIcon />}
        onClick={() => {
          SetEditRecord(record)
          setShowPage('athlete_injuries_edit')
        }}>
        Editar
      </Button>
    )
  )
}
