import React, { useState } from 'react'
import { CreateView, SimpleForm, SelectInput } from 'react-admin'
import { Typography, Grid, Box } from '@material-ui/core'
import CardHistoryPhysicalTest from '../CardHistoryPhysicalTest'
import { physical_tests_list } from '../../constants/physical_tests_list'

export default function AthleteRecordPhysicalTests({ record }) {
  const [input, setInput] = useState({ type: 'bars' })
  return (
    <Box display={{ md: 'block', lg: 'flex' }}>
      <Box flex={2} mr={{ md: 0, lg: '1em' }}>
        <Typography variant="h6" gutterBottom>
          Histórico dos testes físicos
        </Typography>
        <Grid container spacing={4}>
          <Grid item lg={3} sm={12} xl={2} xs={12}>
            <InputsForm input={input} setInput={setInput} />
          </Grid>
          <Grid item lg={9} sm={12} xl={10} xs={12}>
            <CardHistoryPhysicalTest
              type={input.type}
              athlete_id={record.athlete_id}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

const InputsForm = ({ input, setInput }) => {
  const { type } = input
  return (
    <CreateView title=" ">
      <SimpleForm toolbar={null} initialValues={input}>
        <SelectInput
          source="physical_test"
          label="Testes Físicos"
          choices={physical_tests_list}
          defaultValue={type}
          onChange={(event) => {
            setInput({ type: event.target.value })
          }}
        />
      </SimpleForm>
    </CreateView>
  )
}
