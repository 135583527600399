import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axiosConfig from '../data/axios-config'
import { LinearProgress } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  image: {
    height: '100%',
    width: '100%',
  },
})

const AthletePhotoField = ({ record = {} }) => {
  const [photoURL, setPhotoURL] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    ;(async () => {
      if (!record.has_photo) {
        return
      }

      setIsLoading(true)
      axiosConfig({
        url: `athletes/${record.id}/photo`,
        method: 'GET',
        responseType: 'blob',
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          setPhotoURL(url)
        })
        .finally(() => {
          setIsLoading(false)
        })
    })()
  }, [record.id, record.has_photo])

  if (isLoading) {
    return <LinearProgress />
  }

  return photoURL ? (
    <img src={photoURL} alt="" className={classes.image} />
  ) : (
    <img src="athlete_default_image.jpg" alt="" className={classes.image} />
  )
}

AthletePhotoField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
}

export default AthletePhotoField
