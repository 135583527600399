import React from 'react'
import { Box, Typography } from '@material-ui/core'
import TeamPhysicalAverageGraph from '../TeamPhysicalAverageGraph'

export default function SeasonPhysicalTests({ record }) {
  const requestUrl = (input) => {
    const { team_formation, filledwhen, physical_test } = input
    return `graphs/physical_tests/average/season/${record.id}/${team_formation}/${filledwhen}/${physical_test}`
  }

  return (
    <Box display={{ md: 'block', lg: 'flex' }}>
      <Box flex={2} mr={{ md: 0, lg: '1em' }}>
        <Typography variant="h6" gutterBottom>
          Média da aptidão física por escalão
        </Typography>
        <TeamPhysicalAverageGraph
          firstTeamFormation="Sub19"
          urlCallback={requestUrl}
        />
      </Box>
    </Box>
  )
}
