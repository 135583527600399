import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useCheckAuth } from 'ra-core'
import { Notification } from 'react-admin'
import { ThemeProvider } from '@material-ui/styles'
import Login from './login'

const styles = {
  display: 'flex',
  background: 'url(/background.png)',
  minHeight: '100vh',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundSize: '100% 100%',
  justifyContent: 'flex-start',
  backgroundRepeat: 'no-repeat',
}

const Home = ({ theme }) => {
  const [page, setPage] = useState('login')
  const checkAuth = useCheckAuth()
  const history = useHistory()
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        history.push('/')
      })
      .catch(() => {
        // not authenticated, stay on the login page
      })
  }, [checkAuth, history])

  return (
    <div style={styles}>
      <ThemeProvider theme={theme}>
        {page === 'login' && (
          <Login setPage={setPage} page={page} />
        )}

        <Notification />
      </ThemeProvider>
    </div>
  )
}

export default Home
