import React from 'react'
import BarForm from '../resources/physical_tests/BarForm'
import BodyComposition from '../resources/physical_tests/BodyComposition'
import DinamometryForm from '../resources/physical_tests/DinamometryForm'
import PlankSideForm from '../resources/physical_tests/PlankSideForm'
import PlankFrontalForm from '../resources/physical_tests/PlankFrontalForm'
import AdioposityForm from '../resources/physical_tests/AdioposityForm'
import SitUpForm from '../resources/physical_tests/SitUpForm'
import PushUpForm from '../resources/physical_tests/PushUpForm'
import SprintForm from '../resources/physical_tests/SprintForm'
import TTestForm from '../resources/physical_tests/TTestForm'
import YYIR1Form from '../resources/physical_tests/YYIR1Form'
import FlamingoForm from '../resources/physical_tests/FlamingoForm'
import ShoulderForm from '../resources/physical_tests/ShoulderForm'
import SitReachForm from '../resources/physical_tests/SitReachForm'
import SquatJump from '../resources/physical_tests/SquatJump'
import CounterMovementJumpForm from '../resources/physical_tests/CounterMovementJumpForm'

export const physical_tests_list = [
  { id: 'bars', name: 'Suspensão na barra', form: <BarForm /> },
  {
    id: 'body_compositions',
    name: 'Antropometria',
    form: <BodyComposition />,
  },
  {
    id: 'dinamometries',
    name: 'Dinamometria Manual',
    form: <DinamometryForm />,
  },
  { id: 'plank_sides', name: ' Prancha Lateral', form: <PlankSideForm /> },
  { id: 'plank_frontals', name: 'Prancha Frontal', form: <PlankFrontalForm /> },
  {
    id: 'adioposities',
    name: 'Composição Corporal',
    form: <AdioposityForm />,
  },
  { id: 'sit_ups', name: 'Abdominais', form: <SitUpForm /> },
  { id: 'push_ups', name: 'Flexões de Braços', form: <PushUpForm /> },
  { id: 'sprints', name: 'Sprint Linear', form: <SprintForm /> },
  { id: 't_tests', name: 'T-Testes', form: <TTestForm /> },
  {
    id: 'yyir1s',
    name: 'Yo-Yo Interment Recovery Test(YYIR1)',
    form: <YYIR1Form />,
  },
  { id: 'sjs', name: 'Squat Jump (SJ)', form: <SquatJump /> },
  {
    id: 'cmjs',
    name: 'CounterMovement Jump (CMJ)',
    form: <CounterMovementJumpForm />,
  },
  { id: 'flamingos', name: 'Equilíbrio Flamingo', form: <FlamingoForm /> },
  { id: 'shoulders', name: 'Flexibilidade de Ombros', form: <ShoulderForm /> },
  { id: 'sit_reachs', name: 'Senta e Alcança', form: <SitReachForm /> },
]

export const filled_when = [
  { id: 'inicio', name: 'Inicio' },
  { id: 'meio', name: 'Meio' },
  { id: 'final', name: 'Final' },
]

export const macro_categories = [
  {
    id: 'Medidas Antropometricas',
    tests: ['body_compositions', 'adioposities'],
  },
  {
    id: 'Resistência',
    tests: ['yyir1s'],
  },
  {
    id: 'Força',
    tests: [
      'bars',
      'push_ups',
      'sit_ups',
      'plank_frontals',
      'plank_sides',
      'dinamometries',
      'cmjs',
      'sjs',
    ],
  },
  {
    id: 'Velocidade',
    tests: ['sprints', 't_tests'],
  },
  {
    id: 'Flexibilidade',
    tests: ['sit_reachs', 'shoulders'],
  },
  {
    id: 'Equilíbrio',
    tests: ['flamingos'],
  },
]
