import React, { useEffect } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Create,
  TextInput,
  SimpleForm,
  Edit,
  EditButton,
  useRedirect,
  useGetPermissions,
  required,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import SeasonsList from '../../data/seasons-storage'
import { active_season } from '../../helpers/SeasonsHelper'
import { DateInput } from '../../components/datetime'

const useEditStyles = makeStyles({
  root: { alignItems: 'flex-start' },
})

export const SeasonList = (props) => {
  const redirect = useRedirect()
  const getPermissions = useGetPermissions()

  useEffect(() => {
    getPermissions().then((permissions) => {
      if (permissions === 'treinador') {
        redirect(`/seasons/${active_season()}/show`)
      }
    })
  }, [getPermissions, redirect])

  return (
    <List {...props} bulkActionButtons={false} title="Lista de épocas">
      <Datagrid rowClick="show">
        <TextField source="id" label="Id" />
        <TextField source="initial_date" label="Data de ínicio" />
        <TextField source="end_date" label="Data do final" />
        <DateField source="created_at" label="Criado em" />
        <EditButton label="Editar" />
      </Datagrid>
    </List>
  )
}

export const SeasonCreate = (props) => {
  const classes = useEditStyles()

  return (
    <Create classes={classes} title="Criar novo época" {...props}>
      <SeasonForm />
    </Create>
  )
}

export const SeasonEdit = (props) => {
  const classes = useEditStyles()

  return (
    <Edit classes={classes} title="Editar a época" undoable={false} {...props}>
      <SeasonForm />
    </Edit>
  )
}

const datesValidation = (value, allValues) => {
  allValues.id = createIdString(allValues.initial_date, allValues.end_date)

  const initial_year = new Date(allValues.initial_date).getFullYear()
  const end_year = new Date(allValues.end_date).getFullYear()

  if (initial_year > end_year) {
    return ['A data do ínicio tem que ser maior do que a data final!']
  }

  if (end_year - initial_year > 1) {
    return ['A data do final tem de ser do seguinta ao ano da data inicial!']
  }

  return false
}

const redirectOnSuccess = () => {
  SeasonsList.store()
  return 'show'
}

const SeasonForm = (props) => {
  return (
    <SimpleForm {...props} redirect={redirectOnSuccess()}>
      <TextInput
        source="id"
        label="ID"
        disabled
        validate={[required('Obrigatório')]}
      />
      <DateInput
        source="initial_date"
        label="Data de ínicio"
        options={{
          autoOk: true,
          variant: 'inline',
          format: 'dd/MM/yyyy',
        }}
        formatDate={(date) => new Date(date).toISOString().slice(0, 10)}
        validate={[required('Obrigatório'), datesValidation]}
      />
      <DateInput
        source="end_date"
        label="Data do final"
        options={{
          autoOk: true,
          variant: 'inline',
          format: 'dd/MM/yyyy',
        }}
        formatDate={(date) => new Date(date).toISOString().slice(0, 10)}
        validate={[required('Obrigatório'), datesValidation]}
      />
    </SimpleForm>
  )
}

function createIdString(initial_date, end_date) {
  const initial_year = new Date(initial_date).getFullYear() || ''
  const end_year = new Date(end_date).getFullYear() || ''

  return `${initial_year}-${end_year}`
}
