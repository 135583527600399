import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  Filter,
  /* SearchInput, */
  SelectInput,
  Edit,
  usePermissions,
  LinearProgress,
} from 'react-admin'
import { team_formations } from '../../constants/teams'
import { seasons_list, active_season } from '../../helpers/SeasonsHelper'
import AthleteRecordForm from './AthleteRecordForm'
import { makeStyles } from '@material-ui/core'
import {
  getTeamFormationPermissionArray,
  getSeasonListPermissionArray,
} from '../../helpers/FilterPermissions'
import { useCurrentUser } from '../../hooks/useCurrentUser'

const useEditStyles = makeStyles({
  root: { alignItems: 'flex-start' },
})

const AthleteFilter = (props) => (
  <Filter {...props}>
    {/* <SearchInput source="name" placeholder="Nome do Jogador" alwaysOn /> */}
    <SelectInput
      source="season_id"
      label="Época"
      choices={
        props.permission === 'treinador'
          ? getSeasonListPermissionArray()
          : seasons_list()
      }
      defaultValue={active_season()}
      allowEmpty={false}
      translateChoice={false}
    />
    <SelectInput
      source="team_formation"
      label="Escalões"
      choices={
        props.permission === 'treinador'
          ? getTeamFormationPermissionArray(props.coach_team_formation)
          : team_formations
      }
      allowEmpty={false}
      translateChoice={false}
    />
  </Filter>
)

export const AthleteRecordList = (props) => {
  const { permissions } = usePermissions()
  const user = useCurrentUser()

  if (!user) {
    return <LinearProgress />
  }

  if (permissions === 'treinador' && user && !user.coach_team_formation) {
    return 'Não existe ainda um escalão associado ao treinador! Peça a um Admin para alterar as suas definições'
  }

  return (
    <List
      {...props}
      filters={
        <AthleteFilter
          permission={permissions}
          coach_team_formation={user.coach_team_formation}
        />
      }
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filterDefaultValues={
        permissions === 'treinador'
          ? {
              season_id: active_season(),
              team_formation: user.coach_team_formation,
            }
          : null
      }
      title="Lista de historiais">
      <Datagrid rowClick="show">
        <TextField source="id" label="Id" />
        <TextField source="name" label="Nome do Jogador" />
        <TextField source="season_id" label="Época" />
        <TextField source="team_formation" label="Escalão" />
        <DateField source="created_at" label="Criado em" />
        <EditButton label="Editar" />
      </Datagrid>
    </List>
  )
}

export const AthleteRecordEdit = (props) => {
  const classes = useEditStyles()
  return (
    <Edit
      classes={classes}
      successMessage="Operação efectuada com sucesso"
      title="Editar historial"
      label="Editar"
      undoable={false}
      {...props}>
      <AthleteRecordForm />
    </Edit>
  )
}
