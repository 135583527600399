export const minimunValue = (values) => {
  if (values.length === 0) {
    return ''
  }

  return parseFloat(Math.min.apply(null, values).toFixed(2))
}

export const maximunValue = (values) => {
  if (values.length === 0) {
    return ''
  }

  return parseFloat(Math.max.apply(null, values).toFixed(2))
}

export const averageValue = (values) => {
  if (values.length === 0) {
    return ''
  }

  const values_float_array = fixArray(values)

  if (values.length === 1) {
    return values_float_array[0]
  }

  return parseFloat(
    (
      values_float_array.reduce((prev, curr) => prev + curr) /
      values_float_array.length
    ).toFixed(2),
  )
}

export const standardDeviationValue = (values) => {
  if (values.length === 0 || values.length === 1) {
    return ''
  }

  const values_float_array = fixArray(values)
  const avg_square_diff =
    values_float_array.reduce(function (sq, n) {
      return sq + Math.pow(n - averageValue(values_float_array), 2)
    }, 0) /
    (values_float_array.length - 1)

  return avg_square_diff === 0
    ? 0
    : parseFloat(Math.sqrt(avg_square_diff).toFixed(3))
}

export const coefficientVariation = (values) => {
  if (values.length === 0 || values.length === 1) {
    return ''
  }

  const mean = averageValue(values)
  const standard_deviation = standardDeviationValue(values)

  const coefficient_variation = (standard_deviation / mean) * 100

  return parseFloat(coefficient_variation.toFixed(2))
}

const fixArray = (array) => {
  return array.map((element) => parseFloat(element))
}

export const VO2MaxValue = (distance) => {
  const vo2max = distance * 0.0084 + 36.4
  return parseFloat(vo2max.toFixed(3))
}
