import React, { useState, useEffect } from 'react'
import api from '../data/axios-config'
import { useNotify } from 'react-admin'
import { LinearProgress } from 'react-admin'
import { history_physical_tests_graphs } from '../constants/graphs'
import TabComponent from './TabComponent'

export default (props) => {
  const { type, athlete_id } = props
  const notify = useNotify()
  const [data, setData] = useState()
  const [loading, setLoading] = useState()
  const physical_test_graph = history_physical_tests_graphs.find(
    (x) => x.id === type,
  )

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const result = await api.get(
          `graphs/physical_tests/athlete_history/${athlete_id}/${type}`,
        )
        setData(result.data)
      } catch (err) {
        notify(err.message, 'error')
      } finally {
        setLoading(false)
      }
    })()
  }, [notify, athlete_id, type])

  if (loading) {
    return <LinearProgress />
  }

  if (!physical_test_graph) {
    return 'Ainda não existe nenhuma configuração para o teste físico!'
  }

  if (!data) {
    return 'Sem dados!'
  }

  return <GraphContent data={data} config={physical_test_graph} />
}

const GraphContent = (props) => {
  const { config } = props
  if (!config || !config.graph) {
    return 'Ainda não existe um gráfico para este teste físico!'
  }

  return config.multiple ? (
    <MultipleGraphs {...props} />
  ) : (
    <SingleGraph {...props} />
  )
}

const MultipleGraphs = ({ data, config }) => {
  const { settings } = config
  let graphs = settings.keys.map((key) => {
    if (!key) {
      return `Y para chave: ${key}, não existe na configuração do gráfico!`
    }

    const { tabName, name, x, y, axisLeftLegend } = key
    const single_graph_settings = {
      graph: config.graph,
      settings: {
        keys: {
          x: x,
          y: y,
        },
        axisBottomLegend: settings.axisBottomLegend,
        axisLeftLegend: axisLeftLegend,
      },
      name: name,
      format: config.singleFormat,
    }

    return (
      <SingleGraph
        data={data}
        config={single_graph_settings}
        tabLabel={tabName}
        key={`single-graph-${tabName}`}
      />
    )
  })

  return <TabComponent>{graphs}</TabComponent>
}

const SingleGraph = ({ data, config }) => {
  const format = config.format
  const handleData = () => {
    if (format) {
      return format(data, config.settings.keys, config.name)
    }
    return data
  }

  return React.cloneElement(config.graph, {
    data: handleData(),
    settings: config.settings,
  })
}
