import React from 'react'
import { merge } from 'lodash'
import { FormWithRedirect, SelectInput, required } from 'react-admin'
import {
  life_style_drop_choices_options,
  alcoohol_options,
  cafeine_options,
  cigarrete_options,
  weight_options,
  time_options,
} from '../../constants/questionnaires_responses'
import { life_styles_questions } from '../../constants/questionnaires_questions'
import { Box } from '@material-ui/core'
import LongTextInput from '../../components/LongTextInput'

const DEFAULT_VALUES = ({
  type,
  action,
  athlete_id,
  season_id,
  filledwhen,
}) => {
  return {
    talk: null,
    afection: null,
    emotional: null,
    be_active: null,
    exercise: null,
    relaxe: null,
    balance_food: null,
    sugar: null,
    overweight: null,
    smoke: null,
    drugs: null,
    medication: null,
    cafeine: null,
    alcohol: null,
    over_four: null,
    drink_drive: null,
    sleep: null,
    stress: null,
    enjoyng: null,
    speed: null,
    bored: null,
    anger: null,
    optimist: null,
    ansious: null,
    depressed: null,
    work: null,
    relation: null,
    lifestyle: null,
    type: type,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
    filled_when: filledwhen,
  }
}

export default (props) => {
  const { setShowPage, questionnaire, data, toolbar } = props
  let initialValues = merge({}, DEFAULT_VALUES(questionnaire), data)

  return (
    <FormWithRedirect
      {...props}
      initialValues={initialValues}
      redirect={() => {
        setShowPage('athlete_questionnaires_list')
      }}
      render={(formProps) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={1} mr="1em">
                <FirstQuestionColumn />
              </Box>
              <Box flex={1} ml="1em">
                <SecondQuestionColumn />
              </Box>
            </Box>
          </Box>
          {React.cloneElement(toolbar, {
            props,
            handleSubmitWithRedirect: formProps.handleSubmitWithRedirect,
          })}
        </form>
      )}
    />
  )
}

const FirstQuestionColumn = (props) => {
  return (
    <>
      <LongTextInput
        source="talk"
        input={<SelectInput />}
        label={life_styles_questions.talk}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="afection"
        label={life_styles_questions.afection}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="emotional"
        label={life_styles_questions.emotional}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <LongTextInput
        input={<SelectInput />}
        source="be_active"
        label={life_styles_questions.be_active}
        choices={time_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="exercise"
        label={life_styles_questions.exercise}
        choices={time_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="relaxe"
        label={life_styles_questions.relaxe}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <LongTextInput
        source="balance_food"
        input={<SelectInput />}
        label={life_styles_questions.balance_food}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <LongTextInput
        source="sugar"
        input={<SelectInput />}
        label={life_styles_questions.sugar}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="overweight"
        label={life_styles_questions.overweight}
        choices={weight_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="smoke"
        label={life_styles_questions.smoke}
        choices={cigarrete_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="drugs"
        label={life_styles_questions.drugs}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <LongTextInput
        source="medication"
        input={<SelectInput />}
        label={life_styles_questions.medication}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="cafeine"
        label={life_styles_questions.cafeine}
        choices={cafeine_options}
        validate={[required()]}
        fullWidth
      />
      <LongTextInput
        source="alcohol"
        input={<SelectInput />}
        label={life_styles_questions.alcohol}
        choices={alcoohol_options}
        validate={[required()]}
        fullWidth
      />
    </>
  )
}

const SecondQuestionColumn = (props) => {
  return (
    <>
      <SelectInput
        source="over_four"
        label={life_styles_questions.over_four}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="drink_drive"
        label={life_styles_questions.drink_drive}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="sleep"
        label={life_styles_questions.sleep}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="stress"
        label={life_styles_questions.stress}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="enjoyng"
        label={life_styles_questions.enjoyng}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="speed"
        label={life_styles_questions.speed}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="bored"
        label={life_styles_questions.bored}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="anger"
        label={life_styles_questions.anger}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="optimist"
        label={life_styles_questions.optimist}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="ansious"
        label={life_styles_questions.ansious}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="depressed"
        label={life_styles_questions.depressed}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="work"
        label={life_styles_questions.work}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="relation"
        label={life_styles_questions.relation}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="lifestyle"
        label={life_styles_questions.lifestyle}
        choices={life_style_drop_choices_options}
        validate={[required()]}
        fullWidth
      />
    </>
  )
}
