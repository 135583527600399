import React from 'react'
import { usePermissions, LinearProgress } from 'react-admin'
import AdminDashboard from './dashboard/AdminDashboard'
import CoachDashboard from './dashboard/CoachDashboard'

const Dashboard = (props) => {
  const { permissions } = usePermissions()
  const dashboard_list = [
    { permission: 'admin', component: <AdminDashboard /> },
    { permission: 'treinador', component: <CoachDashboard /> },
  ]

  if (!permissions) {
    return <LinearProgress />
  }

  const displayDashboard = dashboard_list.find((list) => {
    return list.permission === permissions
  })

  return displayDashboard
    ? React.cloneElement(displayDashboard.component, [props])
    : `Não existe um dashboard para esse tipo de papel: ${permissions}`
}

export default Dashboard
