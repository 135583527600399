import React from 'react'
import {
  Create,
  Edit,
  FormWithRedirect,
  SaveButton,
  DeleteButton,
  SelectInput,
  required,
  SelectArrayInput,
  NumberInput,
} from 'react-admin'
import BackButton from '../../BackButton'
import { makeStyles } from '@material-ui/core/styles'
import { team_formations, teams } from '../../../constants/teams'
import { field_positions } from '../../../constants/questionnaires_responses'
import { current_school_degree, not_in_school } from '../../../constants/school'
import { Typography, Box, Toolbar } from '@material-ui/core'
import { seasons_list } from '../../../helpers/SeasonsHelper'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const defaultProps = {
  resource: 'athlete_records',
  basePath: '/athlete_records',
  hasShow: false,
  hasList: false,
}

const default_values = (athlete_id) => ({
  athlete_id: athlete_id,
  season_id: null,
  weight: null,
  height: null,
  team_formation: null,
  team: null,
  current_school_degree: null,
  not_in_school: null,
  field_positions: null,
})

export const AthleteRecordCreate = (props) => {
  const { setShowPage } = props
  props = { ...props, ...defaultProps }

  return (
    <>
      <BackButton
        onBack={() => {
          setShowPage('athlete_records_list')
        }}
      />
      <Create
        {...props}
        title=" - Adicionar novo historial"
        record={default_values(props.record.id)}>
        <AthleteRecordForm setShowPage={setShowPage} />
      </Create>
    </>
  )
}

export const AthleteRecordEdit = (props) => {
  const { setShowPage, editRecord } = props
  props = { ...props, ...defaultProps, id: editRecord.id }

  return (
    <>
      <BackButton
        onBack={() => {
          setShowPage('athlete_records_list')
        }}
      />
      <Edit {...props} undoable={false}>
        <AthleteRecordForm setShowPage={setShowPage} action="edit" />
      </Edit>
    </>
  )
}

const CustomToolbar = (props) => {
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
    </Toolbar>
  )
}

const AthleteRecordForm = (props) => {
  const { setShowPage, action, ...rest } = props

  const handleRedirect = () => {
    setShowPage('athlete_records_list')
  }

  return (
    <FormWithRedirect
      toolbar={<CustomToolbar action={action} setShowPage={setShowPage} />}
      initialValues={props.record}
      {...rest}
      render={(formProps) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={2} mr="1em">
                <AthleteRecordBasicData {...rest} />

                <Box mt="1em" />
              </Box>

              <Box flex={1} ml="1em">
                <AthleteRecordOtherData {...rest} />
              </Box>
            </Box>
          </Box>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              <SaveButton
                {...rest}
                redirect={handleRedirect}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
              <DeleteButton record={formProps.record} />
            </Box>
          </Toolbar>
        </form>
      )}
    />
  )
}

const AthleteRecordBasicData = (props) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Historial do Jogador
      </Typography>

      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <SelectInput
            source="season_id"
            label="Época"
            choices={seasons_list()}
            validate={required()}
            fullWidth
          />
        </Box>

        <Box flex={1} ml="0.5em">
          <SelectInput
            source="team_formation"
            label="Escalão"
            choices={team_formations}
            validate={required()}
            fullWidth
          />
        </Box>

        <Box flex={1} ml="0.5em">
          <SelectInput
            source="team"
            label="Equipa"
            allowEmpty
            emptyValue={null}
            choices={teams}
            fullWidth
          />
        </Box>
      </Box>

      <SelectArrayInput
        source="field_positions"
        label="Posições em campo"
        choices={field_positions}
        fullWidth
      />
    </>
  )
}

const AthleteRecordOtherData = (props) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Outras informações
      </Typography>

      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <NumberInput source="height" label="Altura (cm)" fullWidth />
        </Box>

        <Box flex={1} ml="0.5em">
          <NumberInput source="weight" label="Peso (kg)" fullWidth />
        </Box>
      </Box>

      <SelectInput
        source="current_school_degree"
        label="Grau de escolaridade atual"
        choices={current_school_degree}
        fullWidth
      />

      <SelectInput
        source="not_in_school"
        label="Not in school"
        choices={not_in_school}
        fullWidth
      />
    </>
  )
}
