import React from 'react'
import {
  ReferenceManyField,
  Pagination,
  Datagrid,
  TextField,
} from 'react-admin'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import NoteIcon from '@material-ui/icons/Note'
import Button from '@material-ui/core/Button'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'

export default function AthleteRecordsList(props) {
  const { setShowPage } = props

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        size="small"
        endIcon={<AddBoxOutlinedIcon />}
        onClick={() => setShowPage('athlete_records_create')}>
        Adicionar nova historial
      </Button>
      <Grid container justify="center">
        <ReferenceManyField
          {...props}
          pagination={<Pagination />}
          label="Registo por época do jogador"
          reference="athlete_records"
          target="athlete_id">
          <Datagrid {...props}>
            <TextField source="season_id" label="Época" />
            <TextField source="team_formation" label="Escalão" />
            <TextField source="team" label="Equipa" />
            <ViewAthleteRecordButton {...props} />
            <EditButton {...props} />
          </Datagrid>
        </ReferenceManyField>
      </Grid>
    </>
  )
}

const ViewAthleteRecordButton = ({ record }) => {
  return record && record.id ? (
    <Button
      size="small"
      color="primary"
      component={Link}
      endIcon={<NoteIcon />}
      to={`/athlete_records/${record.id}/show`}
      label="Ver Registo">
      Ver Jogador
    </Button>
  ) : null
}

const EditButton = (props) => {
  const { setShowPage, SetEditRecord, record } = props
  return (
    record && (
      <Button
        color="primary"
        size="small"
        endIcon={<AddBoxOutlinedIcon />}
        onClick={() => {
          SetEditRecord(record)
          setShowPage('athlete_records_edit')
        }}>
        Editar
      </Button>
    )
  )
}
