export const current_school_degree = [
  { id: 1, name: 'Nenhum' },
  { id: 2, name: '5º Ano' },
  { id: 3, name: '6º Ano' },
  { id: 4, name: '7º Ano' },
  { id: 5, name: '8º Ano' },
  { id: 6, name: '9º Ano' },
  { id: 7, name: '10º Ano' },
  { id: 8, name: '11º Ano' },
  { id: 9, name: '12º Ano' },
  { id: 10, name: 'Licenciatura' },
  { id: 11, name: 'Mestrado' },
  { id: 12, name: 'Doutoramento' },
]

export const not_in_school = [
  { id: 1, name: 'Sem efeito' },
  { id: 2, name: 'Sem estudos ou 1º Ciclo incompleto' },
  { id: 3, name: '1º Ciclo' },
  { id: 4, name: '2º Ciclo' },
  { id: 5, name: '3º Ciclo' },
  { id: 6, name: 'Secundário' },
  { id: 7, name: 'Licenciatura' },
  { id: 8, name: 'Mestrado' },
  { id: 9, name: 'Doutoramento' },
]
