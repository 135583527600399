import React from 'react'
import { merge } from 'lodash'
import { FormWithRedirect, NumberInput, required } from 'react-admin'
import { Box, Typography } from '@material-ui/core'
import MathDependentInput from '../../components/MathDependentInput'
import { VO2MaxValue } from '../../helpers/MathHelpers'

const DEFAULT_VALUES = ({
  type,
  action,
  athlete_id,
  season_id,
  filledwhen,
}) => {
  return {
    vo2max: null,
    distance: null,
    level: null,
    kmh: null,
    type: type,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
    filled_when: filledwhen,
  }
}

export default (props) => {
  const { setShowPage, physicalTest, data, toolbar } = props
  let initialValues = merge({}, DEFAULT_VALUES(physicalTest), data)

  return (
    <FormWithRedirect
      {...props}
      initialValues={initialValues}
      redirect={() => {
        setShowPage('athlete_questionnaires_list')
      }}
      render={(formProps) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={1} mr="1em">
                <FirstColumn />
              </Box>
              <Box flex={1} ml="1em">
                <SecondColumn />
              </Box>
            </Box>
          </Box>
          {React.cloneElement(toolbar, {
            props,
            handleSubmitWithRedirect: formProps.handleSubmitWithRedirect,
          })}
        </form>
      )}
    />
  )
}

const FirstColumn = (props) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Medição do nível e velocidade
      </Typography>
      <NumberInput
        source="level"
        label="Valor do nível"
        validate={[required()]}
        fullWidth
      />
      <NumberInput
        source="kmh"
        label="Valor do km/h"
        validate={[required()]}
        fullWidth
      />
    </>
  )
}

const SecondColumn = (props) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Medição do VO2 máximo
      </Typography>
      <NumberInput
        source="distance"
        label="Insira o valor da distância"
        validate={[required()]}
        fullWidth
      />
      <MathDependentInput
        source="vo2max"
        label="Valor do VO2 máximo é:"
        calculate={VO2MaxValue}
        variables={'distance'}
        fullWidth
      />
    </>
  )
}
