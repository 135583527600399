import React, { useState } from 'react'
import {
  TextField,
  Labeled,
  DateField,
  refreshView,
  useNotify,
  SelectField,
} from 'react-admin'
import { Typography, Box } from '@material-ui/core'
import AthletePhotoField from '../AthletePhotoField'
import api from '../../data/axios-config'
import { useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
  footed_options,
  default_positions_options,
} from '../../constants/questionnaires_responses'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
)

export default function AthleteSummary({ record }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const notify = useNotify()
  const [loading, setLoading] = useState(false)

  return (
    <Box display={{ md: 'block', lg: 'flex' }}>
      <Box flex={2} mr={{ md: 0, lg: '1em' }}>
        <Typography variant="h6" gutterBottom>
          Dados do Jogador
        </Typography>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="IDNR">
              <TextField record={record} source="idnr" />
            </Labeled>
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="Nome">
              <TextField record={record} source="name" />
            </Labeled>
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="Data de Nascimento">
              <DateField record={record} source="birth_date" />
            </Labeled>
          </Box>
        </Box>

        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <Labeled label="Morada">
            <TextField record={record} source="address" />
          </Labeled>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="Concelho">
              <TextField record={record} source="district" />
            </Labeled>
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="Código Postal">
              <TextField record={record} source="postal" />
            </Labeled>
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="Nacionalidade">
              <TextField record={record} source="nationality" />
            </Labeled>
          </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="Telemóvel">
              <TextField record={record} source="phone" />
            </Labeled>
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="Telefone do encarregado">
              <TextField record={record} source="parent_phone" />
            </Labeled>
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="Email do encarregado">
              <TextField record={record} source="parent_phone" />
            </Labeled>
          </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="Opção">
              {record && !record.active ? (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() => activeAthlete(record.id)}>
                  {loading && (
                    <CircularProgress
                      className={classes.icon}
                      size={18}
                      thickness={2}
                    />
                  )}
                  Activar jogador
                </Button>
              ) : (
                <div>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => inactiveAthlete(record.id)}>
                    {loading && (
                      <CircularProgress
                        className={classes.icon}
                        size={18}
                        thickness={2}
                      />
                    )}
                    Inactivar jogador
                  </Button>
                </div>
              )}
            </Labeled>
          </Box>
        </Box>

        <Box mt="1em" />

        <Typography variant="h6" gutterBottom>
          Outros dados
        </Typography>

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="Pé dominante">
              <SelectField
                record={record}
                source="footed"
                choices={footed_options}
              />
            </Labeled>
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="Posição">
              <SelectField
                record={record}
                source="default_position"
                choices={default_positions_options}
              />
            </Labeled>
          </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="Posições da última temporada">
              <TextField record={record} source="latest_field_positions" />
            </Labeled>
          </Box>
        </Box>
      </Box>

      <Box mt="1em" />

      <Box flex={2} mr={{ md: 0, lg: '1em' }}>
        <Typography variant="h6" gutterBottom>
          Foto
        </Typography>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <AthletePhotoField record={record} />
          </Box>
        </Box>

        <Box mt="1em" />
      </Box>
    </Box>
  )

  async function activeAthlete(id) {
    try {
      setLoading(true)
      await api.put(`/athletes/${id}/active`)
      dispatch(refreshView())
    } catch (err) {
      notify(err.message, 'error')
      setLoading(false)
    }
  }

  async function inactiveAthlete(id) {
    try {
      setLoading(true)
      await api.put(`/athletes/${id}/inactive`)
      dispatch(refreshView())
    } catch (err) {
      notify(err.message, 'error')
      setLoading(false)
    }
  }
}
