import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  Create,
  Edit,
  Filter,
  SearchInput,
} from 'react-admin'
import { makeStyles, Chip } from '@material-ui/core'
import AthleteForm from './AthleteForm'
import { getBase64 } from '../../helpers/FileToBase64'

const useEditStyles = makeStyles({
  root: { alignItems: 'flex-start' },
})

const useQuickFilterStyles = makeStyles((theme) => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}))

const QuickFilter = ({ label }) => {
  const classes = useQuickFilterStyles()
  return <Chip className={classes.chip} label={label} />
}

const AthleteFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" placeholder="Nome" alwaysOn />
    <QuickFilter
      source="active_athletes"
      label="Jogadores activos"
      defaultValue="-"
    />
    <QuickFilter
      source="inactive_athletes"
      label="Jogadores inactivos"
      defaultValue="-"
    />
  </Filter>
)

export const AthleteList = (props) => (
  <List
    {...props}
    filters={<AthleteFilter />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    title="Lista de jogadores">
    <Datagrid rowClick="show">
      <TextField source="id" label="Id" />
      <TextField source="name" label="Nome" />
      <TextField source="nickname" label="Apelido" />
      <TextField source="idnr" label="IDNR" />
      <DateField source="created_at" label="Criado em" />
      <EditButton label="Editar" />
    </Datagrid>
  </List>
)

export const AthleteCreate = (props) => {
  const classes = useEditStyles()
  return (
    <Create
      classes={classes}
      successMessage="Operação efectuada com sucesso"
      title="Criar novo jogador"
      label="Novo"
      transform={transform}
      {...props}>
      <AthleteForm {...props} />
    </Create>
  )
}

export const AthleteEdit = (props) => {
  const classes = useEditStyles()
  return (
    <Edit
      classes={classes}
      successMessage="Operação efectuada com sucesso"
      title="Editar jogador"
      label="Editar"
      transform={transform}
      undoable={false}
      {...props}>
      <AthleteForm />
    </Edit>
  )
}

async function transform(data) {
  if (data.photo && data.photo.rawFile) {
    const image64 = await getBase64(data.photo.rawFile)
    return {
      ...data,
      file64: image64,
    }
  }

  return { ...data }
}
