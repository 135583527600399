import React from 'react'
import Chip from '@material-ui/core/Chip'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const ChipCustomColor = ({ color, label }) => {
  const theme = createMuiTheme({
    palette: {
      primary: { main: color },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <Chip label={label} color="primary" />
    </ThemeProvider>
  )
}

ChipCustomColor.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
}

ChipCustomColor.defaultProps = {
  label: 'Basic',
  color: 'primary',
}

export default ChipCustomColor
