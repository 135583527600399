import React from 'react'
import { merge } from 'lodash'
import { SimpleForm, SelectInput, required } from 'react-admin'
import { disagreement_options } from '../../constants/questionnaires_responses'
import { life_satisfactions_questions } from '../../constants/questionnaires_questions'

const DEFAULT_VALUES = ({
  type,
  action,
  athlete_id,
  season_id,
  filledwhen,
}) => {
  return {
    ideal: null,
    excelent: null,
    satisfied: null,
    reach: null,
    not_change: null,
    type: type,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
    filled_when: filledwhen,
  }
}

export default (props) => {
  const { setShowPage, questionnaire, data, toolbar } = props
  let initialValues = merge({}, DEFAULT_VALUES(questionnaire), data)

  return (
    <>
      <SimpleForm
        initialValues={initialValues}
        toolbar={toolbar}
        redirect={() => {
          setShowPage('athlete_questionnaires_list')
        }}>
        <SelectInput
          source="ideal"
          label={life_satisfactions_questions.ideal}
          choices={disagreement_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="excelent"
          label={life_satisfactions_questions.excelent}
          choices={disagreement_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="satisfied"
          label={life_satisfactions_questions.satisfied}
          choices={disagreement_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="reach"
          label={life_satisfactions_questions.reach}
          choices={disagreement_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="not_change"
          label={life_satisfactions_questions.not_change}
          choices={disagreement_options}
          validate={[required()]}
          fullWidth
        />
      </SimpleForm>
    </>
  )
}
