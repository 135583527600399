import React, { useState, useEffect } from 'react'
import BackButton from '../../components/BackButton'
import { makeStyles } from '@material-ui/core/styles'
import {
  useNotify,
  LinearProgress,
  CreateView,
  Toolbar,
  SaveButton,
} from 'react-admin'
import api from '../../data/axios-config'
import { questionnaires_list } from '../../constants/questionnaires_list'
import { sport_types_options } from '../../constants/questionnaires_responses'
import DeleteButtonWithConfirm from '../../components/DeleteButtonWithConfirm'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

export default (props) => {
  const { setShowPage, questionnaire } = props
  const notify = useNotify()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(questionnaire.action === 'edit')
  const questionnaireType = questionnaires_list.find(
    (x) => x.id === questionnaire.type,
  )

  useEffect(() => {
    if (questionnaire.action === 'create') {
      return
    }

    ;(async () => {
      try {
        setLoading(true)
        const response = await api.get(
          `/athletes/${questionnaire.athlete_id}/questionnaires/${questionnaire.season_id}/${questionnaire.filledwhen}/${questionnaire.type}`,
        )
        setData(response.data)
      } catch (err) {
        notify(err.message, 'error')
      } finally {
        setLoading(false)
      }
    })()
  }, [questionnaire, notify])

  if (loading) {
    return <LinearProgress />
  }

  return (
    <>
      <BackButton
        onBack={() => {
          setShowPage('athlete_questionnaires_list')
        }}
      />
      <CreateView
        record={{}}
        title={
          questionnaire.action === 'edit'
            ? ' - Editar questionário'
            : ' - Criar questionário'
        }>
        {questionnaireType && questionnaireType.form ? (
          React.cloneElement(questionnaireType.form, {
            props,
            data: data,
            questionnaire: questionnaire,
            toolbar: (
              <QuestionnaireToolbar
                setShowPage={setShowPage}
                action={questionnaire.action}
                questionnaireType={questionnaireType}
                recordId={data ? data.id : null}
              />
            ),
          })
        ) : (
          <>"Não existe nenhuma form para este questionário!"</>
        )}
      </CreateView>
    </>
  )
}

const QuestionnaireToolbar = (props) => {
  const { setShowPage, action, questionnaireType, recordId } = props
  const notify = useNotify()
  async function handleSave(values, redirect) {
    try {
      let body = fixValues(values)
      if (values.action === 'create') {
        await api.post(`/questionnaires`, body)
      } else {
        await api.put(`/questionnaires/${values.id}`, body)
      }
      setShowPage('athlete_questionnaires_list')
      notify('Questionário guardado com sucesso!', 'success')
    } catch (error) {
      notify(error.message, 'error')
    }
  }

  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton onSave={handleSave} />
      {action === 'edit' && (
        <DeleteButtonWithConfirm
          title={`Excluir ${questionnaireType.name} #${recordId}`}
          url={`questionnaires/${questionnaireType.id}/${recordId}`}
          redirect={() => setShowPage('athlete_questionnaires_list')}
        />
      )}
    </Toolbar>
  )

  function fixValues(values) {
    if (values.type === 'sleep_habits') {
      const inputs_array = ['wake_up', 'sleep_time', 'weekend', 'sleeping']
      let new_values = values
      inputs_array.forEach((element) => {
        new_values[element] = new Date(values[element]).toLocaleTimeString(
          'en-GB',
        )
      })
      return new_values
    }

    if (values.type === 'sports') {
      let new_values = values
      let type_name_array = []
      values.types.forEach((value_type) => {
        const sport = sport_types_options.find(
          (element) => element.id === value_type,
        )
        type_name_array.push(sport.name)
      })
      new_values.types = type_name_array.join(',')
      return new_values
    }

    return values
  }
}
