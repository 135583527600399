import axios from 'axios'
import apiUrl from '../data/api-url'

const instance = axios.create({
  baseURL: apiUrl,
})

instance.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem('token')}`
}

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (err) => {
    let res = err.response.data
    if (err.response.data.message) {
      res.message = err.response.data.message
    } else {
      res.message = `api.errors.${err.response.data.error}`
    }

    return Promise.reject(res)
  },
)

export default instance
