import React from 'react'
import BarGraph from '../components/graphs/Bar'
import Line from '../components/graphs/Line'

export const defaultPositionsColors = [
  { id: '1', color: '#0D47A1', name: 'Guarda-Redes' },
  { id: '2', color: '#2E7D32', name: 'Defesa' },
  { id: '3', color: '#D50000', name: 'Médio' },
  { id: '4', color: '#424242', name: 'Avançado' },
  { id: '5', color: '#E65100', name: 'Sem Posição' },
]

const getColor = (bar) => {
  const default_position_props = defaultPositionsColors.find(
    (element) => element.id === bar.data.default_position,
  )
  return default_position_props ? default_position_props.color : null
}

const customTooltip = ({ indexValue, value, color }) => (
  <>
    {indexValue}:<strong style={{ color }}> {value}</strong>
  </>
)

const CustomOnClick = (value, history) => {
  if (!value.data.id) {
    return
  }

  history.push(`/athlete_records/${value.data.id}/show`)
}

const defaultBarProps = {
  getColor: getColor,
  tooltip: customTooltip,
  onClick: CustomOnClick,
}

export const physical_tests_average_graphs = [
  {
    id: 'bars',
    name: 'Suspensão na barra',
    settings: {
      keys: ['bars_ini'],
      axisBottomLegend: 'Nome',
      axisLeftLegend: 'Suspensão na barra (s)',
      indexBy: 'name',
    },
    graph: <BarGraph {...defaultBarProps} />,
  },
  {
    id: 'body_compositions',
    name: 'Antropometria',
    settings: {
      keys: ['weight_med', 'height_med', 'waist_med'],
      axisBottomLegend: 'Nome',
      multipleLegends: {
        weight_med: {
          tabLabel: 'Peso',
          axisLeftLegend: 'Peso Médio (kg)',
        },
        height_med: {
          tabLabel: 'Altura',
          axisLeftLegend: 'Altura Média (cm)',
        },
        waist_med: {
          tabLabel: 'Cintura',
          axisLeftLegend: 'Cintura Média (cm)',
        },
      },
      indexBy: 'name',
    },
    multiple: true,
    graph: <BarGraph {...defaultBarProps} />,
  },
  {
    id: 'dinamometries',
    name: 'Dinamometria Manual',
    settings: {
      keys: ['dinamometries_l_med', 'dinamometries_w_med'],
      axisBottomLegend: 'Nome',
      multipleLegends: {
        dinamometries_l_med: {
          tabLabel: 'Membro Superior Esquerdo',
          axisLeftLegend: 'Massa (kg)',
        },
        dinamometries_w_med: {
          tabLabel: 'Membro Superior Direito',
          axisLeftLegend: 'Massa (kg)',
        },
      },
      indexBy: 'name',
    },
    multiple: true,
    graph: <BarGraph {...defaultBarProps} />,
  },
  {
    id: 'sit_ups',
    name: 'Abdominais',
    settings: {
      keys: ['sit_ups_ini'],
      axisBottomLegend: 'Nome',
      axisLeftLegend: 'Abdominais (nº)',
      indexBy: 'name',
    },
    graph: <BarGraph {...defaultBarProps} />,
  },
  {
    id: 'push_ups',
    name: 'Flexões de Braços',
    settings: {
      keys: ['push_ups_ini'],
      axisBottomLegend: 'Nome',
      axisLeftLegend: 'Flexões (nº)',
      indexBy: 'name',
    },
    graph: <BarGraph {...defaultBarProps} />,
  },
  {
    id: 'sprints',
    name: 'Sprint Linear',
    settings: {
      keys: ['sprints_5m', 'sprints_10m', 'sprints_35m'],
      axisBottomLegend: 'Tempo (s)',
      axisLeftLegend: '',
      multipleLegends: {
        sprints_5m: {
          tabLabel: '5 Metros',
          axisLeftLegend: '',
        },
        sprints_10m: {
          tabLabel: '10 Metros',
          axisLeftLegend: '',
        },
        sprints_35m: {
          tabLabel: '35 Metros',
          axisLeftLegend: '',
        },
      },
      indexBy: 'name',
    },
    multiple: true,
    graph: (
      <BarGraph
        layout="horizontal"
        marginLeft={200}
        groupMode="grouped"
        containerHeight={1000}
        {...defaultBarProps}
      />
    ),
  },
  {
    id: 't_tests',
    name: 'T-Testes',
    settings: {
      keys: ['t_tests'],
      axisBottomLegend: 'Nome',
      axisLeftLegend: 'T-Testes (ss.fff)',
      indexBy: 'name',
    },
    graph: <BarGraph {...defaultBarProps} />,
  },
  {
    id: 'yyir1s',
    name: 'Yo-Yo Interment Recovery Test(YYIR1)',
    settings: {
      keys: ['vo2max', 'level', 'distance', 'kmh'],
      axisBottomLegend: 'Nome',
      multipleLegends: {
        vo2max: {
          tabLabel: 'VO2MAX',
          axisLeftLegend: 'VO2MAX (ml.kg-1.min-1)',
        },
        level: {
          tabLabel: 'Level',
          axisLeftLegend: 'Level',
        },
        distance: {
          tabLabel: 'Distância Pecorrida',
          axisLeftLegend: 'Metros',
        },
        kmh: {
          tabLabel: 'KM/H',
          axisLeftLegend: 'KM/H',
        },
      },
      indexBy: 'name',
    },
    multiple: true,
    graph: <BarGraph {...defaultBarProps} />,
  },
  {
    id: 'sjs',
    name: 'Squat Jump (SJ)',
    settings: {
      keys: ['avg_a', 'avg_p', 'maximum_a', 'maximum_p'],
      axisBottomLegend: 'Nome',
      multipleLegends: {
        avg_a: {
          tabLabel: 'Altura Média',
          axisLeftLegend: 'Altura Média (cm)',
        },
        avg_p: {
          tabLabel: 'Potência Média',
          axisLeftLegend: 'Potência Média (w/kg)',
        },
        maximum_a: {
          tabLabel: 'Altura Máxima',
          axisLeftLegend: 'Altura Média (cm)',
        },
        maximum_p: {
          tabLabel: 'Potência Máxima',
          axisLeftLegend: 'Potência Média (w/kg)',
        },
      },
      indexBy: 'name',
    },
    multiple: true,
    graph: <BarGraph {...defaultBarProps} />,
  },
  {
    id: 'cmjs',
    name: 'CounterMovement Jump (CMJ)',
    settings: {
      keys: ['avg_a', 'avg_p', 'maximum_a', 'maximum_p'],
      axisBottomLegend: 'Nome',
      multipleLegends: {
        avg_a: {
          tabLabel: 'Altura Média',
          axisLeftLegend: 'Altura Média (cm)',
        },
        avg_p: {
          tabLabel: 'Potência Média',
          axisLeftLegend: 'Potência Média (w/kg)',
        },
        maximum_a: {
          tabLabel: 'Altura Máxima',
          axisLeftLegend: 'Altura Média (cm)',
        },
        maximum_p: {
          tabLabel: 'Potência Máxima',
          axisLeftLegend: 'Potência Média (w/kg)',
        },
      },
      indexBy: 'name',
    },
    multiple: true,
    graph: <BarGraph {...defaultBarProps} />,
  },
  {
    id: 'flamingos',
    name: 'Equilíbrio Flamingo',
    settings: {
      keys: ['flamingo'],
      axisBottomLegend: 'Nome',
      axisLeftLegend: 'Flamingos (nº)',
      indexBy: 'name',
    },
    graph: <BarGraph {...defaultBarProps} />,
  },
  {
    id: 'sit_reachs',
    name: 'Senta e Alcança',
    settings: {
      keys: ['both', 'left', 'right'],
      axisBottomLegend: 'Nome',
      multipleLegends: {
        both: {
          tabLabel: 'Ambos Membros',
          axisLeftLegend: 'Tempo (s)',
        },
        left: {
          tabLabel: 'Membro Inferior Direito',
          axisLeftLegend: 'Tempo (s)',
        },
        right: {
          tabLabel: 'Membro Inferior Esquerdo',
          axisLeftLegend: 'Tempo (s)',
        },
      },
      indexBy: 'name',
    },
    multiple: true,
    graph: <BarGraph {...defaultBarProps} />,
  },
  /* 
  { id: 'shoulders', name: 'Flexibilidade de Ombros' },
  { id: 'plank_sides', name: ' Prancha Lateral' },
  { id: 'plank_frontals', name: 'Prancha Frontal' },
  { id: 'adioposities', name: 'Composição Corporal' }, 
  */
]

const formatLineData = (data, keys, name) => {
  let new_array = [
    {
      id: name,
      color: 'hsl(13, 70%, 50%)',
      data: [],
    },
  ]

  data.forEach((element) => {
    new_array[0].data.push({
      x: element[keys.x],
      y: element[keys.y],
    })
  })

  return new_array
}

export const history_physical_tests_graphs = [
  {
    id: 'bars',
    name: 'Suspensão na barra',
    settings: {
      keys: {
        name: 'Barra',
        x: 'created_at',
        y: 'bars_ini',
      },
      axisBottomLegend: 'Data',
      axisLeftLegend: 'Tempo (s)',
    },
    format: formatLineData,
    graph: <Line />,
  },
  {
    id: 'sit_ups',
    name: 'Abdominais',
    settings: {
      keys: {
        name: 'Abdominais',
        x: 'created_at',
        y: 'sit_ups_ini',
      },
      axisBottomLegend: 'Data',
      axisLeftLegend: 'Tempo (s)',
    },
    format: formatLineData,
    graph: <Line />,
  },
  {
    id: 'push_ups',
    name: 'Flexões de Braços',
    settings: {
      keys: {
        name: 'Flexões',
        x: 'created_at',
        y: 'push_ups_ini',
      },
      axisBottomLegend: 'Data',
      axisLeftLegend: 'Quantidade (nº)',
    },
    format: formatLineData,
    graph: <Line />,
  },
  {
    id: 'flamingos',
    name: 'Flamingo',
    settings: {
      keys: {
        name: 'Equilíbrio Flamingo',
        x: 'created_at',
        y: 'flamingo',
      },
      axisBottomLegend: 'Data',
      axisLeftLegend: 'Quantidade(nº)',
    },
    format: formatLineData,
    graph: <Line />,
  },
  {
    id: 't_tests',
    name: 'T-Testes',
    settings: {
      keys: [
        {
          tabName: 'T-Teste',
          name: 'T-Testes',
          x: 'created_at',
          y: 't_tests',
          axisLeftLegend: 'Tempo(ss.fff)',
        },
        {
          tabName: 'T-Teste Km',
          name: 'T-Teste Km',
          x: 'created_at',
          y: 't_tests_km',
          axisLeftLegend: 'Distância (km)',
        },
      ],
      axisBottomLegend: 'Data',
    },
    multiple: true,
    singleFormat: formatLineData,
    graph: <Line />,
  },
  {
    id: 'body_compositions',
    name: 'Antropometria',
    settings: {
      keys: [
        {
          tabName: 'Peso',
          name: 'Peso Média',
          x: 'created_at',
          y: 'weight_med',
          axisLeftLegend: 'Massa (kg)',
        },
        {
          tabName: 'Altura',
          name: 'Altura Média',
          x: 'created_at',
          y: 'height_med',
          axisLeftLegend: 'Medida (cm)',
        },
        {
          tabName: 'Cintura',
          name: 'Cintura Média',
          x: 'created_at',
          y: 'waist_med',
          axisLeftLegend: 'Medida (cm)',
        },
      ],
      axisBottomLegend: 'Data',
    },
    multiple: true,
    singleFormat: formatLineData,
    graph: <Line />,
  },
  {
    id: 'dinamometries',
    name: 'Dinamometria Manual',
    settings: {
      keys: [
        {
          tabName: 'Membro Superior Esquerdo',
          name: 'Esquerdo',
          x: 'created_at',
          y: 'dinamometries_l_med',
          axisLeftLegend: 'Massa (kg)',
        },
        {
          tabName: 'Membro Superior Direito',
          name: 'Direito',
          x: 'created_at',
          y: 'dinamometries_w_med',
          axisLeftLegend: 'Massa (kg)',
        },
      ],
      axisBottomLegend: 'Data',
    },
    multiple: true,
    singleFormat: formatLineData,
    graph: <Line />,
  },
  {
    id: 'yyir1s',
    name: 'Yo-Yo Interment Recovery Test(YYIR1)',
    settings: {
      keys: [
        {
          tabName: 'VO2Max',
          name: 'VO2Max',
          x: 'created_at',
          y: 'vo2max',
          axisLeftLegend: 'Medida (ml.kg-1.min-1)',
        },
        {
          tabName: 'Distância',
          name: 'Distância',
          x: 'created_at',
          y: 'distance',
          axisLeftLegend: 'Metros',
        },
        {
          tabName: 'Level',
          name: 'Level',
          x: 'created_at',
          y: 'level',
          axisLeftLegend: 'Level',
        },
        {
          tabName: 'KM/H',
          name: 'KM/H',
          x: 'created_at',
          y: 'kmh',
          axisLeftLegend: 'KM/H',
        },
      ],
      axisBottomLegend: 'Data',
    },
    multiple: true,
    singleFormat: formatLineData,
    graph: <Line />,
  },
  {
    id: 'plank_sides',
    name: 'Prancha Lateral',
    settings: {
      keys: [
        {
          tabName: 'Esquerda',
          name: 'Esquerda',
          x: 'created_at',
          y: 'plank_sides_esq',
          axisLeftLegend: 'Tempo (s)',
        },
        {
          tabName: 'Direita',
          name: 'Direita',
          x: 'created_at',
          y: 'plank_sides_dir',
          axisLeftLegend: 'Tempo (s)',
        },
      ],
      axisBottomLegend: 'Data',
    },
    multiple: true,
    singleFormat: formatLineData,
    graph: <Line />,
  },
  {
    id: 'plank_frontals',
    name: 'Prancha Frontal',
    settings: {
      keys: {
        name: 'Prancha Frontral',
        x: 'created_at',
        y: 'plank_frontals_ini',
      },
      axisBottomLegend: 'Data',
      axisLeftLegend: 'Tempo (s)',
    },
    format: formatLineData,
    graph: <Line />,
  },
  {
    id: 'sjs',
    name: 'Squat Jump',
    settings: {
      keys: [
        {
          tabName: 'Altura Média',
          name: 'Altura Média',
          x: 'created_at',
          y: 'avg_a',
          axisLeftLegend: 'Medida (cm)',
        },
        {
          tabName: 'Potência Média',
          name: 'Direita',
          x: 'created_at',
          y: 'avg_p',
          axisLeftLegend: 'Tempo (s)',
        },
        {
          tabName: 'Altura Máxima',
          name: 'Altura Máxima',
          x: 'created_at',
          y: 'maximum_a',
          axisLeftLegend: 'Tempo (s)',
        },
        {
          tabName: 'Potência Máxima',
          name: 'Potência Máxima',
          x: 'created_at',
          y: 'maximum_p',
          axisLeftLegend: 'Tempo (s)',
        },
      ],
      axisBottomLegend: 'Data',
    },
    multiple: true,
    singleFormat: formatLineData,
    graph: <Line />,
  },
  {
    id: 'cmjs',
    name: 'CounterMovement Jump',
    settings: {
      keys: [
        {
          tabName: 'Altura Média',
          name: 'Altura Média',
          x: 'created_at',
          y: 'avg_a',
          axisLeftLegend: 'Medida (cm)',
        },
        {
          tabName: 'Potência Média',
          name: 'Direita',
          x: 'created_at',
          y: 'avg_p',
          axisLeftLegend: 'Tempo (s)',
        },
        {
          tabName: 'Altura Máxima',
          name: 'Altura Máxima',
          x: 'created_at',
          y: 'maximum_a',
          axisLeftLegend: 'Tempo (s)',
        },
        {
          tabName: 'Potência Máxima',
          name: 'Potência Máxima',
          x: 'created_at',
          y: 'maximum_p',
          axisLeftLegend: 'Tempo (s)',
        },
      ],
      axisBottomLegend: 'Data',
    },
    multiple: true,
    singleFormat: formatLineData,
    graph: <Line />,
  },
  {
    id: 'sit_reachs',
    name: 'Senta e Alcança',
    settings: {
      keys: [
        {
          tabName: 'Ambos Membros',
          name: 'Ambos',
          x: 'created_at',
          y: 'both',
          axisLeftLegend: 'Tempo (s)',
        },
        {
          tabName: 'Membro Inferior Direito',
          name: 'Direito',
          x: 'created_at',
          y: 'right',
          axisLeftLegend: 'Tempo (s)',
        },
        {
          tabName: 'Membro Inferior Esquerdo',
          name: 'Esquerdo',
          x: 'created_at',
          y: 'left',
          axisLeftLegend: 'Tempo (s)',
        },
      ],
      axisBottomLegend: 'Data',
    },
    multiple: true,
    singleFormat: formatLineData,
    graph: <Line />,
  },
  {
    id: 'adioposities',
    name: 'Composição Corporal',
    settings: {
      keys: [
        {
          tabName: 'Tricipital',
          name: 'Tricipital Média',
          x: 'created_at',
          y: 'tricipital_med',
          axisLeftLegend: '..',
        },
        {
          tabName: 'Bicipital',
          name: 'Bicipital Média',
          x: 'created_at',
          y: 'bicipital_med',
          axisLeftLegend: '...',
        },
        {
          tabName: 'Abdominal',
          name: 'Abdominal Média',
          x: 'created_at',
          y: 'abdominal_med',
          axisLeftLegend: '....',
        },
        {
          tabName: 'Subescapular',
          name: 'Subescapular Média',
          x: 'created_at',
          y: 'subescapolar_med',
          axisLeftLegend: '....',
        },
        {
          tabName: 'Geminal',
          name: 'Geminal Média',
          x: 'created_at',
          y: 'geminal_med',
          axisLeftLegend: '....',
        },
        {
          tabName: 'Suprailiaca',
          name: 'Suprailiaca Média',
          x: 'created_at',
          y: 'suprailiaca_med',
          axisLeftLegend: '....',
        },
      ],
      axisBottomLegend: 'Data',
    },
    multiple: true,
    singleFormat: formatLineData,
    graph: <Line />,
  },
]
