import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { addField, FieldTitle } from 'react-admin'
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import ptLocale from 'date-fns/locale/pt'

const style = {
  marginTop: '-8px',
}
const pickerStyle = {
  width: '100%',
}

const makePicker = (PickerComponent, hasTime) => {
  const _makePicker = (props) => {
    const [internalError, setInternalError] = useState(null)

    function onChange(date) {
      props.input.onChange(date)
      if (props.input.onBlur) {
        props.input.onBlur()
      }
    }

    const {
      input,
      options,
      label,
      source,
      resource,
      isRequired,
      className,
      meta,
      fullWidth,
      formatDate,
      disabled,
    } = props

    const { touched, error } = meta

    const inputChange = (e) => {
      let date = new Date(e)

      try {
        if (!hasTime) {
          date = new Date(
            Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
          )
        }

        date = formatDate ? formatDate(date) : date.toISOString()
        setInternalError(null)
        return onChange(date)
      } catch (err) {
        setInternalError(err.message)
        onChange(null)
      }
    }

    return (
      <div className="picker" style={style}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
          <PickerComponent
            {...options}
            label={
              <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
              />
            }
            margin="normal"
            error={!!(touched && (error || internalError))}
            helperText={touched && (error || internalError)}
            className={className}
            style={fullWidth ? pickerStyle : null}
            value={input.value ? input.value : null}
            onChange={inputChange}
            inputVariant="filled"
            ampm={false}
            disabled={disabled}
          />
        </MuiPickersUtilsProvider>
      </div>
    )
  }

  _makePicker.propTypes = {
    input: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
    labelTime: PropTypes.string,
    className: PropTypes.string,
    providerOptions: PropTypes.shape({
      utils: PropTypes.func,
      locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
    disabled: PropTypes.bool,
  }

  _makePicker.defaultProps = {
    input: {},
    isRequired: 'false',
    meta: { touched: false, error: false },
    options: {},
    resource: '',
    source: '',
    labelTime: '',
    className: '',
    providerOptions: {
      utils: DateFnsUtils,
      locale: undefined,
    },
    disabled: false,
  }
  return _makePicker
}

export const DateInput = addField(makePicker(DatePicker, false))
export const TimeInput = addField(makePicker(TimePicker, true))
export const DateTimeInput = addField(makePicker(DateTimePicker, true))
export const KeyboardDateInput = addField(makePicker(KeyboardDatePicker, false))
export const KeyboardDateTimeInput = addField(
  makePicker(KeyboardDateTimePicker, true),
)
