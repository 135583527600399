import React from 'react'
import { NumberInput, FormDataConsumer } from 'react-admin'
import { Typography } from '@material-ui/core'
import {
  minimunValue,
  maximunValue,
  averageValue,
  standardDeviationValue,
  coefficientVariation,
} from '../helpers/MathHelpers'
import { useFormState } from 'react-final-form'
import PropTypes from 'prop-types'

const statistic_array = [
  { name: 'minimum', label: 'Mínimo', callback: minimunValue },
  { name: 'maximum', label: 'Máximo', callback: maximunValue },
  { name: 'avg', label: 'Média', callback: averageValue },
  { name: 'std_dev', label: 'Desvio Padrão', callback: standardDeviationValue },
  {
    name: 'cv',
    label: 'Coeficiente de variação',
    callback: coefficientVariation,
  },
]

const MeasurementInputs = ({ measure_type, measure_variable, jump_number }) => {
  const measurement_inputs = []

  for (let i = 1; i <= parseInt(jump_number); i++) {
    measurement_inputs.push(
      <NumberInput
        source={`${measure_type}_${i}_${measure_variable}`}
        label={`${i}ª medição`}
        fullWidth
        key={`${measure_type}_${i}_${measure_variable}_measure`}
      />,
    )
  }

  return measurement_inputs
}

const StatisticInputs = ({
  measure_variable,
  jump_number,
  statistic_variable,
  measure_type,
}) => {
  const { values } = useFormState()
  const getMeasurementArrayValues = () => {
    let measurement_values = []
    for (let i = 1; i <= parseInt(jump_number); i++) {
      const value = values[`${measure_type}_${i}_${measure_variable}`]

      if (value > 0) {
        measurement_values.push(
          values[`${measure_type}_${i}_${measure_variable}`],
        )
      }
    }
    return measurement_values
  }

  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => (
        <>
          {statistic_array.map((statistic) => {
            return (
              <NumberInput
                source={`${statistic.name}_${statistic_variable}`}
                label={statistic.label}
                {...rest}
                disabled
                value={
                  (values[
                    `${statistic.name}_${statistic_variable}`
                  ] = statistic.callback(getMeasurementArrayValues()))
                }
                key={`${statistic.name}_${measure_variable}_statistic`}
                fullWidth
              />
            )
          })}
        </>
      )}
    </FormDataConsumer>
  )
}

const JumpColumnInput = (props) => {
  const { title } = props
  return (
    <>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <MeasurementInputs {...props} />
      <StatisticInputs {...props} />
    </>
  )
}

JumpColumnInput.propTypes = {
  title: PropTypes.string,
  jump_number: PropTypes.number,
  measure_variable: PropTypes.string,
  statistic_variable: PropTypes.string,
  measure_type: PropTypes.string,
}

export default JumpColumnInput
