import React, { useState } from 'react'
import { Tabs, Tab } from '@material-ui/core'
import Box from '@material-ui/core/Box'

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

export default (props) => {
  const { children } = props
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  let tabs = []
  let tab_panels = []

  children.forEach((component, index) => {
    tabs.push(
      <Tab
        label={component.props.tabLabel}
        {...a11yProps(index)}
        key={`tab-top-${index}`}
      />,
    )
    tab_panels.push(
      <TabPanel value={value} index={index} key={`tab-panel-graph-${index}`}>
        {component}
      </TabPanel>,
    )
  })

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary">
        {tabs}
      </Tabs>
      {tab_panels}
    </div>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{value === index && children}</Box>}
    </div>
  )
}
