import React, { useState } from 'react'
import {
  useQuery,
  Datagrid,
  TextField,
  Pagination,
  ListContextProvider,
} from 'react-admin'
import keyBy from 'lodash/keyBy'
import LinearProgress from '@material-ui/core/LinearProgress'
import moment from 'moment'
import { Typography } from '@material-ui/core'

export default (props) => {
  const today = moment().format('YYYY-MM-DD')
  const [page, setPage] = useState(1)
  const perPage = 10
  const { data, total, loading, error } = useQuery({
    type: 'getList',
    resource: 'questionnaires_tablets',
    payload: {
      pagination: { page, perPage },
      sort: { field: 'id', order: 'ASC' },
      filter: {
        updated_at: today,
      },
    },
  })

  if (loading) {
    return <LinearProgress />
  }

  if (error) {
    return <p>ERROR: {error}</p>
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Jogadores avaliados: {today}
      </Typography>
      <ListContextProvider
        value={{
          data: keyBy(data, 'id'),
          ids: data.map(({ id }) => id),
          total,
          page,
          perPage,
          setPage,
          currentSort: { field: 'id', order: 'ASC' },
          basePath: '/questionnaires_tablets',
          resource: 'questionnaires_tablets',
          selectedIds: [],
        }}>
        <Datagrid>
          <TextField source="id" label="Id" />
          <TextField source="athlete_name" label="Nome" />
          <TextField source="team_formation" label="Escalão" />
        </Datagrid>
        <Pagination rowsPerPageOptions={[]} />
      </ListContextProvider>
    </>
  )
}
