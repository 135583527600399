import React from 'react'
import { merge } from 'lodash'
import { FormWithRedirect, SelectInput, required } from 'react-admin'
import { agreement_options } from '../../constants/questionnaires_responses'
import { motivations_questions } from '../../constants/questionnaires_questions'
import { Box } from '@material-ui/core'

const DEFAULT_VALUES = ({
  type,
  action,
  athlete_id,
  season_id,
  filledwhen,
}) => {
  return {
    why_continue: null,
    pressured: null,
    guilty_quit: null,
    important_benefits: null,
    find_me: null,
    enjoy: null,
    reason_why: null,
    satisfy_other: null,
    embarrassed: null,
    value_benefits: null,
    part_of_me: null,
    because_enjoy: null,
    foggy_reasons: null,
    pressed_by_others: null,
    have_continue: null,
    autodisciple: null,
    expression_of_me: null,
    fun: null,
    worth_it: null,
    satisfy_others: null,
    failures: null,
    learn_new: null,
    standarts: null,
    nice: null,
    type: type,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
    filled_when: filledwhen,
  }
}

export default (props) => {
  const { setShowPage, questionnaire, data, toolbar } = props
  let initialValues = merge({}, DEFAULT_VALUES(questionnaire), data)

  return (
    <FormWithRedirect
      {...props}
      initialValues={initialValues}
      redirect={() => {
        setShowPage('athlete_questionnaires_list')
      }}
      render={(formProps) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={1} mr="1em">
                <FirstQuestionColumn />
              </Box>
              <Box flex={1} ml="1em">
                <SecondQuestionColumn />
              </Box>
            </Box>
          </Box>
          {React.cloneElement(toolbar, {
            props,
            handleSubmitWithRedirect: formProps.handleSubmitWithRedirect,
          })}
        </form>
      )}
    />
  )
}

const FirstQuestionColumn = (props) => {
  return (
    <>
      <SelectInput
        source="why_continue"
        label={motivations_questions.why_continue}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="pressured"
        label={motivations_questions.pressured}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="guilty_quit"
        label={motivations_questions.guilty_quit}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="important_benefits"
        label={motivations_questions.important_benefits}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="find_me"
        label={motivations_questions.find_me}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="enjoy"
        label={motivations_questions.enjoy}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="reason_why"
        label={motivations_questions.reason_why}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="satisfy_other"
        label={motivations_questions.satisfy_other}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="embarrassed"
        label={motivations_questions.embarrassed}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="value_benefits"
        label={motivations_questions.value_benefits}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="part_of_me"
        label={motivations_questions.part_of_me}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="because_enjoy"
        label={motivations_questions.because_enjoy}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
    </>
  )
}

const SecondQuestionColumn = (props) => {
  return (
    <>
      <SelectInput
        source="foggy_reasons"
        label={motivations_questions.foggy_reasons}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="pressed_by_others"
        label={motivations_questions.pressed_by_others}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="have_continue"
        label={motivations_questions.have_continue}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="autodisciple"
        label={motivations_questions.autodisciple}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="expression_of_me"
        label={motivations_questions.expression_of_me}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="fun"
        label={motivations_questions.fun}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="worth_it"
        label={motivations_questions.worth_it}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="satisfy_others"
        label={motivations_questions.satisfy_others}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="failures"
        label={motivations_questions.failures}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="learn_new"
        label={motivations_questions.learn_new}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="standarts"
        label={motivations_questions.standarts}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="nice"
        label={motivations_questions.nice}
        choices={agreement_options}
        validate={[required()]}
        fullWidth
      />
    </>
  )
}
