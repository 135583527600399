import React from 'react'
import { merge } from 'lodash'
import { FormWithRedirect, NumberInput, required } from 'react-admin'
import { Box, Typography } from '@material-ui/core'

const DEFAULT_VALUES = ({
  type,
  action,
  athlete_id,
  season_id,
  filledwhen,
}) => {
  return {
    t_tests: null,
    t_tests_km: null,
    type: type,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
    filled_when: filledwhen,
  }
}

export default (props) => {
  const { setShowPage, physicalTest, data, toolbar } = props
  let initialValues = merge({}, DEFAULT_VALUES(physicalTest), data)

  return (
    <FormWithRedirect
      {...props}
      initialValues={initialValues}
      redirect={() => {
        setShowPage('athlete_questionnaires_list')
      }}
      render={(formProps) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={1} mr="1em">
                <FirstColumn />
              </Box>
              <Box flex={1} ml="1em">
                <SecondColumn />
              </Box>
            </Box>
          </Box>
          {React.cloneElement(toolbar, {
            props,
            handleSubmitWithRedirect: formProps.handleSubmitWithRedirect,
          })}
        </form>
      )}
    />
  )
}

const FirstColumn = (props) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Medição do T-test (Segundo e Milésimas de Segundos) ss.fff
      </Typography>
      <NumberInput
        source="t_tests"
        label="Valor medido no T-test"
        validate={[required()]}
        fullWidth
      />
    </>
  )
}

const SecondColumn = (props) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Medição do T-test (em km/h)
      </Typography>
      <NumberInput
        source="t_tests_km"
        label="km/h do T-test"
        validate={[required()]}
        fullWidth
      />
    </>
  )
}
