import React from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { green, amber } from '@material-ui/core/colors'

const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: amber,
  },
})

export default (props) => {
  const { label, ...rest } = props
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Button {...rest}>{label}</Button>
      </ThemeProvider>
    </div>
  )
}
