import React, { useState } from 'react'
import AthleteRecordsList from './athlete-records/AthleteRecordsList'
import {
  AthleteRecordCreate,
  AthleteRecordEdit,
} from './athlete-records/AthleteRecordForm'

export default (props) => {
  const [showPage, setShowPage] = useState('athlete_records_list')
  const [editRecord, SetEditRecord] = useState(null)

  return (
    <div style={{ marginBottom: 15 }}>
      {showPage === 'athlete_records_list' && (
        <AthleteRecordsList
          {...props}
          setShowPage={setShowPage}
          showPage={showPage}
          SetEditRecord={SetEditRecord}
        />
      )}

      {showPage === 'athlete_records_create' && (
        <AthleteRecordCreate
          {...props}
          setShowPage={setShowPage}
          showPage={showPage}
        />
      )}

      {showPage === 'athlete_records_edit' && (
        <AthleteRecordEdit
          {...props}
          setShowPage={setShowPage}
          showPage={showPage}
          editRecord={editRecord}
          SetEditRecord={SetEditRecord}
        />
      )}
    </div>
  )
}
