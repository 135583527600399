import React, { useState } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from '@material-ui/core'
import ActionCheck from '@material-ui/icons/CheckCircle'
import AlertError from '@material-ui/icons/ErrorOutline'
import api from '../data/axios-config'
import { useNotify } from 'react-admin'

const useStyles = makeStyles(
  (theme) => ({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
  { name: 'RaDeleteWithConfirmButton' },
)

export default (props) => {
  const { className } = props
  const classes = useStyles(props)
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        color="primary"
        className={classnames(
          'ra-delete-button',
          classes.deleteButton,
          className,
        )}
        onClick={handleClickOpen}
        key="button-delete">
        <DeleteIcon />
        Deletar
      </Button>
      <SimpleDialog open={open} onClose={handleClose} {...props} />
    </>
  )
}

const useStylesDialog = makeStyles(
  (theme) => ({
    contentText: {
      minWidth: 400,
    },
    confirmPrimary: {
      color: theme.palette.primary.main,
    },
    confirmWarning: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    iconPaddingStyle: {
      paddingRight: '0.5em',
    },
  }),
  { name: 'RaConfirm' },
)

const SimpleDialog = (props) => {
  const { onClose, open, title, redirect, url } = props
  const classes = useStylesDialog()
  const [loading, setLoading] = useState(false)
  const confirmColor = 'primary'
  const notify = useNotify()

  const handleConfirm = async () => {
    try {
      setLoading(true)
      await api.delete(url)
      redirect()
      notify('Item eliminado com sucesso!', 'success')
    } catch (err) {
      notify(err.message, 'error')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm">
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <DialogContent>Você tem certeza que deseja excluir?</DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          <AlertError className={classes.iconPaddingStyle} />
          Cancelar
        </Button>
        <Button
          disabled={loading}
          onClick={handleConfirm}
          className={classnames('ra-confirm', {
            [classes.confirmWarning]: confirmColor === 'warning',
            [classes.confirmPrimary]: confirmColor === 'primary',
          })}
          autoFocus>
          <ActionCheck className={classes.iconPaddingStyle} />
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
