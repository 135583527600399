import React from 'react'
import { TextField, Labeled, DateField } from 'react-admin'
import { Typography, Box } from '@material-ui/core'

export default function SeasonSummary({ record }) {
  return (
    <Box display={{ md: 'block', lg: 'flex' }}>
      <Box flex={2} mr={{ md: 0, lg: '1em' }}>
        <Typography variant="h6" gutterBottom>
          Dados da Época
        </Typography>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="ID">
              <TextField record={record} source="id" />
            </Labeled>
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="Data do Ínicio">
              <DateField record={record} source="initial_date" />
            </Labeled>
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="Data do Final">
              <DateField record={record} source="end_date" />
            </Labeled>
          </Box>
        </Box>

        <Box mt="1em" />

        <Typography variant="h6" gutterBottom>
          Outros dados
        </Typography>

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="Número de Jogadores inscritos">
              <TextField
                record={record}
                source="number_of_registered_athletes"
              />
            </Labeled>
          </Box>
        </Box>
      </Box>

      <Box mt="1em" />

      <Box flex={2} mr={{ md: 0, lg: '1em' }}>
        <Typography variant="h6" gutterBottom>
          Gráficos / Dados analíticos
        </Typography>
      </Box>
    </Box>
  )
}
