import React from 'react'
import { merge } from 'lodash'
import { FormWithRedirect } from 'react-admin'
import { Box } from '@material-ui/core'
import JumpColumnInput from '../../components/JumpColumnInput'

const DEFAULT_VALUES = ({
  type,
  action,
  athlete_id,
  season_id,
  filledwhen,
}) => {
  return {
    sj_1_a: null,
    sj_2_a: null,
    sj_3_a: null,
    sj_4_a: null,
    sj_1_power: null,
    sj_2_power: null,
    sj_3_power: null,
    sj_4_power: null,
    sj_1_tcont: null,
    sj_2_tcont: null,
    sj_3_tcont: null,
    sj_4_tcont: null,
    sj_1_tflight: null,
    sj_2_tflight: null,
    sj_3_tflight: null,
    sj_4_tflight: null,
    sj_1_pace: null,
    sj_2_pace: null,
    sj_3_pace: null,
    sj_4_pace: null,
    sj_1_rsi: null,
    sj_2_rsi: null,
    sj_3_rsi: null,
    sj_4_rsi: null,
    minimum_a: null,
    maximum_a: null,
    avg_a: null,
    std_dev_a: null,
    cv_a: null,
    minimum_p: null,
    maximum_p: null,
    avg_p: null,
    std_dev_p: null,
    cv_p: null,
    minimum_tcont: null,
    maximum_tcont: null,
    avg_tcont: null,
    std_dev_tcont: null,
    cv_tcont: null,
    minimum_tflight: null,
    maximum_tflight: null,
    avg_tflight: null,
    std_dev_tflight: null,
    cv_tflight: null,
    minimum_rsi: null,
    maximum_rsi: null,
    avg_rsi: null,
    std_dev_rsi: null,
    cv_rsi: null,
    minimum_pace: null,
    maximum_pace: null,
    avg_pace: null,
    std_dev_pace: null,
    cv_pace: null,
    type: type,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
    filled_when: filledwhen,
  }
}

export default (props) => {
  const { setShowPage, physicalTest, data, toolbar } = props
  let initialValues = merge({}, DEFAULT_VALUES(physicalTest), data)

  return (
    <FormWithRedirect
      {...props}
      initialValues={initialValues}
      redirect={() => {
        setShowPage('athlete_questionnaires_list')
      }}
      render={(formProps) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={1} ml="1em">
                <JumpColumnInput
                  title="Tempo de Contacto (s)"
                  jump_number={4}
                  measure_variable="tcont"
                  statistic_variable="tcont"
                  measure_type="sj"
                />
              </Box>
              <Box flex={1} ml="1em">
                <JumpColumnInput
                  title="Tempo de voo (s)"
                  jump_number={4}
                  measure_variable="tflight"
                  statistic_variable="tflight"
                  measure_type="sj"
                />
              </Box>
              <Box flex={1} ml="1em">
                <JumpColumnInput
                  title="Altura (cm)"
                  jump_number={4}
                  measure_variable="a"
                  statistic_variable="a"
                  measure_type="sj"
                />
              </Box>
              <Box flex={1} ml="1em">
                <JumpColumnInput
                  title="Potência (W/Kg)"
                  jump_number={4}
                  measure_variable="power"
                  statistic_variable="p"
                  measure_type="sj"
                />
              </Box>
              <Box flex={1} ml="1em">
                <JumpColumnInput
                  title="Pace (Steps/s)"
                  jump_number={4}
                  measure_variable="pace"
                  statistic_variable="pace"
                  measure_type="sj"
                />
              </Box>
              <Box flex={1} ml="1em">
                <JumpColumnInput
                  title="RSI (m/s)"
                  jump_number={4}
                  measure_variable="rsi"
                  statistic_variable="rsi"
                  measure_type="sj"
                />
              </Box>
            </Box>
          </Box>
          {React.cloneElement(toolbar, {
            props,
            handleSubmitWithRedirect: formProps.handleSubmitWithRedirect,
          })}
        </form>
      )}
    />
  )
}
