import { decode } from 'jsonwebtoken'

export default (param) => {
  let token = param
  const decoded = decode(token, { complete: true })
  const { payload } = decoded
  const { exp } = payload
  const now = Math.floor(Date.now() / 1000)
  if (exp < now) {
    token = null
  }
  return { token, decoded, exp }
}
