import React from 'react'
import { UserMenu, MenuItemLink } from 'react-admin'

export default (props) => {
  return (
    <UserMenu {...props}>

    </UserMenu>
  )
}
