import React from 'react'
import { Show, TabbedShowLayout, Tab } from 'react-admin'
import SeasonSummary from '../../components/season-tabs/SeasonSummary'
import SeasonPhysicalTests from '../../components/season-tabs/SeasonPhysicalTests'

const SeasonTitle = ({ record }) => {
  return <span>Época - {record ? `"${record.id}"` : ''}</span>
}

export default function SeasonShow(props) {
  return (
    <Show {...props} title={<SeasonTitle />}>
      <TabbedShowLayout>
        <Tab label="Sumário">
          <SeasonSummary {...props} />
        </Tab>
        <Tab label="Aptidão Física">
          <SeasonPhysicalTests {...props} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
