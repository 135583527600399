export const teams = [
  { id: 'Equipa A', name: 'Equipa A' },
  { id: 'Equipa B', name: 'Equipa B' },
  { id: 'Equipa C', name: 'Equipa C' },
  { id: 'Equipa D', name: 'Equipa D' },
]

export const team_formations = [
  { id: 'Sub14', name: 'SUB 14' },
  { id: 'Sub15', name: 'SUB 15' },
  { id: 'Sub16', name: 'SUB 16' },
  { id: 'Sub17', name: 'SUB 17' },
  { id: 'Sub18', name: 'SUB 18' },
  { id: 'Sub19', name: 'SUB 19' },
]
