import React from 'react'
import { Box } from '@material-ui/core'
import ButtonLongQuestionPopover from './ButtonLongQuestionPopover'

export default (props) => {
  const { label, source, text, input, ...rest } = props

  if (!input) {
    return 'Necessita definir um tipo de input!'
  }

  return (
    <Box display={{ xs: 'block', sm: 'flex' }}>
      <Box flex={15}>
        {React.cloneElement(input, {
          source: source,
          label: getShortQuestion(label),
          ...rest,
        })}
      </Box>
      <Box flex={1}>
        <ButtonLongQuestionPopover text={label} />
      </Box>
    </Box>
  )
}

const getShortQuestion = (question) => {
  return question.substring(0, 66) + ' ...'
}
