import React, { useState, useEffect } from 'react'
import {
  useNotify,
  SelectInput,
  CreateView,
  SimpleForm,
  LinearProgress,
} from 'react-admin'
import { Typography, Box } from '@material-ui/core'
import { seasons_list, active_season } from '../../../helpers/SeasonsHelper'
import { filled_when } from '../../../constants/questionnaires_responses'
import api from '../../../data/axios-config'
import {
  questionnaires_list,
  first_moment_questionnaires_list,
  second_moment_questionnaires_list,
} from '../../../constants/questionnaires_list'
import ButtonCustomColor from '../../ButtonCustomColor'
import ChipCustomColor from '../../ChipCustomColor'

export default function AthleteQuestionnaires(props) {
  const { setShowPage, setQuestionnaire, inputs, setInputs } = props
  const notify = useNotify()
  const [loadingList, setLoadingList] = useState(true)
  const [data, setData] = useState(null)

  useEffect(() => {
    ;(async () => {
      try {
        setLoadingList(true)
        const response = await api.get(
          `/athletes/${inputs.athlete_id}/questionnaires/${inputs.season_id}/${inputs.filledwhen}`,
        )
        setData(response.data)
      } catch (err) {
        notify(err.message, 'error')
      } finally {
        setLoadingList(false)
      }
    })()
  }, [inputs, notify])

  return (
    <>
      <Box display="flex">
        <Box flex={12} mr="1em">
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
              <InputsForm inputs={inputs} setInputs={setInputs} />
              <Box mt="2em" />
              <CardColorExplanation />
            </Box>

            <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
              <Typography variant="h6" gutterBottom>
                Avaliação
              </Typography>
              <AthleteRecordInitEvalButton
                hasInitEval={data && data.init_eval}
                loading={loadingList}
                {...props}
              />
            </Box>

            <Box flex={4} ml={{ xs: 0, sm: '0.5em' }}>
              <Typography variant="h6" gutterBottom>
                Primeiro Momento
              </Typography>
              <QuestionnairesList
                data={data && data.questionnaires}
                loading={loadingList}
                setShowPage={setShowPage}
                setQuestionnaire={setQuestionnaire}
                inputs={inputs}
                momentQuestionnaires={first_moment_questionnaires_list}
              />
            </Box>
            <Box flex={4} ml={{ xs: 0, sm: '0.5em' }}>
              <Typography variant="h6" gutterBottom>
                Segundo Momento
              </Typography>
              <QuestionnairesList
                data={data && data.questionnaires}
                loading={loadingList}
                setShowPage={setShowPage}
                setQuestionnaire={setQuestionnaire}
                inputs={inputs}
                momentQuestionnaires={second_moment_questionnaires_list}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

const InputsForm = (props) => {
  const { inputs, setInputs } = props

  return (
    <CreateView title=" - Lista de questionários">
      <SimpleForm toolbar={null} initialValues={inputs}>
        <SelectInput
          source="season_id"
          label="Época"
          choices={seasons_list()}
          defaultValue={active_season()}
          onChange={(event) => {
            setInputs({
              athlete_id: inputs.athlete_id,
              season_id: event.target.value,
              filledwhen: inputs.filledwhen,
            })
          }}
        />
        <SelectInput
          source="filledwhen"
          label="Momento"
          choices={filled_when}
          onChange={(event) => {
            setInputs({
              athlete_id: inputs.athlete_id,
              season_id: inputs.season_id,
              filledwhen: event.target.value,
            })
          }}
        />
      </SimpleForm>
    </CreateView>
  )
}

const QuestionnairesList = (props) => {
  const {
    data,
    loading,
    setShowPage,
    setQuestionnaire,
    inputs,
    momentQuestionnaires,
  } = props
  const buttonsList = []

  if (loading) {
    return <LinearProgress />
  }

  if (!momentQuestionnaires) {
    return 'Sem lista de questionários do momento!!!!'
  }

  if (!data) {
    return 'Ainda não existe historial do jogador nesta época!'
  }

  momentQuestionnaires.forEach((questionnaire) => {
    const TextHelper = questionnaires_list.find(
      (element) => element.id === questionnaire,
    )
    buttonsList.push(
      <div key={`${questionnaire}${data[questionnaire]}`}>
        <ButtonCustomColor
          color={data[questionnaire] ? 'primary' : 'default'}
          onClick={() => {
            setQuestionnaire({
              athlete_id: inputs.athlete_id,
              season_id: inputs.season_id,
              filledwhen: inputs.filledwhen,
              type: questionnaire,
              action: data[questionnaire] ? 'edit' : 'create',
            })
            setShowPage('athlete_questionaire_action')
          }}
          variant="contained"
          fullWidth
          label={TextHelper ? TextHelper.name : TextHelper}
          size="large"></ButtonCustomColor>
        &nbsp;
      </div>,
    )
  })

  return <>{buttonsList}</>
}

const AthleteRecordInitEvalButton = ({
  hasInitEval,
  setQuestionnaire,
  setShowPage,
  inputs,
  loading,
}) => {
  if (loading) {
    return <LinearProgress />
  }

  return (
    <ButtonCustomColor
      color={hasInitEval ? 'primary' : 'default'}
      onClick={() => {
        setQuestionnaire({
          athlete_id: inputs.athlete_id,
          season_id: inputs.season_id,
          action: hasInitEval ? 'edit' : 'create',
        })
        setShowPage('athlete_init_eval')
      }}
      variant="contained"
      fullWidth
      label="Avaliação inicial"
      size="large"></ButtonCustomColor>
  )
}

const CardColorExplanation = () => {
  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Significado das cores:
      </Typography>
      <Box mt="0.5em" />
      <ChipCustomColor color="#E0E0E0" label={'Não preenchido'} />
      <Box mt="0.5em" />
      <ChipCustomColor color="#4CAF50" label={'Preenchido'} />
    </>
  )
}
