import React from 'react'
import { merge } from 'lodash'
import { FormWithRedirect, NumberInput, required } from 'react-admin'
import { Box } from '@material-ui/core'
import MathDependentInput from '../../components/MathDependentInput'
import { averageValue } from '../../helpers/MathHelpers'

const DEFAULT_VALUES = ({
  type,
  action,
  athlete_id,
  season_id,
  filledwhen,
}) => {
  return {
    tricipital_ini: null,
    weight_fin: null,
    tricipital_fin: null,
    tricipital_med: null,
    bicipital_ini: null,
    bicipital_fin: null,
    bicipital_med: null,
    abdominal_ini: null,
    abdominal_fin: null,
    abdominal_med: null,
    subescapolar_ini: null,
    subescapolar_fin: null,
    subescapolar_med: null,
    geminal_ini: null,
    geminal_fin: null,
    geminal_med: null,
    suprailiaca_ini: null,
    suprailiaca_fin: null,
    suprailiaca_med: null,
    type: type,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
    filled_when: filledwhen,
  }
}

export default (props) => {
  const { setShowPage, physicalTest, data, toolbar } = props
  let initialValues = merge({}, DEFAULT_VALUES(physicalTest), data)

  return (
    <FormWithRedirect
      {...props}
      initialValues={initialValues}
      redirect={() => {
        setShowPage('athlete_questionnaires_list')
      }}
      render={(formProps) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={1} mr="1em">
                <FirstColumn />
              </Box>
              <Box flex={1} ml="1em">
                <SecondColumn />
              </Box>
              <Box flex={1} ml="1em">
                <ThirdColumn />
              </Box>
            </Box>
          </Box>
          {React.cloneElement(toolbar, {
            props,
            handleSubmitWithRedirect: formProps.handleSubmitWithRedirect,
          })}
        </form>
      )}
    />
  )
}

const FirstColumn = (props) => {
  return (
    <>
      <NumberInput
        source="tricipital_ini"
        label="1ª medição tricipital (em mm)"
        validate={[required()]}
        fullWidth
      />
      <NumberInput
        source="bicipital_ini"
        label="1ª medição bicipital (em mm)"
        validate={[required()]}
        fullWidth
      />
      <NumberInput
        source="abdominal_ini"
        label="1ª medição abdominal (em mm)"
        validate={[required()]}
        fullWidth
      />
      <NumberInput
        source="subescapolar_ini"
        label="1ª medição subescapular (em mm)"
        validate={[required()]}
        fullWidth
      />
      <NumberInput
        source="geminal_ini"
        label="1ª medição geminal (em mm)"
        validate={[required()]}
        fullWidth
      />
      <NumberInput
        source="suprailiaca_ini"
        label="1ª medição suprailíaca (em mm)"
        validate={[required()]}
        fullWidth
      />
    </>
  )
}

const SecondColumn = (props) => {
  return (
    <>
      <NumberInput
        source="tricipital_fin"
        label="2ª medição tricipital (em mm)"
        validate={[required()]}
        fullWidth
      />
      <NumberInput
        source="bicipital_fin"
        label="2ª medição bicipital (em mm)"
        validate={[required()]}
        fullWidth
      />
      <NumberInput
        source="abdominal_fin"
        label="2ª medição abdominal (em mm)"
        validate={[required()]}
        fullWidth
      />
      <NumberInput
        source="subescapolar_fin"
        label="2ª medição subescapular (em mm)"
        validate={[required()]}
        fullWidth
      />
      <NumberInput
        source="geminal_fin"
        label="2ª medição geminal (em mm)"
        validate={[required()]}
        fullWidth
      />
      <NumberInput
        source="suprailiaca_fin"
        label="2ª medição suprailíaca (em mm)"
        validate={[required()]}
        fullWidth
      />
    </>
  )
}

const ThirdColumn = (props) => {
  return (
    <>
      <MathDependentInput
        source="tricipital_med"
        label="A média tricipital é:"
        calculate={averageValue}
        variables={['tricipital_ini', 'tricipital_fin']}
        fullWidth
      />
      <MathDependentInput
        source="bicipital_med"
        label="A média bicipital é:"
        calculate={averageValue}
        variables={['bicipital_ini', 'bicipital_fin']}
        fullWidth
      />
      <MathDependentInput
        source="abdominal_med"
        label="A média abdominal é:"
        calculate={averageValue}
        variables={['abdominal_ini', 'abdominal_fin']}
        fullWidth
      />
      <MathDependentInput
        source="subescapolar_med"
        label="A média subescapular é:"
        calculate={averageValue}
        variables={['subescapolar_ini', 'subescapolar_fin']}
        fullWidth
      />
      <MathDependentInput
        source="geminal_med"
        label="A média geminal é:"
        calculate={averageValue}
        variables={['geminal_ini', 'geminal_fin']}
        fullWidth
      />
      <MathDependentInput
        source="suprailiaca_med"
        label="A média suprailíaca é:"
        calculate={averageValue}
        variables={['suprailiaca_ini', 'suprailiaca_fin']}
        fullWidth
      />
    </>
  )
}
