import validateToken from './validate-token'
import ApiUrl from '../data/api-url'
import { httpClient } from '../data/data-provider'
import SeasonsList from '../data/seasons-storage'
import axiosConfig from '../data/axios-config'
import userCache from './user-cache'

const login = ({ username, password }) => {
  userCache.clear()
  const request = new Request(`${ApiUrl}/auth`, {
    method: 'POST',
    body: JSON.stringify({
      username,
      password,
    }),
    headers: new Headers({ 'Content-Type': 'application/json' }),
  })
  return fetch(request)
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error('api.errors.login_error')
      }
      return response.json()
    })
    .then(({ token }) => {
      localStorage.setItem('token', token)
      axiosConfig.defaults.headers.common['Authorization'] =
        'Bearer ' + localStorage.getItem('token')
      SeasonsList.store()
    })
}

const logout = () => {
  userCache.clear()
  localStorage.removeItem('token')
  return Promise.resolve()
}

const checkAuth = () => {
  let token = localStorage.getItem('token')
  if (token) {
    token = validateToken(token)
    token = token.token
  }

  return token ? Promise.resolve() : Promise.reject()
}

const checkError = (error) => {
  const status = error.status
  if (status === 401) {
    localStorage.removeItem('token')
    return Promise.reject()
  }
  return Promise.resolve()
}

const getPermissions = async (params) => {
  let role = 'guest'

  let token = localStorage.getItem('token')
  if (token) {
    const me = await getCurrentUser()
    if (me) {
      role = me.role || 'guest'
    }
  }
  return role
}

const getCurrentUser = async () => {
  let user = await userCache.get()
  if (!user) {
    user = await userCache.set(forceGetCurrentUser())
  }

  return user
}

const refreshCurrentUser = async () => {
  return await userCache.set(forceGetCurrentUser())
}

const forceGetCurrentUser = async () => {
  let user
  let token = localStorage.getItem('token')
  if (token) {
    const me = await httpClient(`${ApiUrl}/me`)
    user = me && me.json && me.json
  }
  return user
}

export default {
  login,
  logout,
  checkAuth,
  checkError,
  getPermissions,
  getCurrentUser,
  refreshCurrentUser,
}
