import React, { useState, useEffect } from 'react'
import BackButton from '../../components/BackButton'
import {
  useNotify,
  LinearProgress,
  CreateView,
  Toolbar,
  SaveButton,
} from 'react-admin'
import api from '../../data/axios-config'
import { physical_tests_list } from '../../constants/physical_tests_list'
import ButtonHelperDialog from '../../components/ButtonHelperDialog'
import ButtonHistoryGraphPhysicalTest from '../../components/ButtonHistoryGraphPhysicalTest'
import DeleteButtonWithConfirm from '../../components/DeleteButtonWithConfirm'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

export default (props) => {
  const { setShowPage, physicalTest } = props
  const notify = useNotify()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(physicalTest.action === 'edit')
  const physical_test_type = physical_tests_list.find(
    (x) => x.id === physicalTest.type,
  )

  useEffect(() => {
    if (physicalTest.action === 'create') {
      return
    }

    ;(async () => {
      try {
        setLoading(true)
        const response = await api.get(
          `/athletes/${physicalTest.athlete_id}/physical_tests/${physicalTest.season_id}/${physicalTest.filledwhen}/${physicalTest.type}`,
        )
        setData(response.data)
      } catch (err) {
        notify(err.message, 'error')
      } finally {
        setLoading(false)
      }
    })()
  }, [physicalTest, notify])

  if (loading) {
    return <LinearProgress />
  }

  return (
    <>
      <BackButton
        onBack={() => {
          setShowPage('athlete_physical_tests_list')
        }}
      />
      <ButtonHelperDialog type={physicalTest.type} />
      <ButtonHistoryGraphPhysicalTest
        type={physicalTest.type}
        athlete_id={physicalTest.athlete_id}
      />
      <CreateView
        record={{}}
        title={
          physicalTest.action === 'edit'
            ? ' - Editar teste físico'
            : ' - Criar teste físico'
        }>
        {physical_test_type && physical_test_type.form ? (
          React.cloneElement(physical_test_type.form, {
            props,
            data: data,
            physicalTest: physicalTest,
            toolbar: (
              <PhysicalTestsToolbar
                setShowPage={setShowPage}
                action={physicalTest.action}
                physicalTestType={physical_test_type}
                recordId={data ? data.id : null}
              />
            ),
          })
        ) : (
          <>"Não existe nenhuma form para este testes físico!"</>
        )}
      </CreateView>
    </>
  )
}

const PhysicalTestsToolbar = (props) => {
  const { setShowPage, physicalTestType, action, recordId } = props
  const notify = useNotify()
  async function handleSave(values, redirect) {
    try {
      let body = fixValues(values)
      if (values.action === 'create') {
        await api.post(`/physical_tests`, body)
      } else {
        await api.put(`/physical_tests/${values.id}`, body)
      }
      setShowPage('athlete_physical_tests_list')
      notify('Teste Físico guardado com sucesso!', 'success')
    } catch (error) {
      notify(error.message, 'error')
    }
  }

  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton onSave={handleSave} />
      {action === 'edit' && (
        <DeleteButtonWithConfirm
          title={`Excluir ${physicalTestType.name} #${recordId}`}
          url={`physical_tests/${physicalTestType.id}/${recordId}`}
          redirect={() => setShowPage('athlete_physical_tests_list')}
        />
      )}
    </Toolbar>
  )

  function fixValues(values) {
    return values
  }
}
