import React from 'react'
import { Resource } from 'react-admin'

/* Users */
import { UserList, UserCreate, UserEdit } from '../resources/users'
import AccountBoxIcon from '@material-ui/icons/AccountBox'

/* Seasons */
import { SeasonList, SeasonCreate, SeasonEdit } from '../resources/seasons'
import SeasonShow from '../resources/seasons/SeasonShow'
import EventNoteIcon from '@material-ui/icons/EventNote'

/* Athletes */
import { AthleteList, AthleteCreate, AthleteEdit } from '../resources/athletes'
import AthleteShow from '../resources/athletes/AthleteShow'
import AccessibilityIcon from '@material-ui/icons/Accessibility'

/* Athlete Records */
import {
  AthleteRecordList,
  AthleteRecordEdit,
} from '../resources/athlete_records'
import AthleteRecordShow from '../resources/athlete_records/AthleteRecordShow'
import BorderColorIcon from '@material-ui/icons/BorderColor'

/* Games */
import { GameList } from '../resources/games'
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer'

export const resources = (role) => [
  {
    name: 'users',
    component: (
      <Resource
        name="users"
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
        icon={AccountBoxIcon}
        options={{ label: 'Utilizadores' }}
      />
    ),
  },
  {
    name: 'seasons',
    component: (
      <Resource
        name="seasons"
        list={SeasonList}
        create={SeasonCreate}
        edit={SeasonEdit}
        show={SeasonShow}
        icon={EventNoteIcon}
        options={{ label: 'Épocas Desportivas' }}
      />
    ),
  },
  {
    name: 'athletes',
    component: (
      <Resource
        name="athletes"
        list={AthleteList}
        show={AthleteShow}
        create={AthleteCreate}
        edit={AthleteEdit}
        icon={AccessibilityIcon}
        options={{ label: 'Jogadores' }}
      />
    ),
  },
  {
    name: 'athlete_records',
    component: (
      <Resource
        name="athlete_records"
        list={AthleteRecordList}
        icon={BorderColorIcon}
        show={AthleteRecordShow}
        edit={AthleteRecordEdit}
        options={{ label: role === 'admin' ? 'Historiais' : 'Jogadores' }}
      />
    ),
  },
  {
    name: 'games',
    component: (
      <Resource
        name="games"
        list={GameList}
        icon={SportsSoccerIcon}
        options={{ label: 'Jogos' }}
      />
    ),
  },
  {
    name: 'injuries',
    component: <Resource name="injuries" />,
  },
  {
    name: 'questionnaires',
    component: <Resource name="questionnaires" />,
  },
  {
    name: 'physical_tests',
    component: <Resource name="physical_tests" />,
  },
  {
    name: 'athlete_record_game_statistics',
    component: <Resource name="athlete_record_game_statistics" />,
  },
]
