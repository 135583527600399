import React from 'react'
import { merge } from 'lodash'
import { SimpleForm, SelectInput, required } from 'react-admin'
import { positive_negative_drop_choices_options } from '../../constants/questionnaires_responses'
import { positive_negative_questions } from '../../constants/questionnaires_questions'

const DEFAULT_VALUES = ({
  type,
  action,
  athlete_id,
  season_id,
  filledwhen,
}) => {
  return {
    interess: null,
    torment: null,
    guilt: null,
    scared: null,
    excited: null,
    inspired: null,
    nervous: null,
    determined: null,
    active: null,
    fear: null,
    type: type,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
    filled_when: filledwhen,
  }
}

export default (props) => {
  const { setShowPage, questionnaire, data, toolbar } = props
  let initialValues = merge({}, DEFAULT_VALUES(questionnaire), data)

  return (
    <>
      <SimpleForm
        initialValues={initialValues}
        toolbar={toolbar}
        redirect={() => {
          setShowPage('athlete_questionnaires_list')
        }}>
        <SelectInput
          source="interess"
          label={positive_negative_questions.interess}
          choices={positive_negative_drop_choices_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="torment"
          label={positive_negative_questions.torment}
          choices={positive_negative_drop_choices_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="guilt"
          label={positive_negative_questions.guilt}
          choices={positive_negative_drop_choices_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="scared"
          label={positive_negative_questions.scared}
          choices={positive_negative_drop_choices_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="excited"
          label={positive_negative_questions.excited}
          choices={positive_negative_drop_choices_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="inspired"
          label={positive_negative_questions.inspired}
          choices={positive_negative_drop_choices_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="nervous"
          label={positive_negative_questions.nervous}
          choices={positive_negative_drop_choices_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="determined"
          label={positive_negative_questions.determined}
          choices={positive_negative_drop_choices_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="active"
          label={positive_negative_questions.active}
          choices={positive_negative_drop_choices_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="fear"
          label={positive_negative_questions.fear}
          choices={positive_negative_drop_choices_options}
          validate={[required()]}
          fullWidth
        />
      </SimpleForm>
    </>
  )
}
