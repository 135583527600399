export const permissions = [
  {
    role: 'admin',
    resources: [
      'users',
      'seasons',
      'athletes',
      'athlete_records',
      //'games',
      'injuries',
      'questionnaires',
      'physical_tests',
      'athlete_record_game_statistics',
    ],
  },
  { role: 'treinador', resources: ['athlete_records'] },
  { role: 'visitante', resources: [] },
]
