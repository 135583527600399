import React from 'react'
import { TextField, Labeled } from 'react-admin'
import { Typography, Box } from '@material-ui/core'

export default function AthleteSummary({ record }) {
  return (
    <Box display={{ md: 'block', lg: 'flex' }}>
      <Box flex={2} mr={{ md: 0, lg: '1em' }}>
        <Typography variant="h6" gutterBottom>
          Dados do historial
        </Typography>

        <Labeled label="Nome do Jogador">
          <TextField record={record} source="name" />
        </Labeled>

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="Época">
              <TextField record={record} source="season_id" />
            </Labeled>
          </Box>

          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="Escalão">
              <TextField record={record} source="team_formation" />
            </Labeled>
          </Box>

          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <Labeled label="Posições em campo">
              <TextField record={record} source="field_positions" />
            </Labeled>
          </Box>
        </Box>
      </Box>

      <Box mt="1em" />

      <Box flex={2} mr={{ md: 0, lg: '1em' }}>
        <Typography variant="h6" gutterBottom>
          Estastística da Época
        </Typography>

        <Box mt="1em" />
      </Box>
    </Box>
  )
}
