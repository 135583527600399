import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import HelpIcon from '@material-ui/icons/Help'
import {
  Dialog,
  DialogTitle,
  Tabs,
  Tab,
  DialogContent,
} from '@material-ui/core'
import { physical_tests_text_helper } from '../constants/text_helper'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})

export default (props) => {
  const { type } = props
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        color="primary"
        style={{ float: 'right' }}
        onClick={handleClickOpen}>
        <HelpIcon />
      </Button>
      <SimpleDialog open={open} onClose={handleClose} type={type} />
    </>
  )
}

const SimpleDialog = (props) => {
  const { onClose, open, type } = props
  const helper_text = physical_tests_text_helper.find((x) => x.id === type)

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="md">
      {helper_text ? (
        <>
          <DialogTitle id="simple-dialog-title">{helper_text.name}</DialogTitle>
          <DialogContent>
            <CenteredTabs
              description={helper_text.description}
              objective={helper_text.objective}
              image={helper_text.image}
              name={helper_text.name}
            />
          </DialogContent>
        </>
      ) : (
        <>Não existe ainda um texto de ajuda para este teste no sistema!</>
      )}
    </Dialog>
  )
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const CenteredTabs = ({ description, objective, image, name }) => {
  const classes = useStyles()
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered>
        <Tab label="Descritivo" {...a11yProps(0)} />
        <Tab label="Imagem" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <div>
          <p>
            <strong>Objetivo: </strong>
            {objective || 'Ainda não existe um objectivo para este teste!'}
          </p>
        </div>
        <div>
          <p>
            <strong>Descrição: </strong>
            {description
              ? description.split('\n').map((str) => <p>{str}</p>)
              : 'Ainda não existe um descrição para este teste!'}
          </p>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {image ? (
          <img
            src={`physical_tests_images/${image}`}
            title="Teste Físico"
            alt={name}
            className={classes.image}
          />
        ) : (
          'Ainda não existe um objectivo para este teste!'
        )}
      </TabPanel>
    </div>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{value === index && children}</Box>}
    </div>
  )
}
