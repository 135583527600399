import React from 'react'
import {
  FormWithRedirect,
  SaveButton,
  DeleteButton,
  SelectInput,
  TextInput,
  required,
  SelectArrayInput,
  NumberInput,
} from 'react-admin'
import { Typography, Box, Toolbar } from '@material-ui/core'
import { team_formations, teams } from '../../constants/teams'
import { seasons_list, active_season } from '../../helpers/SeasonsHelper'
import { field_positions } from '../../constants/questionnaires_responses'
import { current_school_degree, not_in_school } from '../../constants/school'

export default function AthleteRecordForm(props) {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={2} mr="1em">
                <AthleteRecordBasicData {...props} />

                <Box mt="1em" />
              </Box>

              <Box flex={1} ml="1em">
                <AthleteRecordOtherData {...props} />
              </Box>
            </Box>
          </Box>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              <SaveButton
                {...props}
                redirect="show"
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
              <DeleteButton
                record={formProps.record}
                resource="athlete_records"
                undoable={false}
              />
            </Box>
          </Toolbar>
        </form>
      )}
    />
  )
}

const AthleteRecordBasicData = (props) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Historial do Jogador
      </Typography>

      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <TextInput
            source="athlete_id"
            label="Id do Jogador"
            disabled
            fullWidth
          />
        </Box>

        <Box flex={6} ml="0.5em">
          <TextInput source="name" label="Nome" disabled fullWidth />
        </Box>
      </Box>

      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <SelectInput
            source="season_id"
            label="Época"
            choices={seasons_list()}
            defaultValue={active_season()}
            validate={required()}
            fullWidth
          />
        </Box>

        <Box flex={1} ml="0.5em">
          <SelectInput
            source="team_formation"
            label="Escalão"
            choices={team_formations}
            validate={required()}
            fullWidth
          />
        </Box>

        <Box flex={1} ml="0.5em">
          <SelectInput
            source="team"
            label="Equipa"
            allowEmpty
            emptyValue={null}
            choices={teams}
            fullWidth
          />
        </Box>
      </Box>

      <SelectArrayInput
        source="field_positions"
        label="Posições em campo"
        choices={field_positions}
        fullWidth
      />
    </>
  )
}

const AthleteRecordOtherData = (props) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Outras informações
      </Typography>

      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <NumberInput source="height" label="Altura (cm)" fullWidth />
        </Box>

        <Box flex={1} ml="0.5em">
          <NumberInput source="weight" label="Peso (kg)" fullWidth />
        </Box>
      </Box>

      <SelectInput
        source="current_school_degree"
        label="Grau de escolaridade atual"
        choices={current_school_degree}
        fullWidth
      />

      <SelectInput
        source="not_in_school"
        label="Not in school"
        choices={not_in_school}
        fullWidth
      />
    </>
  )
}
