import React from 'react'
import { AppBar } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Logo from './maritimo-logo.png'
import UserMenu from './UserMenu'

const useStyles = makeStyles(() => ({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  logo: {
    height: '2em',
  },
}))

const MyAppBar = (props) => {
  const classes = useStyles()
  return (
    <AppBar {...props} userMenu={<UserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <img src={Logo} alt="mtl jovens" className={classes.logo} />
      <span className={classes.spacer} />
    </AppBar>
  )
}

export default MyAppBar
