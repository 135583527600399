import React from 'react'
import { physical_tests_list } from '../../../constants/physical_tests_list'
import ButtonCustomColor from '../../ButtonCustomColor'

export default (props) => {
  const { data, setShowPage, setPhysicalTest, inputs, physicalList } = props
  const buttonsList = []

  physicalList.forEach((physical_test) => {
    const TextHelper = physical_tests_list.find(
      (element) => element.id === physical_test,
    )
    buttonsList.push(
      <div key={`${physical_test}${data[physical_test]}`}>
        <ButtonCustomColor
          color={data[physical_test] ? 'primary' : 'default'}
          onClick={() => {
            setPhysicalTest({
              athlete_id: inputs.athlete_id,
              season_id: inputs.season_id,
              filledwhen: inputs.filledwhen,
              type: physical_test,
              action: data[physical_test] ? 'edit' : 'create',
            })
            setShowPage('athlete_physical_tests_action')
          }}
          variant="contained"
          fullWidth
          label={TextHelper ? TextHelper.name : TextHelper}
          size="large"></ButtonCustomColor>
        &nbsp;
      </div>,
    )
  })

  return <>{buttonsList}</>
}
