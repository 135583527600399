import React from 'react'
import { merge } from 'lodash'
import { SimpleForm, SelectArrayInput } from 'react-admin'
import { sport_types_options } from '../../constants/questionnaires_responses'
import { sports_questions } from '../../constants/questionnaires_questions'

const DEFAULT_VALUES = ({
  type,
  action,
  athlete_id,
  season_id,
  filledwhen,
}) => {
  return {
    types: null,
    type: type,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
    filled_when: filledwhen,
  }
}

function fixTypesToArray(types) {
  if (types === null) {
    return
  }

  const sport_name_array = types.split(',')
  let sport_number_array = []
  sport_name_array.forEach((sport_name) => {
    const sport = sport_types_options.find(
      (element) => element.name === sport_name,
    )
    sport_number_array.push(sport.id)
  })

  return sport_number_array
}

export default (props) => {
  const { setShowPage, questionnaire, data, toolbar } = props
  let fix_data = Object.assign({}, data)
  if (Object.entries(data).length !== 0) {
    fix_data.types = fixTypesToArray(data.types)
  }

  let initialValues = merge({}, DEFAULT_VALUES(questionnaire), fix_data)

  return (
    <>
      <SimpleForm
        initialValues={initialValues}
        toolbar={toolbar}
        redirect={() => {
          setShowPage('athlete_questionnaires_list')
        }}>
        <SelectArrayInput
          source="types"
          label={sports_questions.types}
          choices={sport_types_options}
          fullWidth
        />
      </SimpleForm>
    </>
  )
}
