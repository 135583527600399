import React from 'react'
import {
  FormWithRedirect,
  TextInput,
  SaveButton,
  DeleteButton,
  ImageInput,
  ImageField,
  SelectInput,
  required,
  BooleanInput,
  FormDataConsumer,
  SelectArrayInput,
} from 'react-admin'
import { Typography, Box, Toolbar } from '@material-ui/core'
import { genders } from '../../constants/genders'
import counties from '../../constants/counties'
import { team_formations } from '../../constants/teams'
import { seasons_list, active_season } from '../../helpers/SeasonsHelper'
import {
  field_positions,
  footed_options,
  default_positions_options,
} from '../../constants/questionnaires_responses'
import { DateInput } from '../../components/datetime'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  imgContainer: {
    '& img': {
      height: '100%',
      width: '100%',
    },
  },
})

export default function AthleteForm(props) {
  const classes = useStyles()

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={2} mr="1em">
                <AthleteBasicData {...props} />

                <Box mt="1em" />

                <AthleteParentInputs {...props} />

                <Box mt="1em" />

                {!formProps.record.id ? (
                  <AthleteRecordInputs {...formProps.record} />
                ) : null}
              </Box>

              <Box flex={1} ml="1em">
                <Typography variant="h6" gutterBottom>
                  Mais informações
                </Typography>

                <ImageInput
                  source="photo"
                  label="Foto"
                  accept="image/*"
                  multiple={false}
                  className={classes.imgContainer}>
                  <ImageField source="src" title="foto" />
                </ImageInput>
              </Box>
            </Box>
          </Box>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              <SaveButton
                {...props}
                redirect="show"
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
              <DeleteButton
                record={formProps.record}
                resource="athletes"
                undoable={false}
              />
            </Box>
          </Toolbar>
        </form>
      )}
    />
  )
}

const AthleteBasicData = (props) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Dados do Jogador
      </Typography>

      <Box display="flex">
        <Box flex={3} mr="0.5em">
          <TextInput
            source="name"
            label="Nome"
            validate={required()}
            fullWidth
          />
        </Box>
        <Box flex={1}>
          <TextInput source="idnr" label="IDNR" fullWidth />
        </Box>
      </Box>

      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <TextInput source="nationality" label="Nacionalidade" fullWidth />
        </Box>

        <Box flex={1} mr="0.5em">
          <SelectInput
            source="gender"
            label="Genero"
            choices={genders}
            fullWidth
          />
        </Box>

        <Box flex={1} ml="0.5em">
          <DateInput
            source="birth_date"
            label="Data de nascimento"
            options={{
              autoOk: true,
              variant: 'inline',
              format: 'dd/MM/yyyy',
            }}
            formatDate={(date) => new Date(date).toISOString().slice(0, 10)}
            validate={[required('Obrigatório')]}
            fullWidth
          />
        </Box>
      </Box>

      <TextInput source="address" label="Morada" fullWidth />

      <Box display="flex">
        <Box flex={3} mr="0.5em">
          <SelectInput
            source="district"
            label="Concelho"
            choices={counties}
            fullWidth
          />
        </Box>

        <Box flex={1} ml="0.5em">
          <TextInput source="postal" label="Código postal" fullWidth />
        </Box>
      </Box>

      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <SelectInput
            source="footed"
            label="Pé Dominante"
            choices={footed_options}
            fullWidth
          />
        </Box>

        <Box flex={1}>
          <SelectInput
            source="default_position"
            label="Posição"
            choices={default_positions_options}
            fullWidth
          />
        </Box>
      </Box>
    </>
  )
}

const AthleteParentInputs = (props) => {
  const data_parent = {
    show_parent_data: false,
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Dados dos Encarregados de Educação
      </Typography>
      <BooleanInput
        record={data_parent}
        source="show_parent_data"
        label={'Mostrar dados dos encarregados'}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.show_parent_data && (
            <>
              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <TextInput
                    source="father_name"
                    label="Nome do Pai"
                    fullWidth
                  />
                </Box>

                <Box flex={1} ml="0.5em">
                  <TextInput
                    source="mother_name"
                    label="Nome da Mãe"
                    fullWidth
                  />
                </Box>
              </Box>
              <Box display="flex">
                <Box flex={2} mr="0.5em">
                  <TextInput
                    source="parent_email"
                    label="Email do Encarregado"
                    fullWidth
                  />
                </Box>

                <Box flex={1} ml="0.5em">
                  <TextInput
                    source="parent_phone"
                    label="Contacto do Encarregado"
                    fullWidth
                  />
                </Box>
              </Box>
            </>
          )
        }
      </FormDataConsumer>
    </>
  )
}

const AthleteRecordInputs = (props) => {
  const data = {
    create_athlete_record: false,
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Outros Dados
      </Typography>

      <BooleanInput
        record={data}
        source="create_athlete_record"
        label={'Criar uma historial'}
      />

      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.create_athlete_record && (
            <>
              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <SelectInput
                    source="athlete_record.season_id"
                    label="Época"
                    choices={seasons_list()}
                    defaultValue={active_season()}
                    validate={required()}
                    fullWidth
                  />
                </Box>

                <Box flex={1} ml="0.5em">
                  <SelectInput
                    source="athlete_record.team_formation"
                    label="Escalão"
                    choices={team_formations}
                    validate={required()}
                    fullWidth
                  />
                </Box>
              </Box>

              <SelectArrayInput
                source="athlete_record.field_positions"
                label="Posições em campo"
                choices={field_positions}
                fullWidth
              />
            </>
          )
        }
      </FormDataConsumer>
    </>
  )
}
