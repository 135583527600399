import React from 'react'
import { Admin } from 'react-admin'
import dataProvider from './data/data-provider'
import authProvider from './auth/auth-provider'
import Home from './pages/home'
import Layout from './components/layout/Layout'
import theme from './theme'
import Dashboard from './components/Dashboard'
import { resources } from './constants/resources'
import { permissions } from './constants/permissions'

/* i18nProvider  */
import polyglotI18nProvider from 'ra-i18n-polyglot'
import portugueseMessages from '@henriko/ra-language-portuguese'

const messages = {
  pt: portugueseMessages,
}
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'pt', {
  allowMissing: true,
})

export default () => {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      loginPage={Home}
      title="MTL Jovens - ADMIN"
      theme={theme}
      dashboard={Dashboard}
      layout={Layout}>
      {(role) => getRoleResources(role)}
    </Admin>
  )
}

const getRoleResources = (role) => {
  const role_permissions = permissions.find((element) => element.role === role)

  if (!role_permissions) {
    return null
  }

  return role_permissions.resources.map((name) => {
    const component = resources(role).find((element) => element.name === name)
    return component ? component.component : null
  })
}
