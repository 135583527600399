import React from 'react'
import { merge } from 'lodash'
import { FormWithRedirect, SelectInput, required } from 'react-admin'
import { do_it_options } from '../../constants/questionnaires_responses'
import { problem_solvings_questions } from '../../constants/questionnaires_questions'
import { Box } from '@material-ui/core'
import LongTextInput from '../../components/LongTextInput'

const DEFAULT_VALUES = ({
  type,
  action,
  athlete_id,
  season_id,
  filledwhen,
}) => {
  return {
    abstraction: null,
    efforts: null,
    not_true: null,
    pills: null,
    emocional_support: null,
    give_up: null,
    improve: null,
    refuse_believe: null,
    express_feel: null,
    ask_advice: null,
    use_drugs: null,
    think_positive: null,
    criticize: null,
    strategy: null,
    understanding: null,
    give_leed: null,
    something: null,
    as_joke: null,
    shopping: null,
    accept_it: null,
    feel_bored: null,
    religion: null,
    previous_experience: null,
    same_situation: null,
    learn_to_live: null,
    blame_happens: null,
    pray: null,
    sense_humor: null,
    type: type,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
    filled_when: filledwhen,
  }
}

export default (props) => {
  const { setShowPage, questionnaire, data, toolbar } = props
  let initialValues = merge({}, DEFAULT_VALUES(questionnaire), data)

  return (
    <FormWithRedirect
      {...props}
      initialValues={initialValues}
      redirect={() => {
        setShowPage('athlete_questionnaires_list')
      }}
      render={(formProps) => (
        <form>
          <Box p="1em">
            <Box display="flex">
              <Box flex={1} mr="1em">
                <FirstQuestionColumn />
              </Box>
              <Box flex={1} ml="1em">
                <SecondQuestionColumn />
              </Box>
            </Box>
          </Box>
          {React.cloneElement(toolbar, {
            props,
            handleSubmitWithRedirect: formProps.handleSubmitWithRedirect,
          })}
        </form>
      )}
    />
  )
}

const FirstQuestionColumn = (props) => {
  return (
    <>
      <SelectInput
        source="abstraction"
        label={problem_solvings_questions.abstraction}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <LongTextInput
        input={<SelectInput />}
        source="efforts"
        label={problem_solvings_questions.efforts}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="not_true"
        label={problem_solvings_questions.not_true}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="pills"
        label={problem_solvings_questions.pills}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="emocional_support"
        label={problem_solvings_questions.emocional_support}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="give_up"
        label={problem_solvings_questions.give_up}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="improve"
        label={problem_solvings_questions.improve}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="refuse_believe"
        label={problem_solvings_questions.refuse_believe}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="express_feel"
        label={problem_solvings_questions.express_feel}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="ask_advice"
        label={problem_solvings_questions.ask_advice}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="use_drugs"
        label={problem_solvings_questions.use_drugs}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="think_positive"
        label={problem_solvings_questions.think_positive}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="criticize"
        label={problem_solvings_questions.criticize}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="strategy"
        label={problem_solvings_questions.strategy}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
    </>
  )
}

const SecondQuestionColumn = (props) => {
  return (
    <>
      <SelectInput
        source="understanding"
        label={problem_solvings_questions.understanding}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="give_leed"
        label={problem_solvings_questions.give_leed}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="something"
        label={problem_solvings_questions.something}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="as_joke"
        label={problem_solvings_questions.as_joke}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <LongTextInput
        input={<SelectInput />}
        source="shopping"
        label={problem_solvings_questions.shopping}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="accept_it"
        label={problem_solvings_questions.accept_it}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="feel_bored"
        label={problem_solvings_questions.feel_bored}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="religion"
        label={problem_solvings_questions.religion}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="previous_experience"
        label={problem_solvings_questions.previous_experience}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="same_situation"
        label={problem_solvings_questions.same_situation}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="learn_to_live"
        label={problem_solvings_questions.learn_to_live}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="blame_happens"
        label={problem_solvings_questions.blame_happens}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="pray"
        label={problem_solvings_questions.pray}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
      <SelectInput
        source="sense_humor"
        label={problem_solvings_questions.sense_humor}
        choices={do_it_options}
        validate={[required()]}
        fullWidth
      />
    </>
  )
}
