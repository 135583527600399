import React, { useState } from 'react'
import AthleteInjuriesList from './injuries/AthleteInjuriesList'
import { InjurieCreate, InjurieEdit } from '../../resources/injuries'

export default (props) => {
  let initState = 'athlete_injuries_list'
  const [showPage, setShowPage] = useState(initState)
  const [editRecord, SetEditRecord] = useState(null)

  return (
    <div style={{ marginBottom: 15 }}>
      {showPage === 'athlete_injuries_list' && (
        <AthleteInjuriesList
          {...props}
          setShowPage={setShowPage}
          showPage={showPage}
          SetEditRecord={SetEditRecord}
        />
      )}

      {showPage === 'athlete_injuries_create' && (
        <InjurieCreate
          {...props}
          setShowPage={setShowPage}
          showPage={showPage}
        />
      )}

      {showPage === 'athlete_injuries_edit' && (
        <InjurieEdit
          {...props}
          setShowPage={setShowPage}
          showPage={showPage}
          editRecord={editRecord}
          SetEditRecord={SetEditRecord}
        />
      )}
    </div>
  )
}
