import React from 'react'
import { Create, Edit } from 'react-admin'
import InjurieForm from './InjurieForm'
import BackButton from '../../components/BackButton'

const defaultProps = {
  resource: 'injuries',
  basePath: '/injuries',
  hasShow: false,
  hasList: false,
}

const default_values = (athlete_id) => ({
  athlete_id: athlete_id,
  season_id: null,
  type: null,
  location: null,
  laterality: null,
  number_injuries: null,
  surgery_type: null,
  surgery_number: null,
  injury_description: null,
})

export const InjurieCreate = (props) => {
  const { setShowPage } = props
  props = { ...props, ...defaultProps }

  return (
    <>
      <BackButton
        onBack={() => {
          setShowPage('athlete_injuries_list')
        }}
      />
      <Create
        {...props}
        title=" - Adicionar nova lesão"
        record={default_values(props.record.id)}>
        <InjurieForm setShowPage={setShowPage} />
      </Create>
    </>
  )
}

export const InjurieEdit = (props) => {
  const { setShowPage, editRecord } = props
  props = { ...props, ...defaultProps, id: editRecord.id }

  return (
    <>
      <BackButton
        onBack={() => {
          setShowPage('athlete_injuries_list')
        }}
      />
      <Edit {...props} undoable={false}>
        <InjurieForm setShowPage={setShowPage} action="edit" />
      </Edit>
    </>
  )
}
