import React from 'react'
import SchoolDataForm from '../resources/questionnaires/SchoolDataForm'
import SocioEconomicDataForm from '../resources/questionnaires/SocioEconomicDataForm'
import SportPassionForm from '../resources/questionnaires/SportPassionForm'
import VitalityForm from '../resources/questionnaires/VitalityForm'
import PositiveNegativeAffectionForm from '../resources/questionnaires/PositiveNegativeAffectionForm'
import SleepHabitsForm from '../resources/questionnaires/SleepHabitsForm'
import PsychNeedForm from '../resources/questionnaires/PsychNeedForm'
import MotivationForm from '../resources/questionnaires/MotivationForm'
import LifeSatisfactionForm from '../resources/questionnaires/LifeSatisfactionForm'
import LifeStyleForm from '../resources/questionnaires/LifeStyleForm'
import ProblemSolvingForm from '../resources/questionnaires/ProblemSolvingForm'
import SportForm from '../resources/questionnaires/SportForm'

export const questionnaires_list = [
  {
    id: 'psych_needs',
    name: 'Necessidades Psicológicas',
    form: <PsychNeedForm />,
  },
  { id: 'motivations', name: 'Motivações', form: <MotivationForm /> },
  {
    id: 'problem_solvings',
    name: 'Resolução de Problemas',
    form: <ProblemSolvingForm />,
  },
  { id: 'school_data', name: 'Dados Escolares', form: <SchoolDataForm /> },
  {
    id: 'socio_economic_data',
    name: 'Dados Socioeconômicos',
    form: <SocioEconomicDataForm />,
  },
  { id: 'sleep_habits', name: 'Hábitos de sono', form: <SleepHabitsForm /> },
  {
    id: 'sport_passions',
    name: 'Paixões Desportivas',
    form: <SportPassionForm />,
  },
  {
    id: 'life_satisfactions',
    name: 'Satisfação de Vida',
    form: <LifeSatisfactionForm />,
  },
  { id: 'vitalities', name: 'Vitalidades', form: <VitalityForm /> },
  {
    id: 'positive_negative_affections',
    name: 'Afetos Negativos Positivos',
    form: <PositiveNegativeAffectionForm />,
  },
  { id: 'life_styles', name: 'Estilo de Vida', form: <LifeStyleForm /> },
  { id: 'sports', name: 'Desportos', form: <SportForm /> },
]

export const first_moment_questionnaires_list = [
  'socio_economic_data',
  'school_data',
  'sport_passions',
  'psych_needs',
  'motivations',
  'life_satisfactions',
]

export const second_moment_questionnaires_list = [
  'vitalities',
  'positive_negative_affections',
  'life_styles',
  'problem_solvings',
  'sleep_habits',
  'sports',
]
