import React from 'react'
import { LinearProgress } from 'react-admin'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import TeamPhysicalAverageGraph from '../TeamPhysicalAverageGraph'
import { Card, CardContent, Typography } from '@material-ui/core'

const CoachDashboard = (props) => {
  const user = useCurrentUser()

  if (!user) {
    return <LinearProgress />
  }

  const requestUrl = (input) => {
    const { team_formation, filledwhen, physical_test } = input
    return `graphs/physical_tests/average/coach/${team_formation}/${filledwhen}/${physical_test}`
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Média da aptidão física por escalão
        </Typography>
        <TeamPhysicalAverageGraph
          firstTeamFormation={user.coach_team_formation}
          urlCallback={requestUrl}
        />
      </CardContent>
    </Card>
  )
}

export default CoachDashboard
