import React from 'react'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Button from '@material-ui/core/Button'

export default (props) => {
  const { onBack } = props

  return (
    <Button
      color="primary"
      variant="contained"
      size="small"
      startIcon={<ArrowBackIcon />}
      onClick={onBack}>
      Voltar
    </Button>
  )
}
