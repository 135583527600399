import React from 'react'
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core'
import { LinearProgress } from 'react-admin'
import { statsTextHelper } from '../../constants/dashboard'

const StatsList = ({ data, classStyle }) => {
  if (!data) {
    return <div>Sem dados!</div>
  }

  const rows = []

  Object.entries(data).forEach(([key, value]) => {
    const TextHelper = statsTextHelper.find((element) => element.id === key)
    rows.push(
      <div className={classStyle} key={`${key}${value}`}>
        <Typography variant="caption">
          {TextHelper ? TextHelper.name : key} :
        </Typography>
        <Typography variant="caption">{value}</Typography>
      </div>,
    )
  })

  return <div>{rows}</div>
}

const IndicatorBoard = (props) => {
  const { data, title, loading, icon, backgroundAvatarColor } = props

  if (loading) {
    return <LinearProgress />
  }

  return (
    <Card className={props.indCard}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={props.title}
              color="textSecondary"
              gutterBottom
              variant="body2">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              className={props.avatar}
              style={{ backgroundColor: backgroundAvatarColor }}>
              {icon}
            </Avatar>
          </Grid>
        </Grid>
        <StatsList data={data} classStyle={props.difference} />
      </CardContent>
    </Card>
  )
}

export default IndicatorBoard
