import React from 'react'
import { merge } from 'lodash'
import { SimpleForm, SelectInput, NumberInput, required } from 'react-admin'
import {
  likes_options,
  average_options,
} from '../../constants/questionnaires_responses'
import { school_data_questions } from '../../constants/questionnaires_questions'

const DEFAULT_VALUES = ({
  type,
  action,
  athlete_id,
  season_id,
  filledwhen,
}) => {
  return {
    school_thoughts: null,
    student_performance: null,
    math: null,
    portuguese: null,
    physical: null,
    type: type,
    action: action,
    athlete_id: athlete_id,
    season_id: season_id,
    filled_when: filledwhen,
  }
}

export default (props) => {
  const { setShowPage, questionnaire, data, toolbar } = props
  let initialValues = merge({}, DEFAULT_VALUES(questionnaire), data)

  return (
    <>
      <SimpleForm
        initialValues={initialValues}
        toolbar={toolbar}
        redirect={() => {
          setShowPage('athlete_questionnaires_list')
        }}>
        <SelectInput
          source="school_thoughts"
          label={school_data_questions.school_thoughts}
          choices={likes_options}
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="student_performance"
          label={school_data_questions.student_performance}
          choices={average_options}
          validate={[required()]}
          fullWidth
        />
        <NumberInput
          source="math"
          label={school_data_questions.math}
          validate={[required()]}
          fullWidth
        />
        <NumberInput
          source="portuguese"
          label={school_data_questions.portuguese}
          validate={[required()]}
          fullWidth
        />
        <NumberInput
          source="physical"
          label={school_data_questions.physical}
          validate={[required()]}
          fullWidth
        />
      </SimpleForm>
    </>
  )
}
