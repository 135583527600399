export const statsTextHelper = [
  { id: 'users', name: 'Utilizadores' },
  { id: 'athletes', name: 'Jogadores' },
  { id: 'athlete_records', name: 'Historial' },
  { id: 'injuries', name: 'Lesões' },
  { id: 'life_satisfactions', name: 'Satisfações da Vida' },
  { id: 'life_styles', name: 'Estilo de vida' },
  { id: 'motivations', name: 'Motivação' },
  { id: 'positive_negative_affections', name: 'Afetos Pos. Neg.' },
  { id: 'problem_solvings', name: 'Resolução do problema' },
  { id: 'psych_needs', name: 'Necessitades Psicológicas' },
  { id: 'school_data', name: 'Informação Escolar' },
  { id: 'sleep_habits', name: 'Hábitos do Sono' },
  { id: 'socio_economic_data', name: 'Dados Socioeocnomicos' },
  { id: 'sport_passions', name: 'Paixões do desporto' },
  { id: 'sports', name: 'Desporto' },
  { id: 'vitalities', name: 'Vitalidades' },
  { id: 'active_season_id', name: 'Época' },
  { id: 'active_initial_date', name: 'Data de inicio' },
  { id: 'active_end_date', name: 'Data de fim' },
]
